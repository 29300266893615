import React, { PureComponent } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ScrollBarsLib from 'react-custom-scrollbars';
import { ResizeSensor } from 'css-element-queries';
import styles from './style.scss';

// CLASS_NAME
const CLASS_KEY = 'scrollbars-wrapper';
const CLASS_NAME = _.get(styles, CLASS_KEY, CLASS_KEY);

/**
 * Render Track Horizontal
 * @param  {Object} p The properties of track horizontal
 * @return {Element} React element
 */
const renderTrackHorizontal = p => (
  <div className="scrollbars-track-horizontal" {...p} />
);

/**
 * Render Track Vertical
 * @param  {Object} p The properties of track vertical
 * @return {Element} React element
 */
const renderTrackVertical = p => (
  <div className="scrollbars-track-vertical" {...p} />
);

/**
 * ScrollBars component
 * @extends PureComponent
 */
class ScrollBars extends PureComponent {
  /**
   * Constructor
   * @param {Object} props The props
   */
  constructor(props) {
    super(props);
    this.currentScrollValues = null;
    this.resizeSensor = null;
    this.scrollBarsRef = React.createRef();
    this.containerRef = React.createRef();
    // Binding
    this.onScrollStop = this.onScrollStop.bind(this);
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    if (this.props.autoScrollOnResize) {
      this.resizeSensor = new ResizeSensor(this.containerRef.current, () => {
        this.onResize();
      });
    }
    this.currentScrollValues = this.getValues();
  }

  /**
   * On scroll stop
   */
  onScrollStop() {
    // Update the current scroll values
    this.currentScrollValues = this.getValues();
  }

  /**
   * On container resize
   */
  onResize() {
    this.scrollOnResize();
  }

  /**
   * Get the measurement of the height of an element's content,
   * including content not visible on the screen due to overflow.
   * @return {Number} The scroll height
   */
  getScrollHeight() {
    return this.scrollBarsRef.current.getScrollHeight();
  }

  /**
   * Get an object with values about the current position of scrollbar.
   * @return {Object} The current position of scrollbar
   */
  getValues() {
    if (this.scrollBarsRef.current) {
      return this.scrollBarsRef.current.getValues();
    }
    return null;
  }

  /**
   * Set the vertical position of the scroll bar.
   * @param  {Number} x A number indicating the new position to set the scroll bar to.
   */
  scrollTop(x) {
    this.scrollBarsRef.current.scrollTop(x);
  }

  /**
   * Set the current horizontal position of the scroll bar
   * @param  {Number} x An integer indicating the new position to set the scroll bar to.
   */
  scrollLeft(x) {
    this.scrollBarsRef.current.scrollLeft(x);
  }

  /**
   * Scroll when the container is resized
   */
  scrollOnResize() {
    const {
      clientWidth,
      scrollWidth,
      scrollLeft,
    } = this.currentScrollValues;

    const currentScrollPos = this.getValues();
    const currentClientWidth = currentScrollPos.clientWidth;
    // Calculate new scroll width
    const newScrollWidth = Math.floor((currentClientWidth * scrollWidth) / clientWidth);
    // Calculate new scroll left
    const newScrollLeft = (newScrollWidth * scrollLeft) / scrollWidth;

    this.scrollLeft(Math.floor(newScrollLeft));
  }

  /**
   * Render component
   * @return {Element} React Element
   */
  render() {
    const {
      className,
      children,
      onScroll,
      autoHeight,
      maxHeight,
    } = this.props;
    return (
      <div className={`${className} ${CLASS_NAME}`} ref={this.containerRef}>
        <ScrollBarsLib
          onScrollStop={this.onScrollStop}
          onScroll={onScroll}
          renderTrackHorizontal={renderTrackHorizontal}
          renderTrackVertical={renderTrackVertical}
          ref={this.scrollBarsRef}
          autoHeight={autoHeight}
          autoHeightMax={maxHeight}
        >
          {children}
        </ScrollBarsLib>
      </div>
    );
  }
}

ScrollBars.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
  onScroll: PropTypes.func,
  autoScrollOnResize: PropTypes.bool,
  autoHeight: PropTypes.bool,
  maxHeight: PropTypes.number,
};

ScrollBars.defaultProps = {
  className: '',
  onScroll: () => (null),
  autoScrollOnResize: false,
  autoHeight: false,
  maxHeight: 0,
};

export default ScrollBars;
