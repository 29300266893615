import fetch from 'isomorphic-fetch';
import i18n from '../../../../i18n';
import {
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from './events';

import {
  API_LOGIN,
  NOTIFICATION_TYPES,
} from '../../constants';
/**
 * LOGOUT_START
 * @return Object
 */
export const startLogout = () => ({
  type: LOGOUT_START,
});
/**
 * LOGOUT_SUCCESS
 * @param {Object} response
 * @return Object
 */
export const logoutSuccess = response => ({
  type: LOGOUT_SUCCESS,
  response,
});
/**
 * LOGOUT_FAILURE
 * @param {Object} error
 * @return Object
 */
export const logoutFailure = error => ({
  type: LOGOUT_FAILURE,
  error,
});
/**
 * API_LOGIN
 * @return {Promise}
 */
export const logout = async () =>
  async (dispatch) => {
    try {
      // startLogout
      dispatch(startLogout());
      const response = await fetch(API_LOGIN, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }).then((res) => {
        // Check if server return bad response
        // Then throw error
        if (res.status >= 400 && res.status !== 403) {
          throw new Error(i18n.t(NOTIFICATION_TYPES.ERROR_MESSAGE_FROM_SERVER));
        }
        return res.responseMessage;
      });
      // Success
      dispatch(logoutSuccess(response));
    } catch (e) {
      // Dispatch to failure
      dispatch(logoutFailure(e));
    }
  };
