import { Map as MapImmutable } from 'immutable';
import {
  GET_MODULE_INFORMATION_START,
  GET_MODULE_INFORMATION_SUCCESS,
  GET_MODULE_INFORMATION_FAILURE,
  LOAD_MODULE_START,
  LOAD_MODULE_SUCCESS,
  LOAD_MODULE_FAILURE,
} from './events';

const initialState = new MapImmutable({});
const actionHandlers = {};

actionHandlers[LOAD_MODULE_START] = state => state.set('isLoading', true);
actionHandlers[LOAD_MODULE_SUCCESS] = state => state.set('numberModuleWillBeLoad', state.get('numberModuleWillBeLoad') - 1);
actionHandlers[LOAD_MODULE_FAILURE] =
  (state, moduleName) => state.set('moduleName', moduleName);
/**
 * GET_MODULE_INFORMATION_START
 * @param {Object} state Current state
 * @return {Object} Next state
 */
actionHandlers[GET_MODULE_INFORMATION_START] = state =>
  state.set('requesting', true).delete('moduleList');
/**
 * GET_MODULE_INFORMATION_SUCCESS
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[GET_MODULE_INFORMATION_SUCCESS] = (state, action) => state
  .set('requesting', false)
  .set('moduleList', action.response)
  .delete('error')
  .set('numberModuleWillBeLoad', action.response.length);
/**
 * GET_MODULE_INFORMATION_FAILURE
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[GET_MODULE_INFORMATION_FAILURE] = (state, action) =>
  state.set('requesting', false).delete('moduleList').set('error', action.error);
/**
 * Action Handlers
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
export default(state = initialState, action) => {
  if (actionHandlers[action.type] !== undefined) {
    const nextState = actionHandlers[action.type](state, action);
    if (nextState === null || nextState === undefined) {
      return state;
    }
    return nextState;
  }
  return state;
};
