import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  clearMessage,
  clearMessageBox,
  showErrorMessage,
  showSuccessMessage,
} from './actions';
import styles from './style.scss';
import Message from './message';

const classKey = 'message-box';
const CLASS_NAME = _.get(styles, classKey, classKey);

/**
 * MessageBox
 * @extends PureComponent
 */
class MessageBox extends PureComponent {
  constructor(props) {
    super(props);
    this.onClearMessage = this.onClearMessage.bind(this);
    this.renderMessage = this.renderMessage.bind(this);
  }

  /**
   * Remove messages array of message box from reducer on unmount
   */
  componentWillUnmount() {
    _.map(this.props.id, id => this.props.actions.clearMessageBox(id));
  }

  /**
   * Hide message in reducer
   * @param message
   */
  onClearMessage(message) {
    this.props.actions.clearMessage(message.messageBoxId, message.id);
  }

  /**
   * Render a message element
   * @param message
   * @returns {ReactElement}
   */
  renderMessage(message) {
    return (
      <Message
        key={message.id}
        onClearMessage={this.onClearMessage}
        data={message}
      />
    );
  }

  render() {
    const messageComponents = _(this.props.messages)
      .filter(message => !message.hide)
      .map(this.renderMessage)
      .value();

    return (
      <div className={CLASS_NAME}>
        {messageComponents}
      </div>
    );
  }
}

// mapStateToProps
const mapStateToProps = (state, ownProps) => ({
  id: _.flatten([ownProps.id]),
  messages: _([ownProps.id])
    // Convert multidimensional arrays to array
    .flatten()
    .map(id =>
      _.map(state.MessageBoxReducer.get(id), item => ({
        ...item,
        messageBoxId: id,
      }))
    )
    .flatten()
    .value(),
});

// mapDispatchToProps
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, {
    clearMessageBox,
    clearMessage,
  }), dispatch),
});

MessageBox.propTypes = {
  actions: PropTypes.shape({
    clearMessageBox: PropTypes.func.isRequired,
    clearMessage: PropTypes.func.isRequired,
  }).isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  messages: PropTypes.arrayOf(PropTypes.shape({
    messageBoxId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    text: PropTypes.string,
    type: PropTypes.oneOf(['success', 'error']),
    duration: PropTypes.number,
  })),
};

MessageBox.defaultProps = {
  messages: [],
};

const MessageBoxWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageBox);

MessageBoxWrapper.showErrorMessage = showErrorMessage;
MessageBoxWrapper.showSuccessMessage = showSuccessMessage;
export default MessageBoxWrapper;

export { MessageBox as MessageBoxComponent };
