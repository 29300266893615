/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@vibrent/electryon';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import StickyScroll from './stickyScroll';
import styles from './styles.scss';
import {
  STATUS,
} from '../../../constants';

import {
  NOTIFICATION_TYPES,
  APPOINTMENT_URL,
  QUOTA_URL,
  QUOTA_URL_RC,
  SETTING_URL,
  ROLE_NAME,
  HIERARCHY_INSTANCE_EDIT_URL,
  PERMISSIONS,
  CAMPAIGN_URL,
  CASE_MANAGEMENT_URL,
  SEARCH_URL,
  PROSPECT_MANAGEMENT_VIEW_URL,
  SEARCH_NEW_ONE_OFF_TEMPLATE_URL,
  PROSPECT_NEW_ONE_OFF_TEMPLATE_URL,
  REPORTS_LIST_URL,
} from '../../../../../constants';

function createParamsFromObject(params) {
  let result = '';
  _.forEach(params, (value, key) => {
    result += `${key}=${value}&`;
  });
  return _.trimEnd(result, '&');
}
function getHierarchyParam(options) {
  const { hierarchyId, levelId, nodeId } = options;
  return createParamsFromObject({ hid: hierarchyId, lid: levelId, iid: nodeId });
}
export class NotificationsComponent extends Component {
  constructor(props) {
    super(props);
    // Binding functions
    this.onSelectNotification = this.onSelectNotification.bind(this);
  }

  /**
   * select notification
   * @param {Object} item
   */
  async onSelectNotification(item) {
    // Close Popover when select notification
    this.props.onClose();
    const options = JSON.parse(item.options);
    if (options.location && /^Site\//.test(options.location)) {
      this.props.actions.setSiteId(options.location);
    }
    if (item.notificationType !== NOTIFICATION_TYPES.CASE_MANAGEMENT_REMOVE_ASSIGN_KEY &&
    item.notificationType !== NOTIFICATION_TYPES.ONE_OFF_MESSAGE_COMPLETED_KEY) {
      const url = this.getURL(item);
      this.props.history.push(url);
    }
    // save notification status
    if (this.props.typeNotification === STATUS.UN_READ) {
      await this.props.actions.saveNotificationStatus({ id: item.id,
        notificationType: item.notificationType,
        status: STATUS.READ });
    }
  }
  /**
   * Get URL for the notification
   * @param  {Object} notification The notification that you want to get URL
   * @return {String}              The URL
   */
  getURL(notification) {
    const options = JSON.parse(notification.options);

    let url = null;
    let params;
    let role = this.getRole(notification);
    const quotaLocationParam = options.siteId ? options.parentNode : options.location;
    const paramsQuota = new URLSearchParams({ role, location: quotaLocationParam });
    url = `${this.props.isPMI ? QUOTA_URL : QUOTA_URL_RC }?${paramsQuota.toString()}`;
    // Check if roleName is 'ROLE_MC_SITE_MANAGER'
    if (role === ROLE_NAME.ROLE_MC_SITE_MANAGER) {
      const siteId = options.siteId || options.location;
      const siteParams = new URLSearchParams({ role, location: siteId });
      // Then update URL to SETTING_URL
      url = `${SETTING_URL}?${siteParams.toString()}`;
    }

    const { currentActiveProgram } = this.props;
    const userPermission = _.find(currentActiveProgram.permissions, permission =>
      permission.resource === PERMISSIONS.Scheduling.CALENDAR);

    switch (notification.notificationType) {
    case NOTIFICATION_TYPES.NEW_APPOINTMENT_KEY:
    case NOTIFICATION_TYPES.RESCHEDULED_APPOINTMENT_KEY:
    case NOTIFICATION_TYPES.CANCEL_APPOINTMENT_KEY:
    case NOTIFICATION_TYPES.LINKED_PARTICIPANT_APPOINTMENT_KEY:
      // Check if notificationType is:
      // NEW_APPOINTMENT
      // or RESCHEDULED_APPOINTMENT
      // or CANCEL_APPOINTMENT
      // Set url is APPOINTMENT_URL
      this.props.actions.setViewAppointmentParams(options.appointmentId);
      params = new URLSearchParams({ role });
      url = `${APPOINTMENT_URL}?${params.toString()}`;
      break;
    case NOTIFICATION_TYPES.REQUEST_TO_PUBLISH:
    case NOTIFICATION_TYPES.PUBLISHED_NODE_TYPE:
      url = `${HIERARCHY_INSTANCE_EDIT_URL}?${getHierarchyParam(options)}`;
      break;
    case NOTIFICATION_TYPES.APPOINTMENT_ASSIGN_KEY:
    case NOTIFICATION_TYPES.APPOINTMENT_UNASSIGN_KEY:
      role = _.get(userPermission, 'roleName', '');
      params = new URLSearchParams({ role });
      this.props.actions.setViewAppointmentParams(options.appointmentId);
      url = `${APPOINTMENT_URL}?${params.toString()}`;
      break;
    case NOTIFICATION_TYPES.CAMPAIGN_COMPLETED_KEY:
    case NOTIFICATION_TYPES.CAMPAIGN_FAILED_TO_SEND_KEY:
      role = _.get(userPermission, 'roleName', '');
      params = new URLSearchParams({ role, campaignId: options.campaignId });
      url = `${CAMPAIGN_URL}?${params.toString()}`;
      break;
    case NOTIFICATION_TYPES.CASE_MANAGEMENT_ASSIGN_KEY:
      params = new URLSearchParams({
        role,
        engagementListId: options.caseManagementId,
        viewMode: 'VIEW',
        keepFilter: false,
      });
      url = `${CASE_MANAGEMENT_URL}?${params.toString()}`;
      break;
    case NOTIFICATION_TYPES.CASE_MANAGEMENT_RECONTACT_KEY:
      if (options.caseManagementId) {
        role = this.getRoleByPermission(PERMISSIONS.Communication.CASE_MANAGEMENT_ALL);
        params = new URLSearchParams({
          role,
          engagementListId: options.caseManagementId,
          viewMode: 'VIEW',
          keepFilter: false,
        });
        url = `${CASE_MANAGEMENT_URL}?${params.toString()}`;
      } else if (options.isProspect) {
        // If caseManagementId is null mean engagement list is no longer associated
        // Navigate to prospect profile and prevPage is search page if user is prospect
        role = this.getRoleByPermission(PERMISSIONS.prospectManagement);
        params = new URLSearchParams({
          role,
          prevPage: 'searchPage',
          prospectId: options.recontactProfileId,
        });
        url = `${PROSPECT_MANAGEMENT_VIEW_URL}?${params.toString()}`;
      } else {
        // Navigate to participant profile (search page) and prevPage is search page
        role = this.getRoleByPermission(PERMISSIONS.Scheduling.SEARCH);
        params = new URLSearchParams({
          role,
          prevPage: 'searchPage',
          participantId: options.recontactProfileId,
        });
        url = `${SEARCH_URL}?${params.toString()}`;
      }
      break;
    case NOTIFICATION_TYPES.ONE_OFF_MESSAGE_FAILED_TO_SEND_KEY:
      role = this.getRoleByPermission(PERMISSIONS.adhocCampaign);
      params = new URLSearchParams({
        role,
        participantId: options.participantId,
        prospectId: options.prospectId,
        templateId: options.templateId,
      });
      if (options.targetAudience === 'participants') {
        url = `${SEARCH_NEW_ONE_OFF_TEMPLATE_URL}?${params.toString()}`;
      }
      url = `${PROSPECT_NEW_ONE_OFF_TEMPLATE_URL}?${params.toString()}`;
      break;
    case NOTIFICATION_TYPES.USER_EXPORT_REPORT_GENERATED_KEY:
      params = new URLSearchParams({ role });
      url = `${REPORTS_LIST_URL}?${params.toString()}`;
      break;
    default:
      break;
    }
    return url;
  }
  /**
   * Get role
   * @param  {String} permission The permission that you want to get role
   * @return {String} Role name
   */
  getRoleByPermission(permission) {
    const { roleSelected, currentActiveProgram } = this.props;
    // get role can access page by permission.
    const rolesByPermission = currentActiveProgram.permissions
      .filter(p => p.resource.includes(permission));
    // If roleSelected is includes rolesByPermission return name of roleSelected
    // Else need to change to first role in dropdown order by displayName
    if (this.checkIsIncludes(rolesByPermission, roleSelected)) {
      return roleSelected.name;
    }
    const rolesMapping = currentActiveProgram.roles
      .filter(p => this.checkIsIncludes(rolesByPermission, p));
    return _.orderBy(rolesMapping, 'displayName')[0].name;
  }

  /**
   * Get role
   * @param  {Object} item The item that you want to get role
   * @return {String}      Role name
   */
  getRole(item) {
    const { roleSelected, currentActiveProgram } = this.props;
    const arrangeNotiRoleByLevel = _.sortBy(item.notificationRoles, 'level');
    let listRoleName = _.map(arrangeNotiRoleByLevel, r => r.name);
    let defaultRole = _.filter(currentActiveProgram.roles,
      role => (_.includes(listRoleName, role.name)));

    if (_.isEmpty(listRoleName)) {
      const options = JSON.parse(item.options);
      listRoleName = _.sortBy(options.roleNames, 'level');
      defaultRole = _.filter(currentActiveProgram.roles,
        role => (_.includes(listRoleName, role.name)));
    }

    return _.includes(listRoleName, roleSelected.name) || _.isEmpty(defaultRole) ?
      roleSelected.name :
      _.head(defaultRole).name;
  }

  /**
   * Get class name for notification
   * @return {String} The notification class name
   */
  getClassNameNotification() {
    const {
      totalRecent,
      totalEarlier,
      typeNotification,
      recentRequesting,
      earlierRequesting,
    } = this.props;

    const noneNotificationStyle = styles['none-notification'];
    const nomalNotificationStyle = typeNotification === STATUS.UN_READ ? styles['unread-notification'] : styles['read-notification'];
    let style = nomalNotificationStyle;
    if (!recentRequesting && !earlierRequesting) {
      style = totalRecent === 0 && totalEarlier === 0 ?
        noneNotificationStyle : style;
    }
    return style;
  }

  checkIsIncludes = (roles, item) => (roles.findIndex(s => s.roleName === item.name) >= 0);

  render() {
    const {
      totalRecent,
      totalEarlier,
      typeNotification,
      recentNotifications,
      earlierNotifications,
      recentRequesting,
      earlierRequesting,
      currentActiveProgram,
      t,
    } = this.props;
    const recentTitle = t('Recent');
    const earlierTitle = t('Earlier');

    const notificationClassName = this.getClassNameNotification();

    if (totalRecent === -1 || totalEarlier === -1) {
      return (
        <div className="wapper-spinner">
          <Spinner radius={40} />
        </div>
      );
    }
    return (
      <div className={notificationClassName}>
        <StickyScroll
          typeNotification={typeNotification}
          topHeaderTitle={recentTitle}
          stickyTopTitle={earlierTitle}
          stickyBottomTitle={earlierTitle}
          wayPointTitle={earlierTitle}
          recentNotifications={recentNotifications}
          earlierNotifications={earlierNotifications}
          recentRequesting={recentRequesting}
          earlierRequesting={earlierRequesting}
          totalRecent={totalRecent}
          totalEarlier={totalEarlier}
          onSelectNotification={this.onSelectNotification}
          getNotification={this.props.actions.getNotification}
          internationalPhoneSupportEnabled={currentActiveProgram.internationalPhoneSupportEnabled}
          t={this.props.t}
        />
      </div>
    );
  }
}
NotificationsComponent.propTypes = {
  typeNotification: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    setSiteId: PropTypes.func.isRequired,
    getNotification: PropTypes.func.isRequired,
    saveNotificationStatus: PropTypes.func.isRequired,
    setViewAppointmentParams: PropTypes.func.isRequired,
  }).isRequired,
  recentNotifications: PropTypes.arrayOf(PropTypes.string).isRequired,
  recentRequesting: PropTypes.bool,
  earlierNotifications: PropTypes.arrayOf(PropTypes.string).isRequired,
  earlierRequesting: PropTypes.bool,
  currentActiveProgram: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.shape()),
    roles: PropTypes.arrayOf(PropTypes.shape()),
    internationalPhoneSupportEnabled: PropTypes.bool,
  }),
  roleSelected: PropTypes.shape({
    name: PropTypes.string,
  }),
  totalRecent: PropTypes.number,
  totalEarlier: PropTypes.number,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  t: PropTypes.func,
  onClose: PropTypes.func,
  isPMI: PropTypes.bool.isRequired,
};
NotificationsComponent.defaultProps = {
  recentNotifications: [],
  earlierNotifications: [],
  recentRequesting: false,
  earlierRequesting: false,
  totalRecent: 0,
  totalEarlier: 0,
  currentActiveProgram: null,
  roleSelected: {
    name: null,
  },
  t: x => x,
  onClose: null,
};

export default withTranslation()(NotificationsComponent);
