// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-coreModules-authentication-components-footer-styles__footer--33PlVLAG{width:100%;padding-top:5px;padding-bottom:40px;height:24px;text-align:center}.src-coreModules-authentication-components-footer-styles__footer--33PlVLAG .main-footer-container{position:relative;margin-left:48px}.src-coreModules-authentication-components-footer-styles__footer--33PlVLAG .support-footer-container{margin-left:0}.src-coreModules-authentication-components-footer-styles__footer--33PlVLAG .footerCopyright{width:100%;font-size:12px;font-family:Avenir;color:#727173}.src-coreModules-authentication-components-footer-styles__footer--33PlVLAG .privacy-link{font-size:12px;padding-left:8px;color:#0099db;font-weight:600}.src-coreModules-authentication-components-footer-styles__footer--33PlVLAG .footer-logo{width:67.3px;height:16px;margin-left:8.1px;margin-bottom:4px}", ""]);
// Exports
exports.locals = {
	"footer": "src-coreModules-authentication-components-footer-styles__footer--33PlVLAG"
};
module.exports = exports;
