import { RequestUtil } from 'pmt-common';
import { AlertActions } from '@vibrent/electryon';
import {
  UPDATE_USER_PROFILE_START,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
  UPDATE_USER_DETAILS,
} from './events';
import i18n from '../../../../i18n';

export const UPDATE_USER_PROFILE = 'updateUserProfile';

const { showErrorAlert, showSuccessAlert } = AlertActions;

export const updateUserDetails = userData => ({
  type: UPDATE_USER_DETAILS,
  userData,
});

export const updateUserProfileStart = () => ({
  type: UPDATE_USER_PROFILE_START,
});

export const updateUserProfileSuccess = response => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  response,
});

export const updateUserProfileFailure = error => ({
  type: UPDATE_USER_PROFILE_FAILURE,
  error,
});

export const updateUserProfile = userData =>
  async (dispatch) => {
    try {
      dispatch(updateUserProfileStart());
      const url = '/api/userAdmin/user/me';
      const response = await RequestUtil.updateData(url, userData);
      dispatch(showSuccessAlert(
        UPDATE_USER_PROFILE,
        i18n.t('Your changes have been saved'),
      ));
      dispatch(updateUserProfileSuccess(response));
      return response;
    } catch (e) {
      dispatch(showErrorAlert(
        UPDATE_USER_PROFILE,
        e.message,
      ));
      dispatch(updateUserProfileFailure(e));
      return e;
    }
  };
