import React from 'react';
import i18n from '../../../i18n';
import featureNotAvailable from '../images/feature_not_available.png';
import styles from '../../../style/main.scss';

const CLASS_NAME = styles['feature-not-available'];
/**
 * NoFeatureAccess
 * @extends Component
 */
function NoFeatureAccess() {
  const { t } = i18n;
  const urlParams = new URLSearchParams(window.location.search);
  const currentRole = urlParams.get('role');
  let featureName = '';
  let CATIrole = '';
  if (currentRole === 'ROLE_MC_CATI_INTERVIEWER') {
    featureName = 'CATI';
    CATIrole = '"CATI Interviewer"';
  } else if (currentRole === 'ROLE_MC_SUPPORT_ADMIN'
  || currentRole === 'ROLE_MC_SUPPORT_STAFF') {
    featureName = 'Support Tools';
  }
  return (
    <div className={CLASS_NAME}>
      <h1>{featureName}</h1>
      <div className="flex-container container-no-access" >
        <div>
          <div className="bottom-border margin-bottom-25">
            <h2>{t('You do not have access at this time.')}</h2>
            <p className="margin-bottom-25">{t('Contact the System Administrator for more information.')}</p>
          </div>
          {currentRole === 'ROLE_MC_SUPPORT_ADMIN'
          || currentRole === 'ROLE_MC_SUPPORT_STAFF' ?
            <div className="container-width">
              <h2>{t('What does Support Tools do?')}</h2>
              <p>{t('supportToolDescription')}<br />
                <br /><p className="font-weight-600 bottom-margin-0">{t('What Participant information can I update?')}</p>
                {t('supportToolThings')}
              </p>
            </div> : null}
          {currentRole === 'ROLE_MC_CATI_INTERVIEWER' ?
            <div className="container-width">
              <h2>{t('What does CATI do?')}</h2>
              <p>
                {t('catiInformation')}
                <br /><br />
                {t('catiThings', t(CATIrole))}
              </p>
            </div> : null}
        </div>
        <img alt="" src={featureNotAvailable} className="image-alignment" />
      </div>
    </div>
  );
}

export default NoFeatureAccess;
