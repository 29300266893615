// Some libraries are required by other libraries in package.json
// so we need to disable this eslint's rule
/* eslint-disable import/no-extraneous-dependencies */
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.min';
import '@fortawesome/fontawesome-free/css/v4-shims.min.css';
import '@fortawesome/fontawesome-free/js/v4-shims.min';
import '@fortawesome/fontawesome-pro/css/all.min.css';
import '@fortawesome/fontawesome-pro/js/all.min';
import 'url-search-params-polyfill';
import 'survey-core/defaultV2.css';
import 'survey-creator-core/survey-creator-core.css';
import 'react-datepicker/dist/react-datepicker.css';

import * as ReactBoostrapTable from 'react-bootstrap-table';
import ReactBootstrapTableCSS from 'react-bootstrap-table/css/react-bootstrap-table.css';
import ReactSelect from 'react-select';
import * as AsyncPaginate from 'react-select-async-paginate';
import PropTypes from 'prop-types';
import ReactDateTime from 'react-datetime';
import ReactDateTimeCSS from 'react-datetime/css/react-datetime.css';
import * as ReactRouter from 'react-router';
import * as ReactRouterDOM from 'react-router-dom';
import * as ReduxForm from 'redux-form';
import * as Redux from 'redux';
import * as ReactDates from 'react-dates';
import Keycloak from 'keycloak-js';
import * as Electryon from '@vibrent/electryon';
import { Menu, MenuItem, Popover } from '@mui/material';

/*
 * The Report comment requires initialize of react-dates
 * {@link https://github.com/airbnb/react-dates#initialize}
 */
import 'react-dates/initialize';
import ReactDatesCSS from 'react-dates/lib/css/_datepicker.css';
import Parse5 from 'parse5';
import * as ReactTabs from 'react-tabs';
import ReactTabsCSS from 'react-tabs/style/react-tabs.css';
import Base64JS from 'base64-js';
import * as ReactRedux from 'react-redux';
import Lodash from 'lodash';
import Moment from 'moment';
import MomentTimezone from 'moment-timezone';
import * as MomentRange from 'moment-range';
import 'moment/locale/es-us';
import Immutable from 'immutable';
import EventEmmiter3 from 'eventemitter3';
import * as Victory from 'victory';
import URLEncode from 'urlencode';
import AJV from 'ajv';
import AJVKeywords from 'ajv-keywords';
import WhatWGFetch from 'whatwg-fetch';
import UUID from 'uuid';
import * as ReactI18next from 'react-i18next';
import BabelPolyfill from 'babel-polyfill';
import { I18nextProvider } from 'react-i18next';
import React from 'react';
import ReactDOM from 'react-dom';
import * as ReactBoostrap from 'react-bootstrap';

import Router from './router';
import store from './store';
import ModuleLoader from './coreModules/moduleLoader';
import createFetcher from './coreModules/authentication/utils/fetch';
import i18n from './i18n';

// import css
import './style/main.scss';
import './style/common/index.scss';
import './fonts/Avenir-Black.woff';
import './fonts/Avenir-Black.woff2';
import './fonts/Avenir-Heavy.woff2';
import './fonts/Avenir-Heavy.woff';
import './fonts/Avenir-Light.woff2';
import './fonts/Avenir-Light.woff';
import './fonts/Avenir-Medium.woff2';
import './fonts/Avenir-Medium.woff';
import './fonts/Avenir-Roman.woff2';
import './fonts/Avenir-Roman.woff';

const { Provider } = ReactRedux;

const router = (
  <Electryon.Theme>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <div>
          <ModuleLoader store={store} />
          <Router />
        </div>
      </I18nextProvider>
    </Provider>
  </Electryon.Theme>
);

// Export React to global scope
// it include:
// React
window.react = React;
window.ReactBoostrap = ReactBoostrap;
window.fetch = createFetcher(store);
window.ReactDom = ReactDOM;
window.Keycloak = Keycloak;


Electryon.GTMUtil.initTagManager();

ReactDOM.render(router, document.getElementById('root') || document.createElement('div'));

window.ReactBoostrapTable = ReactBoostrapTable;
window.ReactBootstrapTableCSS = ReactBootstrapTableCSS;
window.ReactSelect = ReactSelect;
window.AsyncPaginate = AsyncPaginate;
window.PropTypes = PropTypes;
window.ReactDateTime = ReactDateTime;
window.ReactDateTimeCSS = ReactDateTimeCSS;
window.ReactRouter = ReactRouter;
window.ReactRouterDOM = ReactRouterDOM;
window.ReactSpinner = Electryon.Spinner;
window.ReduxForm = ReduxForm;
window.Redux = Redux;
window.ReactRedux = ReactRedux;
window.Lodash = Lodash;
window.Moment = Moment;
window.MomentTimezone = MomentTimezone;
window.MomentRange = MomentRange;
window.Immutable = Immutable;
window.EventEmmiter3 = EventEmmiter3;
window.Victory = Victory;
window.URLEncode = URLEncode;
window.AJV = AJV;
window.AJVKeywords = AJVKeywords;
window.WhatWGFetch = WhatWGFetch;
window.UUID = UUID;
window.BabelPolyfill = BabelPolyfill;
window.ReactDates = ReactDates;
window.ReactDatesCSS = ReactDatesCSS;
window.Parse5 = Parse5;
window.ReactTabs = ReactTabs;
window.ReactTabsCSS = ReactTabsCSS;
window.Base64JS = Base64JS;
window.Electryon = Electryon;
window.i18n = i18n;
window.ReactI18next = ReactI18next;
window.MUI = { Menu, MenuItem, Popover };
