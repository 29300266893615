import React from 'react';
import PropTypes from 'prop-types';
import {
  Route,
} from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Spinner } from '@vibrent/electryon';
import { appComponents } from '../components';
import FooterComponent from '../components/footer';
import userIsAuthenticated from '../utils/authentication';

const MainView = (props) => {
  const ChildComponent = props.authenticated ?
    userIsAuthenticated(props.component) : props.component;
  const userPermissions = props.currentActiveProgram && props.roleSelected ?
    _.filter(props.currentActiveProgram.permissions, p =>
      (p.action === 'view' && p.roleName === props.roleSelected.name)) : undefined;
  const role = props.roleSelected;
  const injectedPermissionAndRole =
    childProps =>
      (<ChildComponent
        {...childProps}
        permissions={userPermissions}
        role={role}
        userDetail={props.userDetail}
      />);
  return (
    <Route exact={props.exact} path={props.path}>
      <div id="container" className="container-form">
        {props.userDetail && props.numberModuleWillBeLoad === 0 ?
          <Route component={appComponents.Banner} /> :
          null}
        {props.navBar ? <Route component={appComponents.NavBar} /> : null}
        <div
          className={'main-container'}
        >
          {(props.numberModuleWillBeLoad > 0) ?
            <Spinner /> : <Route component={injectedPermissionAndRole} />}
        </div>
        <footer>
          {!props.isPMI && props.userDetail && props.numberModuleWillBeLoad === 0 ?
            <FooterComponent /> : null
          }
        </footer>
      </div>
    </Route>
  );
};

const mapStateToProps = state => ({
  userDetail: state.UserDetail.get('userDetail'),
  currentActiveProgram: state.UserDetail.get('currentActiveProgram'),
  roleSelected: state.UserDetail.get('roleSelected'),
  isPMI: state.UserDetail.get('isPMI'),
  numberModuleWillBeLoad: state.ModuleLoader.get('numberModuleWillBeLoad'),
});

export default connect(
  mapStateToProps,
  null,
)(MainView);

MainView.propTypes = {
  navBar: PropTypes.bool,
  exact: PropTypes.bool,
  authenticated: PropTypes.bool,
  component: PropTypes.func.isRequired,
  path: PropTypes.string,
  userDetail: PropTypes.shape({}),
  currentActiveProgram: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.shape({})),
    roleNames: PropTypes.shape(PropTypes.string),
  }),
  roleSelected: PropTypes.shape(),
  isPMI: PropTypes.bool.isRequired,
  numberModuleWillBeLoad: PropTypes.number,
};

MainView.defaultProps = {
  navBar: false,
  exact: false,
  authenticated: false,
  path: undefined,
  userDetail: undefined,
  currentActiveProgram: undefined,
  roleSelected: undefined,
  numberModuleWillBeLoad: -1,
};
