// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-coreModules-authentication-components-roleSelector-styles__single-role--Cw4KWsTT{margin-right:16px;margin-left:16px}", ""]);
// Exports
exports.locals = {
	"single-role": "src-coreModules-authentication-components-roleSelector-styles__single-role--Cw4KWsTT"
};
module.exports = exports;
