// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-coreModules-authentication-components-common-messageBox-style__message-box--1FM7n9V0 .message{position:relative;margin:10px 0;z-index:inherit;border:solid 1px #caead1;border-radius:2px;font-size:16px;font-weight:normal;text-align:left;padding:16px 40px 16px 20px}.src-coreModules-authentication-components-common-messageBox-style__message-box--1FM7n9V0 .message.success{background-color:#d9efde;color:#146228;border-color:#146228}.src-coreModules-authentication-components-common-messageBox-style__message-box--1FM7n9V0 .message.success .close{font-size:21px;font-weight:700;line-height:1;padding:0 18px;height:100%;position:absolute;top:0;right:0;background-color:rgba(0,0,0,0);border:none;outline:none;cursor:pointer;opacity:1;color:#146228}.src-coreModules-authentication-components-common-messageBox-style__message-box--1FM7n9V0 .message.error{background-color:#fdf2e5;color:#c12001;border-color:#c12001}.src-coreModules-authentication-components-common-messageBox-style__message-box--1FM7n9V0 .message.error .close{font-size:21px;font-weight:700;line-height:1;padding:0 18px;height:100%;position:absolute;top:0;right:0;background-color:rgba(0,0,0,0);border:none;outline:none;cursor:pointer;opacity:1;color:#c12001}", ""]);
// Exports
exports.locals = {
	"message-box": "src-coreModules-authentication-components-common-messageBox-style__message-box--1FM7n9V0"
};
module.exports = exports;
