/**
 * Action for session keeper
 */
import {
  SET_LAST_TIME_INTERACT_UI,
  PING_SERVER_START,
  PING_SERVER_SUCCESS,
  PING_SERVER_FAILURE,
  IS_CATI_SESSION_ACTIVE_FAILURE,
  IS_CATI_SESSION_ACTIVE_START,
  IS_CATI_SESSION_ACTIVE_SUCCESS,
} from './events';
// import api from constants
import {
  API_PING_SERVER,
  API_CATI_SESSION,
} from '../../constants';

/**
 * Set last time user interact UI
 * @return Object
 */
export const setLastTimeInteractUI = time => ({
  type: SET_LAST_TIME_INTERACT_UI,
  time,
});

/**
 * Ping server start
 * @return Object
 */
export const pingServerStart = () => ({
  type: PING_SERVER_START,
});
/**
 * Ping server success
 * @param {Object} response
 * @return Object
 */
export const pingServerSuccess = response => ({
  type: PING_SERVER_SUCCESS,
  response,
});
/**
 * Ping server failure
 * @param {Object} error
 * @return Object
 */
export const pingServerFailure = error => ({
  type: PING_SERVER_FAILURE,
  error,
});

/**
 * Ping server
 * @return {Promise}
 */
export const pingServer = async () =>
  async (dispatch) => {
    try {
      dispatch(pingServerStart());
      const response = await window.fetch(API_PING_SERVER, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          Pragma: 'no-cache',
        },
        credentials: 'include',
      }).then((res) => {
        if (res.status >= 400) {
          throw new Error(res.json());
        }
        return res.json();
      });
      if (!response.Status) {
        window.localStorage.setItem('activeCatiSession', false);
      }
      // Success
      dispatch(pingServerSuccess(response));
    } catch (e) {
      // Dispatch to failure
      dispatch(pingServerFailure(e));
    }
  };
/**
 * Get CATI Session status start
 * @return Object
 */
export const getCatiSessionStart = () => ({
  type: IS_CATI_SESSION_ACTIVE_START,
});
/**
 * Get CATI Session status success
 * @param {Object} response
 * @return Object
 */
export const getCatiSessionSuccess = response => ({
  type: IS_CATI_SESSION_ACTIVE_SUCCESS,
  response,
});
/**
 * Get CATI Session status failure
 * @param {Object} error
 * @return Object
 */
export const getCatiSessionFailure = error => ({
  type: IS_CATI_SESSION_ACTIVE_FAILURE,
  error,
});

/** Get CATI Session status
 *
 * @return {Promise}
 */
export const getCatiSession = async () =>
  async (dispatch) => {
    try {
      dispatch(getCatiSessionStart());
      const response = await window.fetch(API_CATI_SESSION, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          Pragma: 'no-cache',
        },
        credentials: 'include',
      }).then((res) => {
        if (res.status >= 400) {
          throw new Error(res.json());
        }
        return res.json();
      });
      if (!response.Status) {
        window.localStorage.setItem('activeCatiSession', false);
        window.localStorage.removeItem('CurrentActiveSessionId');
        window.localStorage.removeItem('ImpersonatedParticipant');
      }
      // Success
      dispatch(getCatiSessionSuccess(response));
    } catch (e) {
      // Dispatch to failure
      dispatch(getCatiSessionFailure(e));
    }
  };
