import { Map as MapImmutable } from 'immutable';
import {
  GET_TOTAL_NOTIFICATIONS_START,
  GET_TOTAL_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_START,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  SAVE_NOTIFICATION_STATUS_START,
  SAVE_NOTIFICATION_STATUS_SUCCESS,
  SAVE_NOTIFICATION_STATUS_FAILURE,
} from './events';

const TIME_TYPE = {
  RECENT: 'recent',
  EARLIER: 'earlier',
};
const TYPE_NOTIFICATION = {
  READ: 'READ',
  UN_READ: 'UNREAD',
};
const initialState = new MapImmutable({
  totalUnread: 0,
  totalRequesting: false,

  unreadEarlierNotifications: [],
  unreadTotalEarlier: -1,
  unreadEarlierRequesting: false,
  unreadRecentNotifications: [],
  unreadTotalRecent: -1,
  unreadRecentRequesting: false,

  readEarlierNotifications: [],
  readTotalEarlier: -1,
  readEarlierRequesting: false,
  readRecentNotifications: [],
  readTotalRecent: -1,
  readRecentRequesting: false,
});

const actionHandlers = {};
/**
 * GET_TOTAL_NOTIFICATIONS_SUCCESS
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[GET_TOTAL_NOTIFICATIONS_SUCCESS] = (state, action) =>
  state.set('totalUnread', action.total).set('totalRequesting', false);
/**
 * GET_TOTAL_NOTIFICATIONS_START
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[GET_TOTAL_NOTIFICATIONS_START] = state =>
  state.set('totalUnread', 0).set('totalRequesting', true);

/**
 * GET_NOTIFICATIONS_START
 * @param {Object} state Current state
 * @return {Object} Next state
 */
actionHandlers[GET_NOTIFICATIONS_START] = (state, action) => {
  const status = action.status === TYPE_NOTIFICATION.READ ? 'read' : 'unread';
  let newState = state.delete('notificationsStatus');
  if (state.get('notificationsStatus')) {
    newState = newState.delete('notificationsStatus');
  }
  if (action.timeType === TIME_TYPE.RECENT) {
    return newState.set(`${status}RecentRequesting`, true);
  }
  return newState.set(`${status}EarlierRequesting`, true);
};

const updateDataForRecentEarlier = (state, action, typeNotification) => {
  const { total, offset, timeType } = action.response;
  let data = action.response.data;
  if (timeType === TIME_TYPE.RECENT) {
    if (offset > 0) {
      const currentNotifications = state.get(`${typeNotification}RecentNotifications`);
      data = currentNotifications.concat(data);
    }
    return state
      .set(`${typeNotification}RecentRequesting`, false)
      .set(`${typeNotification}TotalRecent`, total)
      .delete('error')
      .set(`${typeNotification}RecentNotifications`, data);
  }
  if (offset > 0) {
    const currentNotifications = state.get(`${typeNotification}EarlierNotifications`);
    data = currentNotifications.concat(data);
  }
  return state
    .set(`${typeNotification}EarlierRequesting`, false)
    .set(`${typeNotification}TotalEarlier`, total)
    .delete('error')
    .set(`${typeNotification}EarlierNotifications`, data);
};
/**
 * GET_NOTIFICATIONS_SUCCESS
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */

actionHandlers[GET_NOTIFICATIONS_SUCCESS] = (state, action) => {
  const { status } = action.response;
  if (status === TYPE_NOTIFICATION.READ) {
    return updateDataForRecentEarlier(state, action, 'read');
  }
  return updateDataForRecentEarlier(state, action, 'unread');
};
/**
 * GET_NOTIFICATIONS_FAILURE
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[GET_NOTIFICATIONS_FAILURE] = (state, action) =>
  state.delete('unreadEarlierNotifications')
    .delete('unreadTotalEarlier')
    .set('unreadEarlierRequesting', false)
    .delete('unreadRecentNotifications')
    .delete('unreadTotalRecent')
    .set('unreadRecentRequesting', false)
    .delete('readEarlierNotifications')
    .delete('readTotalEarlier')
    .set('readEarlierRequesting', false)
    .delete('readRecentNotifications')
    .delete('readTotalRecent')
    .set('readRecentRequesting', false)
    .set('error', action.error);

/**
 * SAVE_NOTIFICATION_STATUS_START
 * @param {Object} state Current state
 * @return {Object} Next state
 */
actionHandlers[SAVE_NOTIFICATION_STATUS_START] = state =>
  state.set('saving', true)
    .delete('notificationsStatus')
    .delete('notificationsStatusError');
/**
 * SAVE_NOTIFICATION_STATUS_SUCCESS
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[SAVE_NOTIFICATION_STATUS_SUCCESS] = (state, action) =>
  state
    .set('saving', false)
    .set('notificationsStatus', action.response);
/**
 * SAVE_NOTIFICATION_STATUS_FAILURE
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[SAVE_NOTIFICATION_STATUS_FAILURE] = (state, action) =>
  state
    .set('saving', false)
    .set('notificationsStatusError', action.error);

/**
 * Action Handlers
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
export default (state = initialState, action) => {
  if (actionHandlers[action.type] !== undefined) {
    const nextState = actionHandlers[action.type](state, action);
    if (nextState === null || nextState === undefined) {
      return state;
    }
    return nextState;
  }
  return state;
};
