import fetch from 'isomorphic-fetch';
import i18n from '../../../../i18n';
import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  CHECK_LOGIN_START,
  CHECK_LOGIN_SUCCESS,
  CHECK_LOGIN_FAILURE,
  GET_KEYCLOAK_URL_START,
  GET_KEYCLOAK_URL_SUCCESS,
  GET_KEYCLOAK_URL_FAILURE,
  USER_DETAIL,
  GET_USER_DETAIL_START,
  GET_USER_DETAIL_FAILURE,
  GET_USER_DETAIL_SUCCESS,
} from './events';
import {
  API_LOGIN,
  NOTIFICATION_TYPES,
  HTTP_NOT_FOUND_RESPONSE,
  API_KEYCLOAK_URL,
  GET_USER_DETAIL_API,
} from '../../constants';
import { addFeatureTogglePermissions, addSchedulingPermissions } from '../../actions/userActions';

export const userDetail = response => ({
  type: USER_DETAIL,
  response,
});

export const startGetKeyCloakURL = () => ({
  type: GET_KEYCLOAK_URL_START,
});

export const getKeyCloakURLSuccess = response => ({
  type: GET_KEYCLOAK_URL_SUCCESS,
  response,
});

export const getKeyCloakURLFailure = error => ({
  type: GET_KEYCLOAK_URL_FAILURE,
  error,
});

export const getKeyCloakURL = async () =>
  async (dispatch) => {
    try {
      dispatch(startGetKeyCloakURL());
      const url = API_KEYCLOAK_URL;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }).then((res) => {
        // Check API Not Found
        if (res.status >= 400) {
          throw new Error(i18n.t(NOTIFICATION_TYPES.ERROR_MESSAGE_FROM_SERVER));
        }
        return res.json();
      });
      // Success
      dispatch(getKeyCloakURLSuccess(response));
      window.localStorage.setItem('keycloakUrl', `${response.url}/realms/${response.realm}/account/password`);
    } catch (e) {
      // Dispatch to failure
      dispatch(getKeyCloakURLFailure(e));
    }
  };

export const startLogin = () => ({
  type: LOGIN_START,
});

export const loginSuccess = response => ({
  type: LOGIN_SUCCESS,
  response,
});

export const loginFailure = error => ({
  type: LOGIN_FAILURE,
  error,
});

export const login = async (email, password) =>
  async (dispatch) => {
    try {
      dispatch(startLogin());
      const url = API_LOGIN;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          email,
          password,
        }),
      }).then((res) => {
        // Check API Not Found
        if (res.status === HTTP_NOT_FOUND_RESPONSE.CODE) {
          throw new Error(HTTP_NOT_FOUND_RESPONSE.MESSAGE);
        }
        return res.json();
      });
      // Success
      dispatch(loginSuccess(response));
    } catch (e) {
      // Dispatch to failure
      dispatch(loginFailure(e));
    }
  };

export const getStartUserInfo = () => ({
  type: GET_USER_DETAIL_START,
});

export const getUserInfoSuccess = response => ({
  type: GET_USER_DETAIL_SUCCESS,
  response,
});

export const getUserInfoFailure = error => ({
  type: GET_USER_DETAIL_FAILURE,
  error,
});

export const getUserInfoDetails = async (token, idToken, refreshToken, program) =>
  async (dispatch) => {
    try {
      dispatch(getStartUserInfo());
      const url = GET_USER_DETAIL_API;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          access_token: token,
          id_token: idToken,
          refresh_token: refreshToken,
          programCode: program,
        }),
      }).then((res) => {
        // Check API Not Found
        if (res.status === HTTP_NOT_FOUND_RESPONSE.CODE) {
          throw new Error(HTTP_NOT_FOUND_RESPONSE.MESSAGE);
        }
        return res.text();
      });
      // Success
      dispatch(getUserInfoSuccess(response));
    } catch (e) {
      // Dispatch to failure
      dispatch(getUserInfoFailure(e));
    }
  };

export const checkLoginStart = () => ({
  type: CHECK_LOGIN_START,
});

export const checkLoginFailure = error => ({
  type: CHECK_LOGIN_FAILURE,
  error,
});

export const checkLoginSuccess = (response) => {
  const userResponse = addFeatureTogglePermissions(response);
  const res = addSchedulingPermissions(userResponse);
  return {
    type: CHECK_LOGIN_SUCCESS,
    userDetail: res,
  };
};

export const checkLogin = async () =>
  async (dispatch) => {
    try {
      dispatch(checkLoginStart());
      const url = API_LOGIN;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          Pragma: 'no-cache',
        },
        credentials: 'include',
      }).then((res) => {
        // Check if server return bad response
        // Then throw error
        if (res.status >= 400) {
          throw new Error(i18n.t(NOTIFICATION_TYPES.ERROR_MESSAGE_FROM_SERVER));
        }
        return res.json();
      });
      // Success
      dispatch(checkLoginSuccess(response));
      return response;
    } catch (e) {
      // Dispatch to failure
      dispatch(checkLoginFailure(e));
      return e;
    }
  };
