import Banner from './banner';
import NotificationReducer from './notificationsModal/reducer';
import Login from './login';
import LoginReducer from './login/reducer';
import Logout from './logout';
import LogoutReducer from './logout/reducer';
import NotFound from './notFound';
import NoFeatureAccess from './noFeatureAccess';
import ProtocolBuilder from './protocolBuilder';
import AccessDenied from './accessDenied';
import SessionExpired from './sessionExpired';
import NavBar from './navbar';
import ManagerAccount from './managerAccount';
import EmptyDashboard from './emptyDashboard';
import SessionRefresher from './sessionRefresher';
import SessionRefresherReducer from './sessionRefresher/reducer';
import GlobalMessage from './globalMessage';
import GlobalMessageReducer from './globalMessage/reducer';
import UserProfile from './userProfile';

export const appComponents = {
  Banner,
  Login,
  Logout,
  NotFound,
  NoFeatureAccess,
  ProtocolBuilder,
  NavBar,
  ManagerAccount,
  SessionExpired,
  EmptyDashboard,
  SessionRefresher,
  AccessDenied,
  GlobalMessage,
  UserProfile,
};

export const reducers = {
  LoginReducer,
  LogoutReducer,
  NotificationReducer,
  SessionRefresherReducer,
  GlobalMessageReducer,
};
