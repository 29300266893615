import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@vibrent/electryon';

/**
 * NotFound
 * @extends Component
 */
const NotFound = (props) => {
  const { t } = useTranslation();
  const [countDown, setCountDown] = useState(10);

  const updateCountDown = () => {
    setCountDown(countDown - 1);
  };

  const goBack = () => {
    props.history.go(-1);
  };

  const goToHomePage = () => {
    props.history.push('/');
  };

  useEffect(() => {
    if (countDown === 0) {
      clearTimeout();
      goToHomePage();
    } else {
      setTimeout(updateCountDown, 1000);
    }
  }, [countDown]);

  if (!props.isHaveNoRoutes && props.numberModuleWillBeLoad === 0) {
    return (
      <div className="text-center">
        <h1>{t("We're sorry")}</h1>
        <h2>{t('The page you are looking for cannot be found.')}</h2>
        <p>
          {t('automaticallyRedirect')}{' '}
          <a
            tabIndex="0"
            role="button"
            onClick={goToHomePage}
          >
            {t('home')}{' '}
          </a>
          {t('page in')}{' '}
          <span id="countDown" >
            {countDown}
          </span>
          {' '}{t('seconds.')}
          <br />
          {t('Or')}{' '}
          <a
            className="testlink"
            tabIndex="0"
            role="button"
            onClick={goBack}
          >
            {t('click here')}{' '}
          </a>
          {' '}{t('to go back to the previous page.')}
        </p>
      </div>
    );
  }

  return (<div className="text-center">
    <Spinner radius={120} color={'#0099db'} stroke={2} />
  </div>);
};

const mapStateToProps = state => ({
  numberModuleWillBeLoad: state.ModuleLoader.get('numberModuleWillBeLoad'),
});

NotFound.propTypes = {
  history: PropTypes.shape({
    go: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  numberModuleWillBeLoad: PropTypes.number,
  isHaveNoRoutes: PropTypes.bool,
};

NotFound.defaultProps = {
  numberModuleWillBeLoad: undefined,
  isHaveNoRoutes: false,
};

export default connect(
  mapStateToProps,
  null,
)(NotFound);

export const NotFoundComponent = NotFound;
