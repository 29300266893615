import Ajv from 'ajv';
import ajvKeywords from 'ajv-keywords';
import _ from 'lodash';
import moment from 'moment';
import uuid from 'uuid';
import DateUtil from '../dateUtil';

// Create AJV instance
const ajv = new Ajv({ useDefaults: true, allErrors: true, $data: true, jsonPointers: true });
ajvKeywords(ajv);

const definition = ajvKeywords.get('dynamicDefaults').definition;
// Default UUID is version 1
definition.DEFAULTS.uuid = () => uuid.v1();

// Add customFormat
ajv.addKeyword('customFormat', {
  modifying: true,
  // Types of property that will be converted
  type: ['object', 'integer'],
  metaSchema: {
    // Expected output of property
    enum: ['moment'],
  },
  compile() {
    return (data, dataPath, parentData, parentDataProperty) => {
      if (!moment.isMoment(data) && _.isNumber(data)) {
        _.set(parentData, parentDataProperty, DateUtil.utc(moment.unix(data)));
      }
      return true;
    };
  },
});

/**
 * Base model class
 */
class BaseModel {
  /**
   * Constructor
   * @param {String} name Name of model
   * @param {Object} schema The schema
   */
  constructor(name, schema) {
    // Check if the model does not have name or schema
    if (_.isNil(name) || _.isNil(schema)) {
      throw new Error('Model name and schema are required.');
    }

    this.schema = _.cloneDeep(schema);
    this.name = name;
  }

  /**
   * Init model data
   * @param  {Object} initData The initial data
   * @return {[type]}          [description]
   */
  init(initData) {
    const data = initData || {};
    // Validate data
    const valid = ajv.validate(this.schema, data);

    // Check if data is valid
    if (valid) {
      return data;
    }

    // Data is invalid
    throw new Error(`Cannot init ${this.name}`, ajv.errors);
  }

  /**
   * Validate data
   * Set isRaw to true if we want to keep the result in raw data.
   * By default the data will be format
   * @param  {Object}  data The data need to be validated.
   * @param  {Boolean} isRaw Should parse the result to Raw Data
   * @param  {Object}  schema The schema
   * @return {Object}
   */
  validate(data, isRaw = false, schema = this.schema) {
    const valid = ajv.validate(schema, data);

    if (valid) {
      if (isRaw) {
        return _.mapValues(data, (v, k) => {
          const rawFormat = _.get(schema.properties[k], 'rawFormat');
          if (rawFormat) {
            return rawFormat(v);
          }
          return v;
        });
      }
      return data;
    }

    return {
      errors: ajv.errors,
    };
  }

  /**
   * Validate prop types
   * @param  {Object} props
   * @param  {String} propName
   * @param  {String} componentName
   * @return {Object} Null if prop is valid, otherwise return Error
   */
  validatePropTypes(props, propName, componentName) {
    const value = props[propName];

    // Check if value is null or empty
    if (_.isNil(value) || _.isEmpty(value)) {
      return null;
    }

    // Validate value
    const result = this.validate(_.cloneDeep(value));

    // Check if we find errors
    if (result.errors) {
      let msg = [
        `${componentName}.${propName} must be ${this.name} type.`,
      ];
      const errors = _.map(result.errors, error =>
        `${this.name}${error.dataPath}: ${error.message}.`
      );
      msg = msg.concat(errors);

      return new Error(msg.join(' '));
    }

    return null;
  }
}

// Export
export default BaseModel;
