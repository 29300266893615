import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { Button } from '@vibrent/electryon';
import { Badge, Popover } from '@mui/material';
import * as style from './styles.scss';
import Tabs from './tabs';
import NotificationMenu, { NOTIFICATION_MENU_MODE } from './notificationMenu';
import {
  TYPE_SECTION,
  STATUS,
} from '../constants';

import Notifications from './notifications/components/index';

const className = style['notifications-wrapper'];
const TAB_MENUS = [NOTIFICATION_MENU_MODE.UNREAD_ALL, NOTIFICATION_MENU_MODE.READ_ALL];
const TAB_INDEX = {
  READ: 1,
  UN_READ: 0,
};

class NotificationsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: TAB_INDEX.UN_READ,
      now: moment().valueOf(),
      anchor: null,
    };
    this.onTabSelect = this.onTabSelect.bind(this);
    this.loadData = this.loadData.bind(this);
    this.resetTabIndex = this.resetTabIndex.bind(this);
    this.handleOpenPopover = this.handleOpenPopover.bind(this);
    this.handleClosePopover = this.handleClosePopover.bind(this);
  }
  componentDidMount() {
    // Check if userDetail is NOT null or defined
    // Then get the total notification
    if (!_.isNil(this.props.userDetail) && !this.props.totalRequesting) {
      moment.locale(this.props.userDetail.preferredLanguage.locale);
      this.props.actions.getTotalNotifications();
    }
  }

  componentDidUpdate(prevProps) {
    const { userDetail } = this.props;
    if (userDetail.preferredLanguage.locale !==
      prevProps.userDetail.preferredLanguage.locale
    ) {
      moment.locale(userDetail.preferredLanguage.locale);
      // force moment to rerender date
      this.forceUpdate();
    }
    if (!_.isEmpty(this.props.notificationsStatus)) {
      const typeSection = _.get(this.props.notificationsStatus, 'typeSection', '');
      switch (typeSection) {
      case TYPE_SECTION.RECENT:
        this.loadRecentSectionData();
        break;
      case TYPE_SECTION.EARLIER:
        this.loadDataEarlierSectionData();
        break;
      default:
        this.loadData(this.state.index);
      }
    }
  }

  onTabSelect(index) {
    this.setState({ index });
    this.loadData(index);
  }

  handleOpenPopover(e) {
    this.setState({ anchor: e.currentTarget });
    this.loadData(this.state.index);
  }

  handleClosePopover() {
    this.setState({ anchor: null });
    this.resetTabIndex();
  }

  loadRecentSectionData() {
    const { unreadRecentNotifications, readRecentNotifications } = this.props;
    const now = moment().valueOf();
    const last24Hour = moment(now).subtract(1, 'd').valueOf();
    this.props.actions.getNotification(
      STATUS.READ,
      TYPE_SECTION.RECENT,
      last24Hour,
      now,
      0,
      readRecentNotifications.length - 1,
    );

    this.props.actions.getNotification(
      STATUS.UN_READ,
      TYPE_SECTION.RECENT,
      last24Hour,
      now,
      0,
      unreadRecentNotifications.length - 1,
    );
    if (this.state.now !== now) {
      this.setState({
        now,
      });
    }
  }

  loadDataEarlierSectionData() {
    const { unreadEarlierNotifications, readEarlierNotifications } = this.props;
    const now = moment().valueOf();
    if (this.state.now !== now) {
      this.setState({
        now,
      });
    }
    const last24Hour = moment(now).subtract(1, 'd').valueOf();

    this.props.actions.getNotification(
      STATUS.READ,
      TYPE_SECTION.EARLIER,
      0,
      last24Hour,
      0,
      readEarlierNotifications.length - 1,
    );

    this.props.actions.getNotification(
      STATUS.UN_READ,
      TYPE_SECTION.EARLIER,
      0,
      last24Hour,
      0,
      unreadEarlierNotifications.length - 1,
    );
  }

  loadData(index) {
    const now = moment().valueOf();
    if (this.state.now !== now) {
      this.setState({
        now,
      });
    }
    const last24Hour = moment(now).subtract(1, 'd').valueOf();
    // Load Read data
    if (index === TAB_INDEX.READ) {
      this.props.actions.getNotification(STATUS.READ, TYPE_SECTION.EARLIER, 0, last24Hour);
      this.props.actions.getNotification(STATUS.READ, TYPE_SECTION.RECENT, last24Hour, now);
    }
    // Load Unread data
    if (index === TAB_INDEX.UN_READ) {
      this.props.actions.getNotification(STATUS.UN_READ, TYPE_SECTION.EARLIER, 0, last24Hour);
      this.props.actions.getNotification(STATUS.UN_READ, TYPE_SECTION.RECENT, last24Hour, now);
    }
  }

  resetTabIndex() {
    this.setState({ index: TAB_INDEX.UN_READ });
  }

  isdisabledHeaderMenu() {
    const {
      unreadTotalRecent,
      unreadTotalEarlier,
      readTotalRecent,
      readTotalEarlier,
    } = this.props;
    if (this.state.index === TAB_INDEX.UN_READ) {
      return (unreadTotalRecent === -1 && unreadTotalEarlier === -1)
      || (unreadTotalRecent === 0 && unreadTotalEarlier === 0);
    }
    return (readTotalRecent === -1 && readTotalEarlier === -1)
    || (readTotalRecent === 0 && readTotalEarlier === 0);
  }

  renderUnreadNotification() {
    return (
      <Notifications
        typeNotification={STATUS.UN_READ}
        actions={this.props.actions}
        recentNotifications={this.props.unreadRecentNotifications}
        recentRequesting={this.props.unreadRecentRequesting}
        earlierNotifications={this.props.unreadEarlierNotifications}
        earlierRequesting={this.props.unreadEarlierRequesting}
        userDetail={this.props.userDetail}
        currentActiveProgram={this.props.currentActiveProgram}
        roleSelected={this.props.roleSelected}
        totalRecent={this.props.unreadTotalRecent}
        totalEarlier={this.props.unreadTotalEarlier}
        history={this.props.history}
        onClose={this.handleClosePopover}
        isPMI={this.props.isPMI}
      />
    );
  }

  renderReadNotification() {
    return (
      <Notifications
        typeNotification={STATUS.READ}
        actions={this.props.actions}
        recentNotifications={this.props.readRecentNotifications}
        recentRequesting={this.props.readRecentRequesting}
        earlierNotifications={this.props.readEarlierNotifications}
        earlierRequesting={this.props.readEarlierRequesting}
        userDetail={this.props.userDetail}
        currentActiveProgram={this.props.currentActiveProgram}
        roleSelected={this.props.roleSelected}
        totalRecent={this.props.readTotalRecent}
        totalEarlier={this.props.readTotalEarlier}
        history={this.props.history}
        onClose={this.handleClosePopover}
        isPMI={this.props.isPMI}
      />
    );
  }

  renderTabNotification() {
    const headerTypeNotification = [STATUS.UN_READ, STATUS.READ];
    const typeNotifications = [this.renderUnreadNotification(), this.renderReadNotification()];
    return (
      <Tabs
        onSelect={this.onTabSelect}
        selectedIndex={this.state.index}
        headerTitles={headerTypeNotification}
        contents={typeNotifications}
        t={this.props.t}
      />
    );
  }

  renderHeader() {
    const {
      index,
      now,
    } = this.state;
    return (
      <div className="notifications-header">
        <div className="text-center">
          <b>{this.props.t('NOTIFICATIONS')}</b>
        </div>
        <NotificationMenu
          lastestQueryTime={this.state.now}
          from={0}
          to={now}
          mode={TAB_MENUS[index]}
          disabled={this.isdisabledHeaderMenu()}
          t={this.props.t}
        />
      </div>
    );
  }

  renderNotificationsOverlay() {
    return (
      <Popover id="popover-positioned-bottom" className={style['notifications-popover-wrapper']}>
        {this.renderHeader()}
        {this.renderTabNotification()}
      </Popover>
    );
  }

  render() {
    const { totalUnread } = this.props;
    const icon = (
      <Badge color={totalUnread <= 0 ? 'default' : 'error'} badgeContent={totalUnread <= 0 ? null : totalUnread}>
        <i className="notification-icon far fa-bell" />
      </Badge>
    );
    return (
      <div className={className}>
        <Button onClick={this.handleOpenPopover} icon={icon} />
        <Popover
          open={Boolean(this.state.anchor)}
          anchorEl={this.state.anchor}
          onClose={this.handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            horizontal: 'right',
          }}
        >
          <div id="popover-positioned-bottom" className={style['notifications-popover-wrapper']}>
            {this.renderHeader()}
            {this.renderTabNotification()}
          </div>
        </Popover>
      </div>
    );
  }
}

NotificationsModal.propTypes = {
  actions: PropTypes.shape({
    setSiteId: PropTypes.func.isRequired,
    getNotification: PropTypes.func.isRequired,
    getTotalNotifications: PropTypes.func.isRequired,
    saveNotificationStatus: PropTypes.func.isRequired,
    setViewAppointmentParams: PropTypes.func.isRequired,
  }).isRequired,
  totalUnread: PropTypes.number,
  totalRequesting: PropTypes.bool,
  // Information of user
  userDetail: PropTypes.shape({
    username: PropTypes.string,
    fullname: PropTypes.string,
    createdAt: PropTypes.string,
    canSwitchViewMode: PropTypes.bool,
    preferredLanguage: PropTypes.shape({
      locale: PropTypes.string,
    }),
  }),
  currentActiveProgram: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.shape()),
    permissions: PropTypes.arrayOf(PropTypes.shape()),
  }),
  roleSelected: PropTypes.shape({
    name: PropTypes.string,
  }),
  unreadRecentNotifications: PropTypes.arrayOf(PropTypes.string).isRequired,
  unreadRecentRequesting: PropTypes.bool,
  unreadEarlierNotifications: PropTypes.arrayOf(PropTypes.string).isRequired,
  unreadEarlierRequesting: PropTypes.bool,
  unreadTotalRecent: PropTypes.number,
  unreadTotalEarlier: PropTypes.number,

  readEarlierNotifications: PropTypes.arrayOf(PropTypes.string).isRequired,
  readRecentNotifications: PropTypes.arrayOf(PropTypes.string).isRequired,
  readTotalRecent: PropTypes.number,
  readTotalEarlier: PropTypes.number,
  readRecentRequesting: PropTypes.bool,
  readEarlierRequesting: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  notificationsStatus: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
  isPMI: PropTypes.bool.isRequired,
};

NotificationsModal.defaultProps = {
  totalUnread: 0,
  totalRequesting: false,
  actions: {},
  userDetail: null,
  currentActiveProgram: null,
  roleSelected: {
    name: null,
  },
  unreadRecentRequesting: false,
  unreadEarlierRequesting: false,
  unreadTotalRecent: 0,
  unreadTotalEarlier: 0,

  readTotalRecent: 0,
  readTotalEarlier: 0,
  readRecentRequesting: false,
  readEarlierRequesting: false,
  notificationsStatus: {},
};

export default NotificationsModal;
