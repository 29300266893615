// milliseconds

// Time for message shown before hiding
export const DEFAULT_DURATION = 5000;

// Time for wait for animation ending to hide message
export const ANIMATION_DURATION = 150;

export const DEFAULT_OPTIONS = {
  clearOtherMessages: true,
  duration: DEFAULT_DURATION,
};
