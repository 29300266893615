import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  ControlLabel,
} from 'react-bootstrap';
import styles from './styles.scss';

const CLASS_NAME = styles['colored-checkbox'];

/**
 * ColoredCheckbox component
 * @extends PureComponent
 */
class ColoredCheckbox extends PureComponent {
  /**
   * Constructor
   * @param {Object} props The component's props
   */
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  /**
   * Trigger change event to parent component
   * @param {Object} e The event data
   */
  onChange(e) {
    this.props.onChange(e.target.checked);
  }

  /**
   * Render colored checkbox component
   * @return {Element} React element
   */
  render() {
    const {
      name,
      className,
      checked,
      disabled,
      children,
    } = this.props;
    const id = `${name}${(new Date()).getTime()}`;
    return (
      <span className={`${className} ${CLASS_NAME}`}>
        <ControlLabel className="colored-checkbox-content" htmlFor={id}>
          {children}
          <input
            type="checkbox"
            checked={checked}
            disabled={disabled}
            id={id}
            name={name}
            onChange={this.onChange}
          />
          <span className="colored-checkbox-checkmark" />
        </ControlLabel>
      </span>
    );
  }
}

ColoredCheckbox.propTypes = {
  name: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

ColoredCheckbox.defaultProps = {
  name: 'checkbox',
  className: '',
  checked: false,
  disabled: false,
  onChange: () => (null),
};

export default ColoredCheckbox;
