import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as style from './style.scss';
// import component from messageBox
import MessageBox from '../common/messageBox';

/**
 * ManagerAccount
 * @extends Component
 */
class ManagerAccount extends Component {
  /**
   * Constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    // Binding goToChangePasswordPage
    this.goToChangePasswordPage = this.goToChangePasswordPage.bind(this);
  }
  /**
   * goToChangePasswordPage
   */
  goToChangePasswordPage() {
    // Push URL changePassword to history
    this.props.history.push('/changePassword');
  }
  /**
   * Render
   * @return {Element} React Element
   */
  render() {
    return (
      <div className={`${style['main-container']}`}>
        <MessageBox />
        <div className={`${style['account-manager']} ${style['main-container-title']}`}>Account Management</div>
        <div className={style['main-container-wrapper']}>
          <div className={style['account-info']}>
            <p>
              Username <span className={style['current-email']}>{this.props.userDetail && this.props.userDetail.username}</span>
            </p>
            <p>
              Password
              <span
                className={style['current-password']}
              >
                &#x25CF;&#x25CF;&#x25CF;&#x25CF;&#x25CF;&#x25CF;&#x25CF;
                &#x25CF;&#x25CF;&#x25CF;&#x25CF;&#x25CF;&#x25CF;&#x25CF;
                &#x25CF;&#x25CF;&#x25CF;&#x25CF;&#x25CF;&#x25CF;&#x25CF;
              </span>
            </p>
            <a
              id="changePasswordLink"
              className={style['change-pwd-link']}
              onClick={this.goToChangePasswordPage}
              role="button"
              tabIndex={0}
            >
              {'Change password > '}
            </a>
          </div>
        </div>
      </div>
    );
  }
}
// mapStateToProps
const mapStateToProps = state => ({
  // User Detail
  userDetail: state.LoginReducer.get('userDetail'),
});
// Props types of ManagerAccount
ManagerAccount.propTypes = {
  // History
  history: PropTypes.shape({
    // Go back
    goBack: PropTypes.func.isRequired,
    // Push
    push: PropTypes.func.isRequired,
    // Location
    location: PropTypes.shape({
      // pathname
      pathname: PropTypes.string,
    }),
  }).isRequired,
  // User Detail
  userDetail: PropTypes.shape({
    // username
    username: PropTypes.string,
  }).isRequired,
};
// Export default connect
export default connect(
  mapStateToProps,
  null,
)(ManagerAccount);
// Export ManagerAccountClass
export const ManagerAccountClass = ManagerAccount;
