import uuid from 'uuid';
import _ from 'lodash';
import {
  SHOW_MESSAGE,
  CLEAR_MESSAGE,
  CLEAR_MESSAGE_BOX,
  CLEAR_ALL_MESSAGE_BOX,
} from './events';
import { DEFAULT_OPTIONS } from './constants';

/**
 * Return show message event action
 * @param {Object} payload include message object and message box's id
 * @returns {{type: String, payload: Object}}
 */
export const showMessageEvent = payload => ({
  type: SHOW_MESSAGE,
  payload,
});

/**
 * Push a message to messages array of message box
 * @param {String} id message box's id
 * @param {Object} message message added to array
 * @param {String} messageType
 * @param {Object} messageOptions
 */
export const showMessage = (id, text, type, options = {}) => dispatch => (
  dispatch(showMessageEvent({ id,
    message: {
      id: uuid.v4(),
      text,
      duration: options.duration || DEFAULT_OPTIONS.duration,
      clearOtherMessages: !_.isNil(options.clearOtherMessages)
        ? options.clearOtherMessages : DEFAULT_OPTIONS.clearOtherMessages,
      type,
    } }))
);

/**
 * Push a success message to messages array of message box
 * @param {String} id message box's id
 * @param {String} text message text
 * @param {Object} messageOptions
 */
export const showSuccessMessage = (id, text, options) =>
  dispatch => dispatch(showMessage(id, text, 'success', options));

/**
 * Push a error message to messages array of message box
 * @param {String} id message box's id
 * @param {String} text message text
 * @param {Object} messageOptions
 */
export const showErrorMessage = (id, text, options) =>
  dispatch => dispatch(showMessage(id, text, 'error', options));

/**
 * Return clear message event
 * @param {Object} payload include message's id and message box's id
 * @returns {{type: String, payload: Object}}
 */
export const clearMessageEvent = payload => ({
  type: CLEAR_MESSAGE,
  payload,
});

/**
 * Hide a message from message box
 * @param {String} id message box's id
 * @param {String} messageId message's id
 * @returns {Function}
 */
export const clearMessage = (id, messageId) => (dispatch) => {
  dispatch(clearMessageEvent({ id, messageId }));
};

/**
 * Return clear message box event
 * @param {Object} payload include message box's id
 * @returns {{type: String, payload: *}}
 */
export const clearMessageBoxEvent = payload => ({
  type: CLEAR_MESSAGE_BOX,
  payload,
});

/**
 * Remove messages array of a message box from reducer
 * @param {String} id message box's id
 */
export const clearMessageBox = id => (dispatch) => {
  dispatch(clearMessageBoxEvent(id));
};

/**
 * Return clear message all box event
 * @param {Object} payload include message box's id
 * @returns {{type: String, payload: *}}
 */
export const clearAllMessageBoxEvent = () => ({
  type: CLEAR_ALL_MESSAGE_BOX,
});

/**
 * Remove messages array of all message box from reducer
 * @param {String} id message box's id
 */
export const clearAllMessageBox = () => (dispatch) => {
  dispatch(clearAllMessageBoxEvent());
};

