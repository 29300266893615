// LOGIN_START
export const LOGIN_START = 'LOGIN_START';
// LOGIN_SUCCESS
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
// LOGIN_FAILURE
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

// CHECK_LOGIN
export const CHECK_LOGIN_START = 'CHECK_LOGIN_START';
export const CHECK_LOGIN_SUCCESS = 'CHECK_LOGIN_SUCCESS';
export const CHECK_LOGIN_FAILURE = 'CHECK_LOGIN_FAILURE';

// GET_KEYCLOAK_URL
export const GET_KEYCLOAK_URL_START = 'GET_KEYCLOAK_URL_START';
export const GET_KEYCLOAK_URL_SUCCESS = 'GET_KEYCLOAK_URL_SUCCESS';
export const GET_KEYCLOAK_URL_FAILURE = 'GET_KEYCLOAK_URL_FAILURE';

// SET_USER_DETAIL
export const USER_DETAIL = 'USER_DETAIL';

// SET_USER_DETAIL
export const GET_USER_DETAIL_START = 'GET_USER_DETAIL_START';
export const GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS';
export const GET_USER_DETAIL_FAILURE = 'GET_USER_DETAIL_FAILURE';

