/* eslint-disable react/jsx-no-target-blank */
import React, { Component, Fragment } from 'react';
import { Map as MapImmutable } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Tippy from '@tippyjs/react';
import { Nav, NavItem } from 'react-bootstrap';
import _ from 'lodash';
import * as style from './styles.scss';
import i18n from '../../../../i18n';
import getRouterKey from '../common/utils';
import * as userActions from '../../actions/userActions';
import { featureWiseRolesHierarchy, MINIMUM_RESOLUTION } from '../common/constants';

/**
 * NavBar
 * @extends Component
 */
class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: window.screen.width > MINIMUM_RESOLUTION,
      isPreviewOpen: false,
      isMainBodyHovering: false,
      isNavToggleHovering: false,
    };
    this.extractPermission = this.extractPermission.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleNavToggle = this.handleNavToggle.bind(this);
    this.handleNavPreviewOpen = this.handleNavPreviewOpen.bind(this);
    this.handleNavPreviewClose = this.handleNavPreviewClose.bind(this);
    this.handleMainBodyEnter = this.handleMainBodyEnter.bind(this);
    this.handleMainBodyLeave = this.handleMainBodyLeave.bind(this);
    this.handleNavToggleEnter = this.handleNavToggleEnter.bind(this);
    this.handleNavToggleLeave = this.handleNavToggleLeave.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleLowerResolution = this.handleLowerResolution.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    if (window.screen.width > MINIMUM_RESOLUTION) {
      document.body.classList.add('nav-expanded');
    } else {
      this.handleLowerResolution();
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    document.body.classList.remove('nav-expanded');
    document.body.classList.remove('nav-preview-expanded');
  }
  /**
   * Get NavItems based on the role that was selected
   * @param {String} activeKey The current active key
   * @return {Element} HTML element
   */
  getNavItems(activeKey) {
    const labelStyle = {
      opacity: this.state.isPreviewOpen || this.state.isOpen ? 1 : 0,
    };
    let items = [];
    // push all public module
    this.props.modules.entrySeq().forEach((item) => {
      Object.keys(item[1].routes.pmiRoutes).forEach((key) => {
        const router = item[1].routes.pmiRoutes[key];
        // Check if permissions
        if (!item[1].routes.pmiRoutes[key].hidden &&
          (!router.permissions || router.permissions.length === 0)) {
          // Then push WrapperView component
          const pageName = item[1].routes.pmiRoutes[key].label;
          const path = item[1].routes.pmiRoutes[key].path;
          const iconName = item[1].routes.pmiRoutes[key].icon;
          const index = item[1].routes.pmiRoutes[key].index;
          items.push({ id: pageName, href: path, icon: iconName, label: pageName, index });
        }
      });
    });
    const { userDetail, currentActiveProgram, lmsStatus, lmsPortalUrl, partnerCmsStatus,
      partnerCmsUrl, isPMI, roleSelected } = this.props;
    if (userDetail && currentActiveProgram) {
      items = [];
      const userPermissions = !isPMI ?
        _.map(_.filter(currentActiveProgram.permissions, p =>
          (p.action === 'view')), p => p.resource) :
        _.map(_.filter(currentActiveProgram.permissions, p =>
          (p.action === 'view' && p.roleName === roleSelected.name)), p => p.resource);
      const routerKey = getRouterKey(userDetail);
      this.props.modules.entrySeq().forEach((item) => {
        Object.keys(item[1].routes[routerKey]).forEach((key) => {
          const router = item[1].routes[routerKey][key];
          let havePermission = false;

          if (router.permissions && router.permissions.length > 0) {
            const permissionIntersection = _.intersection(router.permissions, userPermissions);
            havePermission = permissionIntersection.length > 0;
          } else {
            havePermission = true;
          }
          if (!item[1].routes[routerKey][key].hidden && havePermission) {
            const pageName = item[1].routes[routerKey][key].label;
            const path = item[1].routes[routerKey][key].path;
            const iconName = item[1].routes[routerKey][key].icon;
            const index = item[1].routes[routerKey][key].index;
            const svg = item[1].routes[routerKey][key].svg;
            const rolePermissions = item[1].routes[routerKey][key].permissions;
            items.push({ id: pageName,
              href: path,
              icon: iconName,
              svg,
              label: pageName,
              index,
              rolePermissions });
          }
        });
      });
    }
    if (!isPMI) {
      if (lmsStatus) {
        items.push({
          id: 'lms',
          href: lmsPortalUrl,
          icon: 'fa-kit fa-11-training-academy',
          svg: undefined,
          label: 'Training Academy',
          index: 10,
          type: 'outLink',
          rolePermissions: '',
        });
      }
      if (partnerCmsStatus) {
        items.push({
          id: 'partnerCMS',
          href: partnerCmsUrl,
          icon: 'fa-kit fa-12-community-engagement-builder',
          svg: undefined,
          label: 'Community Engagement Builder',
          index: 11,
          type: 'outLink',
          rolePermissions: '',
        });
      }
      items.push({
        id: 'Protocol Builder',
        href: '/protocolBuilder',
        icon: 'fa-kit fa-16-pb',
        svg: undefined,
        label: 'Protocol Builder',
        index: 15,
        rolePermissions: '',
      });
    }
    if (items.length === 0) {
      return null;
    }

    const navItems = _.orderBy(items, 'index').map((item) => {
      if (_.isNil(item.type)) {
        return (
          <NavItem
            id={`icon-${item.label.match(/[^\s/]+/)}`}
            key={`icon-${item.label.match(/[^\s/]+/)}`}
            href={item.href}
            eventKey={[item.href, item.rolePermissions, item.label]}
            active={item.href === activeKey}
            className={`${item.index > 0 ? '' : 'hidden'} ${item.svg ? 'svg-wrapper' : ''}`}
          >
            {item.icon && <div className={style['icon-wrapper']}>
              <i className={`${item.icon} ${style['navbar-icon']}`} />
            </div>}
            {item.svg && <div className={style['icon-wrapper']}>{item.svg}</div>}
            <span className={style['nav-label']} style={labelStyle}>
              {i18n.t(item.label)}
            </span>
          </NavItem>
        );
      }
      return (
        <li id={`icon-${item.label.match(/[^\s/]+/)}`} className={`${item.index > 0 ? '' : 'hidden'} ${item.svg ? 'svg-wrapper' : ''}`}>
          <a target="_blank" href={item.href}>
            {item.icon && <div className={style['icon-wrapper']}><i className={`${item.icon} ${style['navbar-icon']}`} /></div>}
            <span className={style['nav-label']} style={labelStyle}>
              {i18n.t(item.label)}
            </span>
          </a>
        </li>
      );
    });
    if (!isPMI) {
      const hosIndex = navItems.findIndex(item => item.props.href === '/hierarchy');
      const hrPosition = hosIndex === -1 ? navItems.findIndex(item =>
        item.props.href === '/protocolBuilder') : hosIndex;
      const hrTag = <hr width={'100%'} />;
      navItems.splice(hrPosition, 0, hrTag);
    }
    return navItems;
  }

  handleLowerResolution() {
    this.setState({ isOpen: false });
    document.body.classList.remove('nav-expanded');
    document.body.classList.remove('nav-preview-expanded');
    this.props.actions.showResolutionToast();
  }

  handleResize() {
    if (window.screen.width > MINIMUM_RESOLUTION) {
      this.setState({ isOpen: true });
      document.body.classList.add('nav-expanded');
      document.body.classList.add('nav-preview-expanded');
    } else {
      this.handleLowerResolution();
    }
  }
  extractPermission(paths) {
    for (let i = 0; i < paths.length; i += 1) {
      const viewPermissions = _.map(_.filter(this.props.currentActiveProgram.permissions, p =>
        (p.action === 'view' && p.resource === paths[i])), p => p.roleName);
      if (!_.isEmpty(viewPermissions)) {
        return viewPermissions;
      }
    }
    return [];
  }

  /**
   * handleSelect
   * @param {String} path
   */
  handleSelect(path) {
    if (this.props.isPMI) {
      this.props.history.push(path[0]);
    } else {
      const permission = this.extractPermission(path[1]);
      permission.sort((a, b) => featureWiseRolesHierarchy[path[2]].indexOf(a) -
      featureWiseRolesHierarchy[path[2]].indexOf(b));
      const newRole = _.find(this.props.currentActiveProgram.roles, r => permission[0] === r.name);
      this.props.actions.setUserRole(newRole === undefined ?
        this.props.currentActiveProgram.roles[0] : newRole);
      this.props.history.push(path[0]);
    }
  }

  handleNavPreviewOpen() {
    if (!this.state.isOpen) {
      this.setState({ isPreviewOpen: true });
      document.body.classList.add('nav-preview-expanded');
    }
  }

  handleNavPreviewClose() {
    this.setState({ isPreviewOpen: false });
    document.body.classList.remove('nav-preview-expanded');
  }

  handleMainBodyEnter() {
    this.setState({ isMainBodyHovering: true });
    this.handleNavPreviewOpen();
  }

  handleMainBodyLeave() {
    this.setState({ isMainBodyHovering: false });
    setTimeout(() => {
      if (!this.state.isNavToggleHovering) {
        this.handleNavPreviewClose();
      }
    });
  }

  handleNavToggleEnter() {
    this.setState({ isNavToggleHovering: true });
  }

  handleNavToggleLeave() {
    setTimeout(() => {
      this.setState({ isNavToggleHovering: false });
      if (!this.state.isMainBodyHovering) {
        this.handleNavPreviewClose();
      }
    });
  }

  handleNavToggle() {
    this.setState({
      isOpen: !this.state.isOpen,
      isPreviewOpen: false,
    });
    document.body.classList.remove('nav-preview-expanded');
    if (this.state.isOpen) {
      document.body.classList.remove('nav-expanded');
    } else {
      document.body.classList.add('nav-expanded');
    }
  }

  /**
   * render
   * @return {Element} React element
   */
  render() {
    const activeKey = `/${this.props.history.location.pathname.split('/')[1]}`;
    const navItems = this.getNavItems(activeKey);
    const tooltipKey = this.state.isOpen ? 'collapse' : 'expand';
    return (
      <Fragment>
        <Tippy
          content={
            <span
              className={style['nav-tooltip']}
            >
              {i18n.t(tooltipKey)}
              <i className={style.arrow} />
            </span>
          }
          distance={0}
          duration={0}
          placement="right"
        >
          {window.screen.width > MINIMUM_RESOLUTION ? <div
            id="navbar-toggle"
            role="presentation"
            className={style['navbar-toggle']}
            onClick={this.handleNavToggle}
            onMouseEnter={this.handleNavToggleEnter}
            onMouseLeave={this.handleNavToggleLeave}
          >
            <i
              className="fa-solid fa-chevron-left"
            />
          </div> : null}
        </Tippy>
        <div
          id="leftMenu"
          className={style.leftMenu}
        >
          <nav
            className={style.sidebar}
            onMouseEnter={this.handleMainBodyEnter}
            onMouseLeave={this.handleMainBodyLeave}
          >
            <Nav
              className={style['navbar-nav']}
              activeKey={activeKey}
              onSelect={this.handleSelect}
              style={{ paddingTop: window.screen.width <= MINIMUM_RESOLUTION && '0' }}
            >
              {navItems}
            </Nav>
          </nav>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  currentActiveProgram: state.UserDetail.get('currentActiveProgram'),
  roleSelected: state.UserDetail.get('roleSelected'),
  modules: state.modules,
  userDetail: state.UserDetail.get('userDetail'),
  lmsStatus: state.UserDetail.get('lmsStatus'),
  lmsPortalUrl: state.UserDetail.get('lmsPortalUrl'),
  partnerCmsStatus: state.UserDetail.get('partnerCmsStatus'),
  partnerCmsUrl: state.UserDetail.get('partnerCmsUrl'),
  collapseNavbar: state.UserDetail.get('collapseNavbar'),
  isPMI: state.UserDetail.get('isPMI'),
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, userActions), dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavBar);
NavBar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  modules: PropTypes.shape({
    entrySeq: PropTypes.func.isRequired,
  }).isRequired,
  currentActiveProgram: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.shape()),
    roles: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  actions: PropTypes.shape({
    setUserRole: PropTypes.func.isRequired,
    showResolutionToast: PropTypes.func.isRequired,
  }).isRequired,
  roleSelected: PropTypes.shape({
    displayName: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  userDetail: PropTypes.shape({
    pmiplatform: PropTypes.bool,
  }).isRequired,
  lmsStatus: PropTypes.bool.isRequired,
  lmsPortalUrl: PropTypes.string.isRequired,
  partnerCmsStatus: PropTypes.bool.isRequired,
  partnerCmsUrl: PropTypes.string.isRequired,
  isPMI: PropTypes.bool.isRequired,
};
NavBar.defaultProps = {
  modules: new MapImmutable({}),
  actions: {},
  roleSelected: {},
};
export const NavBarComponent = NavBar;
