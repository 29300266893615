import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Waypoint } from 'react-waypoint';
import { Spinner } from '@vibrent/electryon';
import ScrollBars from '../../../scrollbars';
import Section from '../section';
import styles from './style.scss';
import {
  STATUS,
  TYPE_SECTION,
} from '../../../../constants';

const HEIGHT_OF_NOTIFICATION_RECORD = 100;

class StickyScroll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStickyTop: false,
      isStickyBottom: true,
      disabledLoadingRecent: false,
      showEarlier: props.recentNotifications.length === props.totalRecent,
    };
    this.autoScrollRecent = this.autoScrollRecent.bind(this);
    this.autoScrollEarlier = this.autoScrollEarlier.bind(this);

    this.onTopEnterWayPoint = this.onTopEnterWayPoint.bind(this);
    this.onBottomEnterWayPoint = this.onBottomEnterWayPoint.bind(this);
    this.onTopLeaveWayPoint = this.onTopLeaveWayPoint.bind(this);
    this.onBottomLeaveWayPoint = this.onBottomLeaveWayPoint.bind(this);
    this.onEnableLoadingRecent = this.onEnableLoadingRecent.bind(this);
    this.loadMoreData = this.loadMoreData.bind(this);

    this.containerRefRecent = React.createRef();
    this.scrollBars = React.createRef();
    this.setShowEarlier = this.setShowEarlier.bind(this);
    this.setIsStickyTop = this.setIsStickyTop.bind(this);
  }
  componentDidUpdate() {
    if (this.props.recentNotifications.length === this.props.totalRecent) {
      this.setShowEarlier();
    }
    // catch - reload data after save notification
    // case: have just one record earlier
    if (this.props.totalEarlier === 0) {
      this.setIsStickyTop();
    }
  }

  /**
   * When user scroll down and the TopWayPoint enter the display view the hide the Top Sticky
   * @param  {Object} currentPoint the current point
   */
  onTopEnterWayPoint(currentPoint) {
    const { currentPosition, previousPosition } = currentPoint;
    if (this.props.totalRecent > 0 && currentPosition === Waypoint.inside &&
      previousPosition === Waypoint.above) {
      this.setState({
        // Up - enable loading recent section (2.1)
        disabledLoadingRecent: false,
        isStickyTop: false,
        // showEarlier: this.props.recentNotifications.length === this.props.totalRecent,
      });
    }
  }
  /**
   * When user scroll up and the top WayPoint leave the display view the show the Top Sticky
   * @param  {Object} currentPoint the current point
   */
  onTopLeaveWayPoint(currentPoint) {
    const { currentPosition, previousPosition } = currentPoint;
    if (this.props.totalRecent > 0 && currentPosition === Waypoint.above &&
      previousPosition === Waypoint.inside) {
      this.setState({
        isStickyTop: true,
      });
    }
  }
  /**
   * When user scroll down and the bottom WayPoint enter the display view the hide the Bottom Sticky
   * @param  {Object} currentPoint the current point
   */
  onBottomEnterWayPoint(currentPoint) {
    const { currentPosition, previousPosition } = currentPoint;
    // Down
    if (currentPosition === Waypoint.inside && previousPosition === Waypoint.below) {
      this.setState({
        isStickyBottom: false,
      });
    }
  }
  /**
   * When user scroll up and the bottom WayPoint leave the display view the show the Bottom Sticky
   * @param  {Object} currentPoint the current point
   */
  onBottomLeaveWayPoint(currentPoint) {
    const { currentPosition, previousPosition } = currentPoint;
    // Up
    if (currentPosition === Waypoint.below && previousPosition === Waypoint.inside) {
      this.setState({
        isStickyBottom: true,
      });
    }
  }

  onEnableLoadingRecent(currentPoint) {
    const { currentPosition, previousPosition } = currentPoint;
    // Down
    if (!this.state.showEarlier &&
      currentPosition === Waypoint.above && previousPosition === Waypoint.inside) {
      this.setState({
        disabledLoadingRecent: false,
      });
    }
  }

  setIsStickyTop() {
    if (this.state.isStickyTop) {
      this.setState({
        isStickyTop: false,
      });
    }
  }

  setShowEarlier() {
    if (!this.state.showEarlier) {
      this.setState({ showEarlier: true });
    }
  }

  loadMoreData(type) {
    const { recentNotifications, earlierNotifications, typeNotification } = this.props;
    let startTime = 0;
    let endTime = 0;
    // get data for recent section
    if (type === TYPE_SECTION.RECENT && !this.state.disabledLoadingRecent) {
      startTime = moment().subtract(1, 'd').valueOf();
      endTime = moment().valueOf();

      this.props.getNotification(
        typeNotification,
        TYPE_SECTION.RECENT,
        startTime,
        endTime,
        recentNotifications.length);
    // get data for earlier section
    } else if (type === TYPE_SECTION.EARLIER) {
      endTime = moment().subtract(1, 'd').valueOf();

      this.props.getNotification(
        typeNotification,
        TYPE_SECTION.EARLIER,
        startTime,
        endTime,
        earlierNotifications.length);
    }
  }
  /**
   * handle auto scroll to Recent section
   */
  autoScrollRecent() {
    const { recentNotifications, totalRecent } = this.props;
    if (recentNotifications.length < totalRecent) {
      this.setState({
        isStickyTop: false,
        isStickyBottom: true,
        disabledLoadingRecent: true,
        showEarlier: false,
      });
      this.containerRefRecent.current.scrollIntoView({ block: 'start', behavior: 'auto' });
    } else {
      this.containerRefRecent.current.scrollIntoView({ block: 'start', behavior: 'auto' });
    }
  }
  /**
   * handle auto scroll to Earlier section
   */
  autoScrollEarlier() {
    const { recentNotifications, totalRecent, totalEarlier } = this.props;
    //  20 is the height of waypoint lazy loading
    const pos = recentNotifications.length === totalRecent ?
      recentNotifications.length * HEIGHT_OF_NOTIFICATION_RECORD :
      (recentNotifications.length * HEIGHT_OF_NOTIFICATION_RECORD) + 20;
    if (this.state.showEarlier) {
      this.scrollBars.current.scrollTop(pos + 1);
      this.setState({
        isStickyTop: totalEarlier > 0,
        isStickyBottom: false,
      });
    } else {
      this.setState({
        showEarlier: true,
        disabledLoadingRecent: true,
        isStickyTop: totalEarlier > 0,
        isStickyBottom: false,
      });
      setTimeout(() => {
        this.scrollBars.current.scrollTop(pos + 1);
      }, 300);
    }
  }

  renderTopHeader() {
    const {
      typeNotification,
      topHeaderTitle,
      totalRecent,
      totalEarlier,
      stickyBottomTitle,
    } = this.props;
    const isZeroRecord = totalRecent === 0;
    const title = typeNotification === STATUS.READ || isZeroRecord ?
      topHeaderTitle :
      `${topHeaderTitle} (${totalRecent})`;

    if (isZeroRecord) {
      return (
        <Fragment>
          <div className="top-header disabled-header">
            {title}
          </div>
          {totalEarlier > 0 ? this.renderStickyTopHeader() : null}
          {totalEarlier === 0 ?
            (
              <div className="sticky-header-bottom disabled-header">
                {stickyBottomTitle}
              </div>
            )
            : null}
        </Fragment>
      );
    }
    return (
      <div
        id="top-header"
        className="top-header"
        role="presentation"
        onClick={this.autoScrollRecent}
      >
        {title}
      </div>
    );
  }
  renderStickyTopHeader() {
    const {
      typeNotification,
      stickyTopTitle,
      totalEarlier,
    } = this.props;
    const title = typeNotification === STATUS.READ ?
      stickyTopTitle :
      `${stickyTopTitle} (${totalEarlier})`;
    return (
      <div className="sticky-top-header" >
        {title}
      </div>
    );
  }
  renderStickyBottomHeader() {
    const {
      typeNotification,
      totalEarlier,
      stickyBottomTitle,
      totalRecent,
    } = this.props;
    const isZeroRecord = totalEarlier === 0;

    if (isZeroRecord && totalRecent > 0) {
      return (
        <div className="sticky-header-bottom disabled-header">
          {stickyBottomTitle}
        </div>
      );
    }

    if (this.state.isStickyBottom && totalRecent > 4) {
      const title = typeNotification === STATUS.READ || isZeroRecord ?
        stickyBottomTitle :
        `${stickyBottomTitle} (${totalEarlier})`;
      return (
        <div
          id="sticky-header-bottom"
          className="sticky-header-bottom"
          role="presentation"
          onClick={this.autoScrollEarlier}
        >
          {title}
        </div>
      );
    }
    return '';
  }

  renderWayPoint() {
    const {
      typeNotification,
      wayPointTitle,
      totalEarlier,
      recentNotifications,
    } = this.props;
    const title = typeNotification === STATUS.READ ?
      wayPointTitle :
      `${wayPointTitle} (${totalEarlier})`;
    return (
      <Fragment>
        <Waypoint
          id="top-waypoint"
          onEnter={currentPoint => this.onTopEnterWayPoint(currentPoint)}
          onLeave={currentPoint => this.onTopLeaveWayPoint(currentPoint)}
        />
        <div
          className="way-point-item"
          role="presentation"
          onClick={() => {
            this.scrollBars.current.scrollTop(
              (recentNotifications.length * HEIGHT_OF_NOTIFICATION_RECORD) + 2);
          }}
        >
          {title}
        </div>
        <Waypoint
          id="bottom-waypoint"
          onEnter={currentPoint => this.onBottomEnterWayPoint(currentPoint)}
          onLeave={currentPoint => this.onBottomLeaveWayPoint(currentPoint)}
        />
      </Fragment>
    );
  }

  renderEarlierNotification() {
    const {
      typeNotification,
      totalEarlier,
      earlierRequesting,
      earlierNotifications,
      internationalPhoneSupportEnabled,
      t,
    } = this.props;
    return (
      <Section
        name={TYPE_SECTION.EARLIER}
        typeNotification={typeNotification}
        totalRecord={totalEarlier}
        isRequesting={earlierRequesting}
        notifications={earlierNotifications}
        onSelectNotification={this.props.onSelectNotification}
        loadMoreData={this.loadMoreData}
        internationalPhoneSupportEnabled={internationalPhoneSupportEnabled}
        t={t}
      />
    );
  }

  renderContentBottom() {
    const {
      typeNotification,
      totalRecent,
      totalEarlier,
      recentRequesting,
      earlierRequesting,
      t,
    } = this.props;

    if (this.state.showEarlier) {
      if (totalRecent === 0 && totalEarlier === 0) {
        if (!recentRequesting && !earlierRequesting) {
          return (
            <div className="text-center">
              <div className="like-icon" >
                <i className="fa fa-thumbs-o-up" aria-hidden="true" />
              </div>
              <div className="message-none-data"><span>{t('You’re all caught up!')}</span></div>
            </div>
          );
        }
      }
      const marginContentBottom = totalRecent !== 0 && totalEarlier === 0 ? 'm-t-50' : '';
      const minHeight = totalEarlier !== 0 ? 'earlier-height' : '';
      return (
        <Fragment>
          {(totalRecent > 0 && totalEarlier > 0) && this.renderWayPoint()}
          <div
            key={`${typeNotification}-earlier`}
            className={`${typeNotification} earlier ${marginContentBottom} ${minHeight}`}
          >
            {this.renderEarlierNotification()}
          </div>
        </Fragment>
      );
    }
    return null;
  }

  renderContentTop() {
    const {
      typeNotification,
      totalRecent,
      recentRequesting,
      recentNotifications,
      internationalPhoneSupportEnabled,
      t,
    } = this.props;

    return (
      <div
        key={`${typeNotification}-recent`}
        className={`${typeNotification} recent`}
        ref={this.containerRefRecent}
      >
        <Waypoint
          id="enable-loading-recent"
          onLeave={currentPoint => this.onEnableLoadingRecent(currentPoint)}
        />
        <Section
          name={TYPE_SECTION.RECENT}
          typeNotification={typeNotification}
          totalRecord={totalRecent}
          isRequesting={recentRequesting}
          notifications={recentNotifications}
          onSelectNotification={this.props.onSelectNotification}
          loadMoreData={this.loadMoreData}
          internationalPhoneSupportEnabled={internationalPhoneSupportEnabled}
          t={t}
        />
      </div>
    );
  }

  render() {
    const {
      totalRecent,
      totalEarlier,
      recentRequesting,
      earlierRequesting,
    } = this.props;

    const stickyScrollClassName =
    (totalRecent === 0 && totalEarlier === 0) ?
      'no-data' : styles['sticky-scroll'];
      // margin content top 50px when the earlier header is sticky top
    const marginContentTop = totalRecent === 0 && totalEarlier !== 0 ? 'm-t-50' : '';

    // reload data when changing tab - show spinner
    if (totalRecent === 0 && totalEarlier === 0 && recentRequesting && earlierRequesting) {
      return (
        <div className="wapper-spinner">
          <Spinner radius={40} />
        </div>
      );
    }

    return (
      <div className={stickyScrollClassName}>
        <div className={styles['top-header-container']}>
          {this.renderTopHeader()}
        </div>
        { this.state.isStickyTop ? this.renderStickyTopHeader() : null}
        <ScrollBars
          className={styles['notification-content-container']}
          autoScrollOnResize
          ref={this.scrollBars}
        >
          <div className={`${marginContentTop}`}>
            {this.renderContentTop()}
          </div>
          {this.renderContentBottom()}
        </ScrollBars>
        {this.renderStickyBottomHeader()}
      </div>
    );
  }
}
StickyScroll.propTypes = {
  getNotification: PropTypes.func.isRequired,
  typeNotification: PropTypes.string.isRequired,
  topHeaderTitle: PropTypes.string.isRequired,
  stickyTopTitle: PropTypes.string.isRequired,
  stickyBottomTitle: PropTypes.string.isRequired,
  wayPointTitle: PropTypes.string.isRequired,
  // notification data
  recentNotifications: PropTypes.arrayOf(PropTypes.string).isRequired,
  recentRequesting: PropTypes.bool,
  earlierNotifications: PropTypes.arrayOf(PropTypes.string).isRequired,
  earlierRequesting: PropTypes.bool,
  totalRecent: PropTypes.number,
  totalEarlier: PropTypes.number,
  onSelectNotification: PropTypes.func.isRequired,
  internationalPhoneSupportEnabled: PropTypes.bool,
  t: PropTypes.func,
};
StickyScroll.defaultProps = {
  recentNotifications: [],
  earlierNotifications: [],
  recentRequesting: false,
  earlierRequesting: false,
  totalRecent: 0,
  totalEarlier: 0,
  internationalPhoneSupportEnabled: false,
  t: x => x,
};
export default StickyScroll;
