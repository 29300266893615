// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-coreModules-authentication-components-notificationsModal-components-scrollbars-style__scrollbars-wrapper--2xSWrCeL .scrollbars-track-vertical,.src-coreModules-authentication-components-notificationsModal-components-scrollbars-style__scrollbars-wrapper--2xSWrCeL .scrollbars-track-horizontal{position:absolute;border-radius:3px;z-index:10}.src-coreModules-authentication-components-notificationsModal-components-scrollbars-style__scrollbars-wrapper--2xSWrCeL .scrollbars-track-vertical{width:6px;right:2px;bottom:2px;top:2px}.src-coreModules-authentication-components-notificationsModal-components-scrollbars-style__scrollbars-wrapper--2xSWrCeL .scrollbars-track-horizontal{height:6px;right:2px;bottom:2px;left:2px}", ""]);
// Exports
exports.locals = {
	"scrollbars-wrapper": "src-coreModules-authentication-components-notificationsModal-components-scrollbars-style__scrollbars-wrapper--2xSWrCeL"
};
module.exports = exports;
