// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-coreModules-authentication-components-notificationsModal-components-notifications-components-styles__none-notification---MELRblB{height:204px;color:#b9b9b9}.src-coreModules-authentication-components-notificationsModal-components-notifications-components-styles__none-notification---MELRblB .like-icon{font-size:30px;padding-top:20px}.src-coreModules-authentication-components-notificationsModal-components-notifications-components-styles__none-notification---MELRblB .message-none-data{font-weight:600;font-style:italic;padding-top:5px}.src-coreModules-authentication-components-notificationsModal-components-notifications-components-styles__unread-notification--3IMEE1Po{height:500px;overflow:hidden}.src-coreModules-authentication-components-notificationsModal-components-notifications-components-styles__read-notification--2PsDUsGx{height:500px;overflow:hidden}.src-coreModules-authentication-components-notificationsModal-components-notifications-components-styles__read-notification--2PsDUsGx li .content{display:inline-flex}.src-coreModules-authentication-components-notificationsModal-components-notifications-components-styles__read-notification--2PsDUsGx li .content .icon{color:#707070}.src-coreModules-authentication-components-notificationsModal-components-notifications-components-styles__read-notification--2PsDUsGx li .content .message-text{color:#707070}.src-coreModules-authentication-components-notificationsModal-components-notifications-components-styles__read-notification--2PsDUsGx .dropdown .dropdown-toggle{color:#707070}.src-coreModules-authentication-components-notificationsModal-components-notifications-components-styles__read-notification--2PsDUsGx .dropdown .dropdown-toggle:focus,.src-coreModules-authentication-components-notificationsModal-components-notifications-components-styles__read-notification--2PsDUsGx .dropdown .dropdown-toggle:hover{color:#707070}", ""]);
// Exports
exports.locals = {
	"none-notification": "src-coreModules-authentication-components-notificationsModal-components-notifications-components-styles__none-notification---MELRblB",
	"unread-notification": "src-coreModules-authentication-components-notificationsModal-components-notifications-components-styles__unread-notification--3IMEE1Po",
	"read-notification": "src-coreModules-authentication-components-notificationsModal-components-notifications-components-styles__read-notification--2PsDUsGx"
};
module.exports = exports;
