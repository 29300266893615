import { RequestUtil } from 'pmt-common';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';

export const REQUEST_METHOD = {
  GET: 'GET',
  POST: 'POST',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};

/**
 * A request wrapper that allow us to cancel a request
 */
class AbortableRequest {
  /**
   * Constructor
   */
  constructor() {
    this.abortController = new window.AbortController();

    // These will be created later. That use for abort process
    this.responseReject = null;
  }

  /**
   * Create wrapper for real response
   * @param  {Promise} realResponse The real response
   * @return {Promise}
   */
  doResponse(realResponse) {
    return new Promise((resolve, reject) => {
      // When the AbortableRequest.abort() is called. We use this function to abort the response.
      this.responseReject = reject;
      realResponse
        .then(resolve)
        .catch(reject);
    });
  }

  /**
   * Abort a request
   */
  abort() {
    this.responseReject({ isAborted: true });
    this.abortController.abort();
  }

  /**
   * Request
   * @param  {Object} params
   * @return {Promise}
   */
  request(params) {
    const { url, method, data } = params;
    const options = {
      ...params.options,
      signal: this.abortController.signal,
    };

    let response;
    switch (method) {
    case REQUEST_METHOD.GET:
      response = RequestUtil.getData(url, options);
      break;
    case REQUEST_METHOD.POST:
      response = RequestUtil.postData(url, data, options);
      break;
    case REQUEST_METHOD.UPDATE:
      response = RequestUtil.updateData(url, data, options);
      break;
    default:
      response = RequestUtil.deleteData(url, options);
      break;
    }

    return this.doResponse(response);
  }
}

// Export
export default AbortableRequest;
