import { Map as MapImmutable } from 'immutable';
import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  CHECK_LOGIN_START,
  CHECK_LOGIN_SUCCESS,
  CHECK_LOGIN_FAILURE,
  GET_KEYCLOAK_URL_START,
  GET_KEYCLOAK_URL_SUCCESS,
  GET_KEYCLOAK_URL_FAILURE,
  USER_DETAIL,
  GET_USER_DETAIL_FAILURE,
  GET_USER_DETAIL_START,
  GET_USER_DETAIL_SUCCESS,
} from './events';

const initialState = new MapImmutable({
  isCheckingSession: true,
  requesting: false,
  keyCloakConfig: '',
  allowedPrograms: [],
});

const actionHandlers = {};

actionHandlers[GET_KEYCLOAK_URL_START] = state => state
  .set('requesting', true)
  .set('isCheckingSession', true)
  .delete('keyCloakConfig')
  .delete('error')
  .delete('userDetail');

actionHandlers[GET_KEYCLOAK_URL_SUCCESS] = (state, action) => state
  .set('requesting', false)
  .set('isCheckingSession', false)
  .set('keyCloakConfig', action.response)
  .delete('error');

actionHandlers[USER_DETAIL] = (state, action) => state
  .set('requesting', false)
  .set('isCheckingSession', true)
  .set('userDetail', action.response)
  .delete('error');

actionHandlers[GET_KEYCLOAK_URL_FAILURE] = (state, action) => state
  .set('requesting', false)
  .set('isCheckingSession', false)
  .delete('keyCloakConfig')
  .set('error', action.error);

actionHandlers[GET_USER_DETAIL_START] = state => state
  .set('requesting', true)
  .set('isCheckingSession', true)
  .delete('error')
  .delete('setUserDetails')
  .delete('userDetail');

actionHandlers[GET_USER_DETAIL_SUCCESS] = state => state
  .set('requesting', false)
  .set('isCheckingSession', false)
  .set('setUserDetails', true)
  .delete('error');

actionHandlers[GET_USER_DETAIL_FAILURE] = (state, action) => state
  .set('requesting', false)
  .set('isCheckingSession', false)
  .delete('setUserDetails')
  .set('error', action.error);

actionHandlers[LOGIN_START] = state => state
  .set('requesting', true)
  .set('isCheckingSession', false)
  .delete('response')
  .delete('error')
  .delete('userDetail');

actionHandlers[LOGIN_SUCCESS] = (state, action) => state
  .set('requesting', false)
  .set('response', action.response)
  .delete('error');

actionHandlers[LOGIN_FAILURE] = (state, action) => state
  .set('requesting', false)
  .delete('response')
  .set('error', action.error);

actionHandlers[CHECK_LOGIN_START] = state => state
  .set('isCheckingSession', true)
  .delete('response')
  .delete('userDetail');

actionHandlers[CHECK_LOGIN_SUCCESS] = (state, action) => {
  const response = action.userDetail;
  const nextProps = state
    .set('isCheckingSession', false)
    .set('setUserDetails', true)
    .set('allowedPrograms', action.userDetail.assignProgramList)
    .set('isPMI', action.userDetail.pmiplatform);
  if (!response.code) {
    return nextProps.set('userDetail', action.userDetail)
      .set('isPMI', action.userDetail.pmiplatform);
  }
  return nextProps.set('response', action.userDetail)
    .set('isPMI', action.userDetail.pmiplatform);
};
actionHandlers[CHECK_LOGIN_FAILURE] = state => state
  .set('isCheckingSession', false);


export default (state = initialState, action) => {
  if (actionHandlers[action.type] !== undefined) {
    const nextState = actionHandlers[action.type](state, action);
    if (nextState === null || nextState === undefined) {
      return state;
    }
    return nextState;
  }
  return state;
};
