import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import LogoHolder from './common/logoHolder';
import getRouterKey from './common/utils';

class EmptyDashboard extends Component {
  componentDidMount() {
    this.navigateToNextPath(this.props);
  }

  componentDidUpdate() {
    this.navigateToNextPath(this.props);
  }

  navigateToNextPath(props) {
    // Check if numberModuleWillBeLoad is 0
    if (props.numberModuleWillBeLoad === 0) {
      const routes = [];
      const routerKey = getRouterKey(props.userDetail);
      const userPermissions =
        _.map(_.filter(this.props.currentActiveProgram.permissions, p =>
          (p.action === 'view' && p.roleName === this.props.roleSelected.name)), p => p.resource);
      props.modules.entrySeq().forEach((item) => {
        const moduleRoutes = item[1].routes[routerKey];
        if (_.isEmpty(item[1].routes[routerKey]) !== true) {
          _.forEach(moduleRoutes, (router) => {
            let havePermission = false;

            if (router.permissions && router.permissions.length > 0) {
              const permissionIntersection = _.intersection(router.permissions, userPermissions);
              havePermission = permissionIntersection.length > 0;
            } else {
              havePermission = true;
            }
            if (havePermission) {
              routes.push(router);
            }
          });
        }
      });
      // Sorting routes by index
      const orderedRoute = _.orderBy(_.filter(routes, r => r.index >= 0), 'index').concat(_.filter(routes, r => r.index < 0));
      let path = '';
      if (this.props.roleSelected.name === 'ROLE_MC_CATI_INTERVIEWER'
      || this.props.roleSelected.name === 'ROLE_MC_SUPPORT_ADMIN'
      || this.props.roleSelected.name === 'ROLE_MC_SUPPORT_STAFF') {
        path = '/noFeatureAccess';
      } else {
        path = '/notFound';
      }
      this.props.history.replace(orderedRoute[0] ? orderedRoute[0].path : path);
    }
  }

  render() {
    return (<div className="banner">
      <LogoHolder linkedLogo={false} />
    </div>);
  }
}

const mapStateToProps = state => ({
  modules: state.modules,
  numberModuleWillBeLoad: state.ModuleLoader.get('numberModuleWillBeLoad'),
  currentActiveProgram: state.UserDetail.get('currentActiveProgram'),
  userDetail: state.UserDetail.get('userDetail'),
  roleSelected: state.UserDetail.get('roleSelected'),
});

export default connect(
  mapStateToProps,
  null,
)(EmptyDashboard);

EmptyDashboard.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  currentActiveProgram: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.shape()),
  }),
  roleSelected: PropTypes.shape({
    displayName: PropTypes.string,
    name: PropTypes.string,
  }),
  userDetail: PropTypes.shape({}).isRequired,
};

EmptyDashboard.defaultProps = {
  currentActiveProgram: null,
  roleSelected: {
    name: null,
  },
};
