import React, { PureComponent } from 'react';
import _ from 'lodash';
import uuid from 'uuid';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styles from './styles.scss';

const ellipsisDefaultStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  webkitBoxOrient: 'vertical',
  display: '-webkit-box',
  webkitLineClamp: '2',
  wordWrap: 'break-word',
};
/**
 * ToolTipTextOverflow field
 * @extends PureComponent
 */
class ToolTipTextOverflow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasOverflowingChildren: false,
      text: undefined,
    };
    this.updateOverflow = this.updateOverflow.bind(this);
  }
  /**
   * check the text to show tooltip
   * @param  {object} e
   */
  updateOverflow(e) {
    const el = e.target;
    const { hasOverflowingChildren, text } = this.state;

    if (!hasOverflowingChildren && el.scrollHeight > el.clientHeight) {
      this.setState({ hasOverflowingChildren: true });
      if (el.textContent !== text) {
        const chirdren = _.replace(el.textContent, this.props.label, '');
        this.setState({ text: _.trim(chirdren) });
      }
    } else {
      this.setState({ hasOverflowingChildren: false });
    }
  }
  /**
   * render text
   * if the text is too long - make truncate and add boostrap tooltip
   * @return {element}
   */
  render() {
    const { hasOverflowingChildren, text } = this.state;
    const {
      placement = 'top',
      style = {},
      children,
      customClass,
      label,
    } = this.props;
    const ellipsisStyle = { ...ellipsisDefaultStyle, ...style };
    if (!hasOverflowingChildren) {
      return (
        <div style={ellipsisStyle} onMouseOver={this.updateOverflow}>
          {label && <span>{label}</span>} {children}
        </div>
      );
    }

    const classNameTooltip = customClass || styles['tool-tip-wrapper'];
    const tooltip =
    (<Tooltip
      id={`tooltip-${uuid.v4()}`}
      className={classNameTooltip}
      style={this.props.tooltipStyle}
    >
      {text}
    </Tooltip>);
    return (
      <OverlayTrigger
        placement={placement}
        overlay={tooltip}
      >
        <div style={{ ...ellipsisStyle, cursor: 'pointer' }}>
          {label && <span>{label}</span>} {children}
        </div>
      </OverlayTrigger>
    );
  }
}

ToolTipTextOverflow.propTypes = {
  // position of tooltip
  placement: PropTypes.string,
  children: PropTypes.node.isRequired,
  tooltipStyle: PropTypes.shape({}),
  style: PropTypes.shape({}),
  customClass: PropTypes.string,
  label: PropTypes.string,
};

ToolTipTextOverflow.defaultProps = {
  placement: undefined,
  style: undefined,
  customClass: undefined,
  label: '',
  tooltipStyle: {},
};
// export ToolTipTextOverflow
export default ToolTipTextOverflow;
