import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';

class SessionExpired extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countDown: 5,
    };

    this.goToLoginPage = this.goToLoginPage.bind(this);
    this.updateCountDown = this.updateCountDown.bind(this);
    this.counter = setInterval(this.updateCountDown, 1000);
  }

  componentDidUpdate() {
    if (this.state.countDown === -1) {
      clearInterval(this.counter);
      window.sessionStorage.clear();
      this.goToLoginPage();
    }
  }

  updateCountDown() {
    this.setState({
      countDown: this.state.countDown - 1,
    });
  }

  goToLoginPage() {
    this.props.history.push(`/login${this.props.location.search}`);
  }

  render() {
    const { t } = i18n;
    const userLocale = window.localStorage.getItem('userLocale');
    i18n.changeLanguage(userLocale || 'en');
    return (<div className="text-center">
      <h1>{t('Your session has expired')}</h1>
      <h2>{t('Please login again.')}</h2>
      <p>
        {t('automaticallyRedirect')}{' '}
        <a
          tabIndex="0"
          role="button"
          onClick={this.goToLoginPage}
        >
          {t('login')}{' '}
        </a>
        {t('page in')}{' '}
        <span id="countDown" >
          {this.state.countDown}
        </span>
        {' '}{t('seconds.')}
      </p>
    </div>);
  }
}

SessionExpired.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default SessionExpired;
