import { reducer as formReducer } from 'redux-form';
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducers as NotificationMessageBoxReducer, AlertReducer } from '@vibrent/electryon';
import { reducers } from '../coreModules/authentication';
import { RequestReducer } from '../coreModules/authentication/components/middlewares/request';
import ModuleLoader from '../coreModules/moduleLoader/reducer';
import MessageBoxReducer from '../coreModules/authentication/components/common/messageBox/reducers';
import LocalStoreReducer from './localStoreReducer';
import modules from './module';

let allReducers = {
  routing: routerReducer,
  ModuleLoader,
  LocalStoreReducer,
  MessageBoxReducer,
  ...reducers,
  modules,
  ...RequestReducer,
  form: formReducer,
  NotificationMessageBoxReducer,
  AlertReducer,
};
/**
 * createReducer
 * @param {Object} newReducers
 * @return combineReducers
 */
const createReducer = (newReducers) => {
  allReducers = { ...allReducers, ...newReducers };
  return combineReducers({
    ...allReducers,
  });
};
// Export createReducer;
export default createReducer;
