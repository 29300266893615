import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from 'react-tabs';
import _ from 'lodash';
import styles from './styles.scss';

const CLASS_NAME = styles.tabs;

class ReactTab extends PureComponent {
  /**
   * Render tab header
   * @return {Element} React element
   */
  renderHeader() {
    const { headerTitles, t } = this.props;
    return (
      <TabList>
        {_.map(headerTitles, title => (<Tab key={title}>{t(title)}</Tab>))}
      </TabList>
    );
  }
  /**
   * Render content tabs
   * @return {Element} React element
   */
  renderContent() {
    const { contents } = this.props;
    return (
      <div className="items">
        {_.map(contents, (content, key) => (<TabPanel key={key} >{content}</TabPanel>))}
      </div>
    );
  }

  render() {
    return (
      <div className={CLASS_NAME}>
        <Tabs
          onSelect={this.props.onSelect}
          selectedIndex={this.props.selectedIndex}
        >
          {this.renderHeader()}
          {this.renderContent()}
        </Tabs>
      </div>
    );
  }
}
ReactTab.propTypes = {
  headerTitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  contents: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ])).isRequired,
  onSelect: PropTypes.func,
  selectedIndex: PropTypes.number,
  t: PropTypes.func,
};
ReactTab.defaultProps = {
  headerTitle: [],
  content: [],
  onSelect: undefined,
  selectedIndex: null,
  t: x => x,
};
export default ReactTab;
