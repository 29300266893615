import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Button } from '@vibrent/electryon';
import { Menu, MenuItem } from '@mui/material';
import * as actions from '../../actions';
import {
  NOTIFICATION_STATUS,
} from '../../../../constants';
import styles from './style.scss';

const CLASS_NAME = styles['notification-menu'];

export const NOTIFICATION_MENU_MODE = {
  READ_ONE: 1,
  READ_ALL: 2,
  UNREAD_ONE: 3,
  UNREAD_ALL: 4,
};

const NotificationMenu = (props) => {
  const { t } = props;
  const [anchor, setAnchor] = useState(null);
  const endTimeOfDay = new Date();
  endTimeOfDay.setHours(23, 59, 59, 999);

  const deleteAllRead = () => {
    setAnchor(null);
    props.actions.saveNotificationStatus({
      from: props.from,
      to: props.to,
      status: NOTIFICATION_STATUS.DELETED,
      oldStatus: NOTIFICATION_STATUS.READ,
      dateTime: props.lastestQueryTime,
      endTimeOfDate: endTimeOfDay.valueOf(),
    });
  };
  const deleteAllUnread = () => {
    setAnchor(null);
    props.actions.saveNotificationStatus({
      from: props.from,
      to: props.to,
      status: NOTIFICATION_STATUS.DELETED,
      oldStatus: NOTIFICATION_STATUS.UNREAD,
      dateTime: props.lastestQueryTime,
      endTimeOfDate: endTimeOfDay.valueOf(),
    });
  };
  const deleteOne = () => {
    setAnchor(null);
    props.actions.saveNotificationStatus({
      id: props.id,
      status: NOTIFICATION_STATUS.DELETED,
      typeSection: props.typeSection,
    });
  };
  const markOneAsRead = () => {
    setAnchor(null);
    props.actions.saveNotificationStatus({
      id: props.id,
      status: NOTIFICATION_STATUS.READ,
      typeSection: props.typeSection,
    });
  };
  const markAllAsRead = () => {
    setAnchor(null);
    props.actions.saveNotificationStatus({
      from: props.from,
      to: props.to,
      status: NOTIFICATION_STATUS.READ,
      oldStatus: NOTIFICATION_STATUS.UNREAD,
      dateTime: props.lastestQueryTime,
      endTimeOfDate: endTimeOfDay.valueOf(),
    });
  };
  const markOneAsUnRead = () => {
    setAnchor(null);
    props.actions.saveNotificationStatus({
      id: props.id,
      status: NOTIFICATION_STATUS.UNREAD,
      typeSection: props.typeSection,
    });
  };
  const markAllAsUnread = () => {
    setAnchor(null);
    props.actions.saveNotificationStatus({
      from: props.from,
      to: props.to,
      status: NOTIFICATION_STATUS.UNREAD,
      oldStatus: NOTIFICATION_STATUS.READ,
      dateTime: props.lastestQueryTime,
      endTimeOfDate: endTimeOfDay.valueOf(),
    });
  };
  const menuItems = [];
  if (props.mode === NOTIFICATION_MENU_MODE.READ_ONE) {
    menuItems.push(
      <MenuItem id="noti-menu-delete" aria-label={t('Delete')} onClick={deleteOne}>{t('Delete')}</MenuItem>
    );
    menuItems.push(
      <MenuItem id="noti-menu-mark-unread" aria-label={t('Mark as unread')} onClick={markOneAsUnRead}>{t('Mark as unread')}</MenuItem>
    );
  }
  if (props.mode === NOTIFICATION_MENU_MODE.READ_ALL) {
    menuItems.push(<MenuItem id="noti-menu-delete-all" aria-label={t('Delete all')} onClick={deleteAllRead}>{t('Delete all')}</MenuItem>);
    menuItems.push(
      <MenuItem id="noti-menu-mark-all-unread" aria-label={t('Mark all as unread')} onClick={markAllAsUnread}>{t('Mark all as unread')}</MenuItem>
    );
  }
  if (props.mode === NOTIFICATION_MENU_MODE.UNREAD_ONE) {
    menuItems.push(<MenuItem id="noti-menu-delete" aria-label={t('Delete')} onClick={deleteOne}>{t('Delete')}</MenuItem>);
    menuItems.push(
      <MenuItem id="noti-menu-mark-read" aria-label={t('Mark as read')} onClick={markOneAsRead}>{t('Mark as read')}</MenuItem>
    );
  }
  if (props.mode === NOTIFICATION_MENU_MODE.UNREAD_ALL) {
    menuItems.push(
      <MenuItem id="noti-menu-delete-all" aria-label={t('Delete all')} onClick={deleteAllUnread}>{t('Delete all')}</MenuItem>
    );
    menuItems.push(
      <MenuItem id="noti-menu-mark-all-read" aria-label={t('Mark all as read')} onClick={markAllAsRead}>{t('Mark all as read')}</MenuItem>
    );
  }

  return (
    <div className={`${CLASS_NAME} ${props.className}`}>
      <Button
        buttonType="dropdown"
        aria-label="Notifications"
        className="action-btn"
        disabled={props.disabled}
        endIcon={null}
        label={(<i className="fas fa-ellipsis-h" />)}
        variant="text"
        color="primary"
        onClick={e => setAnchor(e.currentTarget)}
      >
        <Menu
          open={Boolean(anchor)}
          anchorEl={anchor}
          onClose={() => setAnchor(null)}
        >
          {menuItems}
        </Menu>
      </Button>
    </div>
  );
};

NotificationMenu.propTypes = {
  actions: PropTypes.shape({
    setSiteId: PropTypes.func.isRequired,
    getNotification: PropTypes.func.isRequired,
    saveNotificationStatus: PropTypes.func.isRequired,
  }).isRequired,
  from: PropTypes.number,
  id: PropTypes.string,
  to: PropTypes.number,
  mode: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  typeSection: PropTypes.string,
  lastestQueryTime: PropTypes.string,
  t: PropTypes.func,
};

// Default props of Notifications
NotificationMenu.defaultProps = {
  from: null,
  id: null,
  to: null,
  mode: NOTIFICATION_MENU_MODE.READ_ONE,
  className: '',
  disabled: false,
  typeSection: '',
  lastestQueryTime: null,
  t: x => x,
};


// mapDispatchToProps
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, actions), dispatch),
});


// Export default connect
export default connect(
  null,
  mapDispatchToProps
)(NotificationMenu);

export const NotificationMenuComponent = NotificationMenu;
