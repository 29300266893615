import { RequestUtil } from 'pmt-common';
import { AlertActions, GTMUtil } from '@vibrent/electryon';
import {
  PROTOCOL_BUILDER_REQUEST_START,
  PROTOCOL_BUILDER_REQUEST_SUCCESS,
  PROTOCOL_BUILDER_REQUEST_FAILURE,
} from './events';
import i18n from '../../../../i18n';

export const PROTOCOL_BUILDER_REQUEST = 'protocolBuilderRequest';

const { showErrorAlert, showSuccessAlert } = AlertActions;

export const protocolBuilderRequestStart = () => ({
  type: PROTOCOL_BUILDER_REQUEST_START,
});

export const protocolBuilderRequestSuccess = response => ({
  type: PROTOCOL_BUILDER_REQUEST_SUCCESS,
  response,
});

export const protocolBuilderRequestFailure = error => ({
  type: PROTOCOL_BUILDER_REQUEST_FAILURE,
  error,
});

export const protocolBuilderRequest = userData =>
  async (dispatch, getState) => {
    const userDetailReducer = getState().UserDetail;
    const userDetails = (userDetailReducer && userDetailReducer.get('userDetail')) || {};
    const currentActiveProgram = (userDetailReducer &&
      userDetailReducer.get('currentActiveProgram')) || {};
    const userInfo = {
      userDetail: {
        ...userDetails,
        ...currentActiveProgram,
      },
      roleSelected: (userDetailReducer && userDetailReducer.get('roleSelected')) || null,
      nodeData: (userDetailReducer && userDetailReducer.get('nodeData')) || [],
    };
    try {
      dispatch(protocolBuilderRequestStart());
      const url = '/api/protocolbuilder/contactme';
      const response = await RequestUtil.postData(url, userData);
      dispatch(showSuccessAlert(
        PROTOCOL_BUILDER_REQUEST,
        i18n.t('Thank you for providing your contact information. We will connect with you shortly.'),
      ));
      dispatch(protocolBuilderRequestSuccess(response));

      GTMUtil.pushTagManager(userInfo, 'protocolBuilderFormSubmit', ['success']);
      return response;
    } catch (e) {
      dispatch(showErrorAlert(
        PROTOCOL_BUILDER_REQUEST,
        i18n.t('There was an error in form submission, please try again.'),
      ));
      dispatch(protocolBuilderRequestFailure(e));
      GTMUtil.pushTagManager(userInfo, 'protocolBuilderFormSubmit', ['error']);
      return e;
    }
  };
