import { Map as MapImmutable } from 'immutable';
import * as EVENT from './events';

const initialState = new MapImmutable({
  requests: new MapImmutable({}),
});

const actionsMap = {};

actionsMap[EVENT.START_REQUEST] = (state, action) => {
  const { id, request, timestamp } = action.payload;
  const requestDetail = {
    request,
    timestamp,
  };
  return state.set('requests', state.get('requests').set(id, requestDetail));
};

actionsMap[EVENT.END_REQUEST] = (state, action) => {
  const { id, timestamp } = action.payload;
  const requests = state.get('requests');

  return (requests.get(id).timestamp === timestamp) ?
    state.set('requests', requests.delete(id)) :
    state;
};

actionsMap[EVENT.ABORT_REQUEST] = (state, action) => {
  const { id } = action.payload;
  const requests = state.get('requests');
  requests.get(id).request.abort();
  return state.set('requests', requests.delete(id));
};

export default (state = initialState, action) => {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
};
