// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-coreModules-authentication-components-common-fieldForm-styles__form-group--3gPfbUf4 .label-wrapper{display:table-cell;vertical-align:middle;height:32px}.src-coreModules-authentication-components-common-fieldForm-styles__form-group--3gPfbUf4 .label-wrapper label{margin-bottom:0px}.src-coreModules-authentication-components-common-fieldForm-styles__form-group--3gPfbUf4 .form-control{border-radius:4px;border:solid 1px #bbb;height:35px}", ""]);
// Exports
exports.locals = {
	"form-group": "src-coreModules-authentication-components-common-fieldForm-styles__form-group--3gPfbUf4"
};
module.exports = exports;
