import BaseModel from './baseModel';

const NAME = 'ToggleFeatureModel';

/**
 * Toggle Feature model
 * @extends BaseModel
 */
class ToggleFeatureModel extends BaseModel {
  constructor() {
    super(NAME, {
      type: 'object',
      required: ['id'],
      properties: {
        id: {
          type: 'integer',
        },
        name: {
          type: 'string',
        },
        displayName: {
          type: 'string',
        },
        programId: {
          type: 'integer',
        },
        enabled: {
          type: 'boolean',
        },
      },
      dynamicDefaults: {
        id: 'uuid',
      },
    });
  }
}

export default ToggleFeatureModel;
