import { Map as MapImmutable } from 'immutable';
import _ from 'lodash';
import {
  SHOW_MESSAGE,
  CLEAR_MESSAGE,
  CLEAR_MESSAGE_BOX,
  CLEAR_ALL_MESSAGE_BOX,
} from './events';


// Init state
// This is a message box's dictionary
// key is message box's id
// value is messages box's messages
const initialState = new MapImmutable({});

// Init action handlers
const actionHandlers = {};

/**
 * Push a message to messages array of a message box
 * @param {Object} state
 * @param {Object} payload
 * @returns {Object} newState
 */
actionHandlers[SHOW_MESSAGE] = (state, { payload }) => {
  const messages = state.get(payload.id);

  if (payload.message.clearOtherMessages) {
    return new MapImmutable({ [payload.id]: [payload.message] });
  }

  if (messages) {
    messages.push(payload.message);
    return state.set(payload.id, Object.assign([], [...messages]));
  }

  return state.set(payload.id, Object.assign([], [payload.message]));
};

/**
 * Hide a message from message box
 * @param {Object} state
 * @param {Object} payload
 * @returns {Object} newState
 */
actionHandlers[CLEAR_MESSAGE] = (state, { payload }) => {
  const messages = state.get(payload.id);
  if (!messages) {
    return state;
  }

  const message = _.find(messages, item => item.id === payload.messageId);
  if (message) {
    message.hide = true;
  }
  return state.set(payload.id, Object.assign([], [...messages]));
};

/**
 * Remove messages array of a message box
 * @param {Object} state
 * @param {Object} payload
 * @returns {Object} newState
 */
actionHandlers[CLEAR_MESSAGE_BOX] = (state, action) => state.delete(action.payload);

/**
 * Remove messages array of all message box
 * @returns {Object} newState
 */
actionHandlers[CLEAR_ALL_MESSAGE_BOX] = () => initialState;

/**
 * Reducer handler
 * @param  {Object} state
 * @param  {Object} action
 * @return {Object} next state
 */
export default (state = initialState, action) => {
  // Get appropriate action handler by type
  const fn = actionHandlers[action.type];
  // Check if handler is existed
  // Then call the handler
  // Otherwise return state
  return fn ? fn(state, action) : state;
};
