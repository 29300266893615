import React from 'react';
import PropTypes from 'prop-types';
import { Field as ReduxField } from 'redux-form';
import { FormGroup, ControlLabel, FormControl, HelpBlock, Row, Col } from 'react-bootstrap';
import { DatePicker as DatePickerComponent, Select as SelectRedux } from '@vibrent/electryon';
import styles from './styles.scss';
import ColoredCheckbox from '../coloredCheckbox';
import {
  required,
} from './validator';

const formGroupClass = styles['form-group'];

// Render Field - Start
const RenderField = props => (
  <FormGroup
    validationState={props.meta.touched && props.meta.error ? 'error' : null}
    className={formGroupClass}
  >
    <Row>
      <Col sm={props.labelColSm} className={props.labelColClass}>
        <div className="label-wrapper">
          <ControlLabel className={`input-label label-form ${props.labelClass}`}>
            {props.label}{props.required && <span className="star-mark">*</span>}&nbsp;
          </ControlLabel>
        </div>
      </Col>
      <Col sm={props.inputColSm} className={props.inputColClass}>
        <FormControl
          type={props.type}
          placeholder={props.placeholder}
          disabled={props.disabled}
          componentClass={props.componentClass}
          rows={props.rows}
          maxLength={props.maxLength}
          bsClass={props.bsClass}
          required={props.required}
          className={`${props.formControlClass} ${props.input.value ? 'has-value' : ''}`}
          {...props.input}
          {...props}
        />
      </Col>
    </Row>
    {props.inline ? (
      props.meta.touched && (props.meta.error && <div className="help-block-wrapper">
        <HelpBlock>{props.meta.error}</HelpBlock>
      </div>)
    ) : (
      <Row>
        <Col smOffset={props.labelColSm} sm={props.inputColSm} className={props.inputColClass}>
          {props.meta.touched && (props.meta.error && <div className="help-block-warpper">
            <HelpBlock>{props.meta.error}</HelpBlock>
          </div>)}
        </Col>
      </Row>
    )}
  </FormGroup>
);

RenderField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  componentClass: PropTypes.string,
  rows: PropTypes.string,
  maxLength: PropTypes.string,
  bsClass: PropTypes.string,
  labelColSm: PropTypes.number,
  inputColSm: PropTypes.number,
  labelClass: PropTypes.string,
  required: PropTypes.bool,
  inline: PropTypes.bool,
  labelColClass: PropTypes.string,
  inputColClass: PropTypes.string,
  formControlClass: PropTypes.string,
};

RenderField.defaultProps = {
  placeholder: '',
  disabled: false,
  componentClass: 'input',
  rows: undefined,
  maxLength: undefined,
  bsClass: undefined,
  labelColSm: 12,
  inputColSm: 12,
  labelClass: '',
  required: false,
  inline: true,
  labelColClass: '',
  inputColClass: '',
  formControlClass: '',
};
// Render Field - End

// Checkbox - Start
const CheckboxComponent = (props) => {
  const { input } = props;
  return <ColoredCheckbox checked={input.value} {...input} {...props} />;
};

CheckboxComponent.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.boolean,
  }).isRequired,
};
// Checkbox - End

// DatePicker - Start
const DatePickerWrapper = (props) => {
  const { input } = props;
  return <DatePickerComponent {...input} {...props} />;
};

DatePickerWrapper.propTypes = {
  input: PropTypes.shape({}).isRequired,
};
// DatePicker - End

const FieldForm = props => <ReduxField {...props} component={RenderField} />;

export const Select = props => <ReduxField {...props} component={SelectRedux} />;
export const DatePicker = props => <ReduxField {...props} component={DatePickerWrapper} />;
export const Checkbox = props => <ReduxField {...props} component={CheckboxComponent} />;

export const normalizeTemplateIds = (value) => {
  if (!value || value === '') {
    return null;
  }
  return value.replace(/[^0-9,]+/g, '');
};

export {
  required,
};

export default FieldForm;
