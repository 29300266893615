// GET_MODULE_INFORMATION_START
export const GET_MODULE_INFORMATION_START = 'GET_MODULE_INFORMATION_START';
// GET_MODULE_INFORMATION_SUCCESS
export const GET_MODULE_INFORMATION_SUCCESS = 'GET_MODULE_INFORMATION_SUCCESS';
// GET_MODULE_INFORMATION_FAILURE
export const GET_MODULE_INFORMATION_FAILURE = 'GET_MODULE_INFORMATION_FAILURE';
// LOAD_MODULE_START
export const LOAD_MODULE_START = 'LOAD_MODULE_START';
// LOAD_MODULE_SUCCESS
export const LOAD_MODULE_SUCCESS = 'LOAD_MODULE_SUCCESS';
// LOAD_MODULE_FAILURE
export const LOAD_MODULE_FAILURE = 'LOAD_MODULE_FAILURE';
// ADD_MODULE
export const ADD_MODULE = 'ADD_MODULE';
