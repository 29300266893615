import { Map as MapImmutable } from 'immutable';
import store from '../localStore';

export const initialState = new MapImmutable({
  data: new MapImmutable({}),
  storing: false,
  error: null,
});

export const STORE_DATA_TO_LOCAL_START = 'STORE_DATA_TO_LOCAL_START';
export const STORE_DATA_TO_LOCAL_SUCCESS = 'STORE_DATA_TO_LOCAL_SUCCESS';
export const STORE_DATA_TO_LOCAL_FAILURE = 'STORE_DATA_TO_LOCAL_FAILURE';
export const GET_DATA_FROM_LOCAL_START = 'GET_DATA_FROM_LOCAL_START';
export const GET_DATA_FROM_LOCAL_SUCCESS = 'GET_DATA_FROM_LOCAL_SUCCESS';
export const GET_DATA_FROM_LOCAL_FAILURE = 'GET_DATA_FROM_LOCAL_FAILURE';

const actionHandlers = {};
/**
 * STORE_DATA_TO_LOCAL_START
 * @param   {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[STORE_DATA_TO_LOCAL_START] = state => state
  .set('storing', true)
  .set('error', null);
/**
   * STORE_DATA_TO_LOCAL_SUCCESS
   * @param   {Object} state Current state
   * @param {Object} action
   * @return {Object} Next state
   */
actionHandlers[STORE_DATA_TO_LOCAL_SUCCESS] =
  (state, { data: { compressedData, key } }) => {
    // Using expire plugin to expire in 5 minutes
    store.set(key, compressedData, new Date().getTime() + (5 * 60 * 1000));
    store.removeExpiredKeys();

    return state
      .set('storing', false)
      .set('error', null);
  };
/**
     * STORE_DATA_TO_LOCAL_FAILURE
     * @param   {Object} state Current state
     * @param {Object} action
     * @return {Object} Next state
     */
actionHandlers[STORE_DATA_TO_LOCAL_FAILURE] = (state, error) => state
  .set('storing', false)
  .set('error', error);

/**
   * GET_DATA_FROM_LOCAL_START
   * @param   {Object} state Current state
   * @param {Object} action
   * @return {Object} Next state
   */
actionHandlers[GET_DATA_FROM_LOCAL_START] = state => state
  .set('dashboardFilterOptions', null)
  .set('error', null);
/**
       * GET_DATA_FROM_LOCAL_SUCCESS
       * @param   {Object} state Current state
       * @param {Object} action
       * @return {Object} Next state
       */
actionHandlers[GET_DATA_FROM_LOCAL_SUCCESS] = (state, { key }) => state
  .setIn(['data', 'dashboardFilterOptions'], store.get(key) || '')
  .set('error', null);
/**
         * GET_DATA_FROM_LOCAL_FAILURE
         * @param   {Object} state Current state
         * @param {Object} action
         * @return {Object} Next state
         */
actionHandlers[GET_DATA_FROM_LOCAL_FAILURE] = (state, error) => state
  .set(['data', 'dashboardFilterOptions'], null)
  .set('error', error);
/**
   * Action Handlers
   * @param   {Object} state Current state
   * @param {Object} action
   * @return {Object} Next state
   */
export default (state = initialState, action) => {
  if (actionHandlers[action.type] !== undefined) {
    const nextState = actionHandlers[action.type](state, action);
    if (nextState === null || nextState === undefined) {
      return state;
    }
    return nextState;
  }
  return state;
};
