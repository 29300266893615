import _ from 'lodash';

const urlUtil = {
  generateUrlWithQuery(baseUrl, object) {
    // remove null of undefined atribute
    const obj = _.omitBy(object, _.isNil);
    const parts = _.map(obj, (value, key) => `${key}=${value}`);
    return `${baseUrl}?${parts.join('&')}`;
  },
};

export default urlUtil;
