import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import NotificationsModal from './components';

// mapStateToProps
const mapStateToProps = state => ({
  totalUnread: state.NotificationReducer.get('totalUnread'),
  totalRequesting: state.NotificationReducer.get('totalRequesting'),
  userDetail: state.UserDetail.get('userDetail'),
  isPMI: state.UserDetail.get('isPMI'),
  roleSelected: state.UserDetail.get('roleSelected'),

  unreadEarlierNotifications: state.NotificationReducer.get('unreadEarlierNotifications'),
  unreadRecentNotifications: state.NotificationReducer.get('unreadRecentNotifications'),
  unreadTotalRecent: state.NotificationReducer.get('unreadTotalRecent'),
  unreadTotalEarlier: state.NotificationReducer.get('unreadTotalEarlier'),
  unreadRecentRequesting: state.NotificationReducer.get('unreadRecentRequesting'),
  unreadEarlierRequesting: state.NotificationReducer.get('unreadEarlierRequesting'),

  readEarlierNotifications: state.NotificationReducer.get('readEarlierNotifications'),
  readRecentNotifications: state.NotificationReducer.get('readRecentNotifications'),
  readTotalRecent: state.NotificationReducer.get('readTotalRecent'),
  readTotalEarlier: state.NotificationReducer.get('readTotalEarlier'),
  readRecentRequesting: state.NotificationReducer.get('readRecentRequesting'),
  readEarlierRequesting: state.NotificationReducer.get('readEarlierRequesting'),
  notificationsStatus: state.NotificationReducer.get('notificationsStatus'),
});

// mapDispatchToProps
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, actions), dispatch),
});

// Export default connect
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsModal);
