import { Map as MapImmutable } from 'immutable';
import {
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from './events';

const initialState = new MapImmutable({
  requesting: false,
  isLogout: false,
});

const actionHandlers = {};
/**
 * LOGOUT_START
 * @param {Object} state Current state
 * @return {Object} Next state
 */
actionHandlers[LOGOUT_START] = state => state
  .set('requesting', true)
  .set('isLogout', false)
  .delete('error');
/**
 * LOGOUT_SUCCESS
 * @param {Object} state Current state
 * @return {Object} Next state
 */
actionHandlers[LOGOUT_SUCCESS] = state => state
  .set('requesting', false)
  .set('isLogout', true)
  .delete('error');
/**
 * LOGOUT_FAILURE
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[LOGOUT_FAILURE] = (state, action) => state
  .set('requesting', false)
  .set('isLogout', false)
  .set('error', action.error);
/**
 * Action Handlers
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
export default (state = initialState, action) => {
  if (actionHandlers[action.type] !== undefined) {
    const nextState = actionHandlers[action.type](state, action);
    if (nextState === null || nextState === undefined) {
      return state;
    }
    return nextState;
  }
  return state;
};
