'use strict';

// polyfill for many ES features not supported in some browsers
if (
  (typeof window !== 'undefined' && !window._babelPolyfill) ||
  (typeof global !== 'undefined' && !global._babelPolyfill)
) {
  require('babel-polyfill');
}

// fetch() polyfill for making API calls.
require('whatwg-fetch');
