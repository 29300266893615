import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FieldUtils, PhoneNumberView } from '@vibrent/electryon';
import { reduxForm, Field as ReduxField } from 'redux-form';
import _ from 'lodash';
import {
  Col,
  Row,
  ControlLabel,
  Form,
  Button,
} from 'react-bootstrap';
import { AsYouType } from 'libphonenumber-js';
import * as countries from 'react-flags-select';
import styles from './styles.scss';
import {
  maxLength50,
  required,
  firstName,
  lastName,
  normalizePhoneValidation,
} from '../../components/common/fieldForm/validator';
import Field from '../../components/common/fieldForm';

const {
  renderPhoneNumberInput,
} = FieldUtils;

const UserProfileForm = (props) => {
  const className = styles['.user-profile-form'];
  const { t } = useTranslation();
  const phoneValidator =
    useMemo(() => normalizePhoneValidation(
      props.internationalPhoneSupportEnabled), [props.internationalPhoneSupportEnabled]);

  const renderViewModeField = (label, value) => (
    <Fragment>
      <ControlLabel className="label-padding label-padding-top">
        {t(label)}
        <span className="star-mark">*</span>
      </ControlLabel>
      <div className="field-value">{value}</div>
    </Fragment>
  );

  const renderPhone = (phoneNumber) => {
    const getPhone = () => {
      const asYouTypeNew = new AsYouType();
      asYouTypeNew.input(phoneNumber);
      const country = asYouTypeNew.getNumber() && asYouTypeNew.getNumber().country;
      const Flag = countries[_.capitalize(country)];
      return (
        <span>
          {Flag && <Flag />}
          <PhoneNumberView
            phoneNumber={phoneNumber}
            internationalPhoneSupportEnabled
          />
        </span>
      );
    };
    return (
      <Fragment>
        <ControlLabel className="label-padding label-padding-top">
          {t('Mobile Number')}
        </ControlLabel>
        <div className="field-value mobile-field">
          {!phoneNumber ? t('Not Available') : getPhone()}
        </div>
      </Fragment>
    );
  };

  const handleCancel = () => {
    props.setViewMode();
    props.reset();
  };

  const onSubmit = (user) => {
    const userData = _.omit(user, ['roles', 'email']);
    props.updateUserProfile(userData).then((res) => {
      if (res.code === 200) {
        props.updateUserDetails(user);
      }
      props.setViewMode();
      props.reset();
    });
  };

  return (<Form
    onSubmit={props.handleSubmit(values => onSubmit(values))}
    className={className}
  >
    <Row>
      <Col sm={4}>
        <Row>
          <Col sm={12}>
            {props.viewMode ?
              renderViewModeField('firstName', props.initialValues.firstName)
              :
              <Field
                name="firstName"
                type="text"
                label={t('firstName')}
                validate={[required, firstName, maxLength50]}
                required
              />
            }
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            {props.viewMode ?
              renderViewModeField('Last Name', props.initialValues.lastName)
              :
              <Field
                name="lastName"
                type="text"
                label={t('Last Name')}
                validate={[required, lastName, maxLength50]}
                required
              />
            }
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            {props.viewMode ?
              renderViewModeField('Registered Email', props.initialValues.email)
              :
              <Field
                name="email"
                type="text"
                className="disabled-field"
                label={t('Registered Email')}
                required
                disabled
              />
            }
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            {props.viewMode ?
              renderPhone(props.initialValues.phoneNumber)
              :
              <Fragment>
                <ControlLabel className="label-padding">{t('Mobile Number')}</ControlLabel>
                <ReduxField
                  name="phoneNumber"
                  type="text"
                  component={renderPhoneNumberInput}
                  className="form-control"
                  validate={[phoneValidator]}
                  internationalPhoneSupportEnabled
                />
              </Fragment>
            }
          </Col>
        </Row>
        { !props.viewMode && <Row>
          <Col sm={12}>
            <div className="profile-btns">
              <Button
                type="submit"
                disabled={props.invalid || props.pristine || props.updatingProfile}
                className="btn btn-primary"
              >
                {t('Save')}
              </Button>
              <Button
                className="profile-cancel-button btn btn-secondary"
                onClick={handleCancel}
              >
                {t('Cancel')}
              </Button>
            </div>
          </Col>
        </Row>}
      </Col>
      <Col sm={4}>
        <Row>
          <Col sm={12} className="roles-box">
            <ControlLabel className="label-padding">{t('Roles Assigned')}</ControlLabel>
            <textarea
              name="roles"
              type="text"
              component="textarea"
              className="form-control m-b-24"
              value={props.initialValues.roles}
              disabled
            />
          </Col>
        </Row>
      </Col>
    </Row>
  </Form>
  );
};

UserProfileForm.propTypes = {
  initialValues: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.number,
    roles: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  viewMode: PropTypes.bool.isRequired,
  internationalPhoneSupportEnabled: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  setViewMode: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  updatingProfile: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  updateUserDetails: PropTypes.func.isRequired,
};
UserProfileForm.defaultProps = {
  initialValues: {},
  viewMode: false,
  internationalPhoneSupportEnabled: false,
  updatingProfile: true,
};

const formName = 'VibrentUserProfileForm';

const UserProfileFormConnect = reduxForm({
  form: formName,
  validate: UserProfileForm.validator,
  enableReinitialize: true,
})(UserProfileForm);

export default UserProfileFormConnect;
