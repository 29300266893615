export const SET_LAST_TIME_PING_SERVER = 'SET_LAST_TIME_PING_SERVER';
export const SET_LAST_TIME_INTERACT_UI = 'SET_LAST_TIME_INTERACT_UI';
export const PING_SERVER_START = 'PING_SERVER_START';
export const PING_SERVER_SUCCESS = 'PING_SERVER_SUCCESS';
export const PING_SERVER_FAILURE = 'PING_SERVER_FAILURE';
export const IS_CATI_SESSION_ACTIVE_START = 'IS_CATI_SESSION_ACTIVE_START';
export const IS_CATI_SESSION_ACTIVE_SUCCESS = 'IS_CATI_SESSION_ACTIVE_SUCCESS';
export const IS_CATI_SESSION_ACTIVE_FAILURE = 'IS_CATI_SESSION_ACTIVE_FAILURE';
export const IS_PARTICIPANT_PROFILE = 'IS_PARTICIPANT_PROFILE';

export default {
  SET_LAST_TIME_PING_SERVER,
  SET_LAST_TIME_INTERACT_UI,
  PING_SERVER_START,
  PING_SERVER_SUCCESS,
  PING_SERVER_FAILURE,
  IS_CATI_SESSION_ACTIVE_START,
  IS_CATI_SESSION_ACTIVE_SUCCESS,
  IS_CATI_SESSION_ACTIVE_FAILURE,
};
