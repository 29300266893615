// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-coreModules-authentication-components-common-toolTipTextOverflow-styles__tool-tip-wrapper--4BkKAcot .tooltip-arrow{display:none}.src-coreModules-authentication-components-common-toolTipTextOverflow-styles__tool-tip-wrapper--4BkKAcot .tooltip-inner{max-width:100%;padding:3px 8px;color:#fff;text-align:center;background-color:rgba(0,0,0,.6);font-size:11px;border-radius:2px;word-wrap:break-word}", ""]);
// Exports
exports.locals = {
	"tool-tip-wrapper": "src-coreModules-authentication-components-common-toolTipTextOverflow-styles__tool-tip-wrapper--4BkKAcot"
};
module.exports = exports;
