// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-coreModules-authentication-components-logout-style__log-out-spinner-container--5UuwkF5j{position:fixed;transform:translateY(-50%);top:50%;left:50%}", ""]);
// Exports
exports.locals = {
	"log-out-spinner-container": "src-coreModules-authentication-components-logout-style__log-out-spinner-container--5UuwkF5j"
};
module.exports = exports;
