import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Alert,
  AlertActions,
  AlertMapStateToProps,
  AlertMapDispatchToProps,
} from '@vibrent/electryon';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  UrlUtil,
} from 'pmt-common';
import * as actionsGlobalMessage from './action';
import {
  CAMPAGN_STATUS,
  TARGET_AUDIENCE,
  WAITING_TIME,
  SEARCH_NEW_ONE_OFF_TEMPLATE_URL,
  PROSPECT_NEW_ONE_OFF_TEMPLATE_URL,
  PERMISSION_ADHOC_CAMPAIGN,
} from './constant';

export const AlertComponent = connect(
  AlertMapStateToProps,
  AlertMapDispatchToProps,
)(Alert);

/**
 * Get role
 * @param  {String} permission The permission that you want to get role
 * @return {String} Role name
 */
export const getRoleByPermission = (roleSelected, userDetail) => {
  const permission = PERMISSION_ADHOC_CAMPAIGN;
  const checkIsIncludes = (roles, item) => (roles.findIndex(s => s.roleName === item.name) >= 0);
  const { currentActiveProgram } = userDetail;

  if (currentActiveProgram === null) {
    return null;
  }
  // get role can access page by permission.
  const rolesByPermission = currentActiveProgram.permissions
    .filter(p => p.resource.includes(permission));
  // If roleSelected is includes rolesByPermission return name of roleSelected
  // Else need to change to first role in dropdown order by displayName
  if (checkIsIncludes(rolesByPermission, roleSelected)) {
    return roleSelected.name;
  }
  const rolesMapping = currentActiveProgram.roles
    .filter(p => checkIsIncludes(rolesByPermission, p));
  return !_.isEmpty(rolesMapping) ? _.orderBy(rolesMapping, 'displayName')[0].name : null;
};

export const handleClickErrorNotificationToast = (history, url) => {
  history.push(url);
  return true;
};

const GlobalMessage = (props) => {
  let interval = null;
  const { listCampaignSuccess, actions,
    requestingAdhocNotification, history, roleSelected, userDetail } = props;
  const { currentActiveProgram } = userDetail;

  const [toastIndex, setToastIndex] = useState(1);

  const hasAdhocCampaignPerm = React.useMemo(() => {
    if (userDetail && currentActiveProgram && currentActiveProgram.permissions) {
      return currentActiveProgram.permissions.find(
        perm => perm.resource === PERMISSION_ADHOC_CAMPAIGN && perm.action === 'view');
    }
    return false;
  }, [currentActiveProgram]);

  const getNotificationMessage = () => {
    if (currentActiveProgram && !requestingAdhocNotification) {
      props.actions.getAdhocCampaignNotification();
    }
  };

  useEffect(() => () => clearInterval(interval), []);
  useEffect(() => {
    if (hasAdhocCampaignPerm) {
      interval = setInterval(() => {
        getNotificationMessage();
      }, WAITING_TIME);
    }
  }, [userDetail]);

  useEffect(() => {
    // add toast message
    let index = toastIndex;
    _.map(listCampaignSuccess, (obj) => {
      if (obj.campaignStatus === CAMPAGN_STATUS.COMPLETED) {
        const successMessage = `The one-off message was successfully sent to ${obj.audienceFullName}.`;
        actions.showSuccessNotificationToast(
          index,
          successMessage,
        );
        index += 1;
      }
      if (obj.campaignStatus === CAMPAGN_STATUS.FAILED) {
        const url = obj.targetAudience === TARGET_AUDIENCE.PARTICIPANT ?
          SEARCH_NEW_ONE_OFF_TEMPLATE_URL : PROSPECT_NEW_ONE_OFF_TEMPLATE_URL;
        const buildUrl = UrlUtil.generateUrlWithQuery(url,
          {
            participantId: obj.participantId,
            prospectId: obj.prospectId,
            templateId: obj.templateId,
            role: getRoleByPermission(roleSelected, userDetail),
          });

        const failMessage = `The one-off message failed to send to ${obj.audienceFullName}.
         Try again or contact the support center for assistance.`;

        actions.showErrorNotificationToast(
          index,
          failMessage,
          [
            {
              title: 'Try Again',
              onClick: () => handleClickErrorNotificationToast(history, buildUrl),
            },
          ],
        );
        index += 1;
      }
    });

    setToastIndex(index);
  }, [listCampaignSuccess]);

  return (
    <AlertComponent
      id="notification-center"
      viewType="toast"
    />
  );
};

GlobalMessage.propTypes = {
  actions: PropTypes.shape({
    getAdhocCampaignNotification: PropTypes.func.isRequired,
    showSuccessNotificationToast: PropTypes.func.isRequired,
    showErrorNotificationToast: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  userDetail: PropTypes.shape({
    currentActiveProgram: PropTypes.shape({
      permissions: PropTypes.arrayOf({}),
    }),
  }),
  roleSelected: PropTypes.shape({}),
  listCampaignSuccess: PropTypes.arrayOf(PropTypes.shape({
    campaignId: PropTypes.number,
    campaignName: PropTypes.string,
    targetAudience: PropTypes.string,
    audienceFullName: PropTypes.string,
    templateId: PropTypes.number,
    campaignStatus: PropTypes.string,
    participantId: PropTypes.number,
    prospectId: PropTypes.number,
  })),
  requestingAdhocNotification: PropTypes.bool,
};

GlobalMessage.defaultProps = {
  listCampaignSuccess: [],
  requestingAdhocNotification: false,
  userDetail: {},
  roleSelected: null,
  participantId: null,
  prospectId: null,
};

// mapStateToProps
const mapStateToProps = state => ({
  listCampaignSuccess: state.GlobalMessageReducer.get('listCampaignSuccess'),
  requestingAdhocNotification: state.GlobalMessageReducer.get('requestingAdhocNotification'),
  userDetail: state.UserDetail.get('userDetail'),
  roleSelected: state.UserDetail.get('roleSelected'),
});

const mapDispatchToProps = dispatch => ({
  actions:
    bindActionCreators(
      Object.assign({
        ...AlertActions,
        ...actionsGlobalMessage,
      }),
      dispatch,
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GlobalMessage);
export const GlobalMessageComponent = GlobalMessage;
