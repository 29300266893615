import _ from 'lodash';
import { validatePhoneNumber } from '@vibrent/electryon';
import i18n from '../../../../../i18n';

// Validators
export const required = value => (_.trim(value) ? undefined : i18n.t('requiredValidation'));
export const maxLength = max => value =>
  (value && value.length > max ? i18n.t('maxLength', [max]) : undefined);
export const maxLength30 = maxLength(30);
export const maxLength50 = maxLength(50);

export const normalizePhoneValidation = internationalPhoneSupportEnabled => value =>
  (value && validatePhoneNumber(value, internationalPhoneSupportEnabled, i18n.t('The mobile number entered is invalid.')));

export const firstName = value =>
  (value && (!/^[\w][^0-9.'_!¡?÷?¿/\\+=@#$%^`"&*(){}|,~<>;:[\]-]{0,}$/i.test(value) || /^[0-9]$/i.test(value)) ? i18n.t('nameValidation', ['first name.']) : undefined);

export const lastName = value =>
  (value && (!/^[\w][^0-9.'_!¡?÷?¿/\\+=@#$%^`"&*(){}|,~<>;:[\]-]{0,}$/i.test(value) || /^[0-9]$/i.test(value)) ? i18n.t('nameValidation', ['last name.']) : undefined);

export const institution = value =>
  (value && (!/^[\w][^0-9.'_!¡?÷?¿/\\+=@#$%^`"&*(){}|,~<>;:[\]-]{0,}$/i.test(value) || /^[0-9]$/i.test(value)) ? i18n.t('nameValidation', ['institution/company.']) : undefined);

export const email = value =>
  (value && !/^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/i.test(value)
    ? i18n.t('emailValidation')
    : undefined);

export const requiredEmail = value => (_.trim(value) ? undefined : i18n.t('emailValidation'));

export default {

};
