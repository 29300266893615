export const API_GET_ADHOC_CAMPAIGN_NOTIFICATION = '/api/communications/adhocCampaignNotification';

export const GET_ADHOC_CAMPAIGN_NOTIFICATION_START = 'GET_ADHOC_CAMPAIGN_NOTIFICATION_START';
export const GET_ADHOC_CAMPAIGN_NOTIFICATION_SUCCESS = 'GET_ADHOC_CAMPAIGN_NOTIFICATION_SUCCESS';
export const GET_ADHOC_CAMPAIGN_NOTIFICATION_FAILURE = 'GET_ADHOC_CAMPAIGN_NOTIFICATION_FAILURE';

export const CAMPAGN_STATUS = {
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
};
export const TARGET_AUDIENCE = {
  PARTICIPANT: 'participants',
  PROSPECT: 'prospects',
};

export const WAITING_TIME = 60000;
export const PERMISSION_ADHOC_CAMPAIGN = '/communications/adhocCampaign/sendMessage';
export const SEARCH_NEW_ONE_OFF_TEMPLATE_URL = '/search/oneOffTemplate';
export const PROSPECT_NEW_ONE_OFF_TEMPLATE_URL = '/prospectManagement/oneOffTemplate';
