import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const AccessDenied = (props) => {
  const { t } = useTranslation();
  const [count, setCount] = React.useState(10);
  useEffect(() => {
    const id = setInterval(() => {
      setCount(count - 1);
    }, 1000);
    return () => clearInterval(id);
  });

  /**
   * Redirect user to login page
   */
  const goToHomePage = () => {
    props.history.push('/');
  };

  /**
   * Function that force user logout and redirect to login page
   */
  const reLogin = () => {
    props.history.push('/logout');
  };
  // time out then redirect user to homepage
  if (count === -1) {
    goToHomePage();
  }
  return (
    <div className="text-center">
      <h1>{t("We're sorry")}</h1>
      <h2>{t('noPermissionAccess')}</h2>
      <p>
        {t('automaticallyRedirect')}{' '}
        <a
          tabIndex="0"
          role="button"
          onClick={goToHomePage}
        >
          {t('home')}{' '}
        </a>
        {t('page in')}{' '}
        <span id="countDown" >
          {count}
        </span>
        {' '}{t('seconds.')}
        <br />
        {t('Or')}{' '}
        <a
          className="testlink"
          tabIndex="0"
          role="button"
          onClick={reLogin}
        >
          {t('click here')}{' '}
        </a>
        {' '}{t('to re-login.')}
      </p>
    </div>
  );
};

AccessDenied.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default AccessDenied;
