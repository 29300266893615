import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';


const FooterComponent = props => (
  <div className={styles.footer}>
    <div className={props.support ? 'support-footer-container' : 'main-footer-container'}>
      <span className="footerCopyright">
        Powered by<img className="footer-logo" src="/tenants/default/img/logo_default.png" alt="logo" />
      </span>
    </div>
  </div>
);
export default FooterComponent;

FooterComponent.propTypes = {
  support: PropTypes.bool,
};

FooterComponent.defaultProps = {
  support: false,
};
