import React from 'react';
import PropTypes from 'prop-types';
import {
  Route,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner } from '@vibrent/electryon';
import userIsAuthenticated from '../utils/authentication';
import * as style from '../components/logout/style.scss';

/**
 * FullView
 * @param {Object} props
 */
const FullView = (props) => {
  const ChildComponent = props.authenticated ?
    userIsAuthenticated(props.component) : props.component;

  const userDetail = props.userDetail;
  const injectedPermissionAndRole =
    childProps =>
      (<ChildComponent {...childProps} userDetail={userDetail} />);
  return (
    ((props.numberModuleWillBeLoad > 0) || props.getProgramDetails || props.requesting) ?
      <Spinner className={style['log-out-spinner-container']} /> :
      <Route exact={props.exact} path={props.path} component={injectedPermissionAndRole} />
  );
};
/**
 * mapStateToProps
 * in mapStateToProps() we specify exactly which slice of the state
 *  we want to provide to our component
 * @param  {[object]} state
 * @return {[object]}
 */
const mapStateToProps = state => ({
  userDetail: state.UserDetail.get('userDetail'),
  roleSelected: state.UserDetail.get('roleSelected'),
  numberModuleWillBeLoad: state.ModuleLoader.get('numberModuleWillBeLoad'),
  getProgramDetails: state.LoginReducer.get('getProgramDetails'),
  requesting: state.ModuleLoader.get('requesting'),
});
// export connect for FullView
export default connect(
  mapStateToProps,
  null,
)(FullView);

// define props types for FullView component
// it include
FullView.propTypes = {
  // exact
  exact: PropTypes.bool,
  // authenticated
  authenticated: PropTypes.bool,
  // component
  component: PropTypes.func.isRequired,
  // path
  path: PropTypes.string,
  // userDetail
  userDetail: PropTypes.shape({}),
  numberModuleWillBeLoad: PropTypes.number,
  getProgramDetails: PropTypes.bool,
  requesting: PropTypes.bool,
};
// define default props for this component
FullView.defaultProps = {
  // exact is false
  exact: false,
  // authenticated
  authenticated: false,
  // path
  path: undefined,
  // userDetail
  userDetail: undefined,
  // roleSelected
  roleSelected: undefined,
  numberModuleWillBeLoad: -1,
  getProgramDetails: false,
  requesting: false,
};
