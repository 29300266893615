import { RequestUtil } from 'pmt-common';
import * as CONSTANT from './constant';

export const startAdhocCampaignNotification = () => ({
  type: CONSTANT.GET_ADHOC_CAMPAIGN_NOTIFICATION_START,
});

export const getAdhocCampaignNotificationSuccess = response => ({
  type: CONSTANT.GET_ADHOC_CAMPAIGN_NOTIFICATION_SUCCESS,
  data: response,
});

export const getAdhocCampaignNotificationFailure = error => ({
  type: CONSTANT.GET_ADHOC_CAMPAIGN_NOTIFICATION_FAILURE,
  error,
});

export const getAdhocCampaignNotification = () => async (dispatch) => {
  try {
    dispatch(startAdhocCampaignNotification());
    const url = CONSTANT.API_GET_ADHOC_CAMPAIGN_NOTIFICATION;
    const response = await RequestUtil.getData(url);

    dispatch(getAdhocCampaignNotificationSuccess(response));
  } catch (ex) {
    dispatch(getAdhocCampaignNotificationFailure(ex));
  }
};
