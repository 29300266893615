// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-coreModules-authentication-views-styles__main-container--2pchy0mm{padding:64px 16px 12px 16px;transform:none;width:calc(100% - 48px);margin:0;margin-left:48px;overflow-x:hidden;transition:width 300ms ease-out,margin-left 300ms ease-out}.src-coreModules-authentication-views-styles__main-container-support--OwA6XXfe{margin:0 auto;width:calc(100% - 120px);transform:none;margin-top:64px}.src-coreModules-authentication-views-styles__main-container-support--OwA6XXfe label[class*=checkbox] .checkmark{top:3px;width:14px;height:14px;border:1px solid #4a4a4a}", ""]);
// Exports
exports.locals = {
	"main-container": "src-coreModules-authentication-views-styles__main-container--2pchy0mm",
	"main-container-support": "src-coreModules-authentication-views-styles__main-container-support--OwA6XXfe"
};
module.exports = exports;
