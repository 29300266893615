import { reducer as formReducer } from 'redux-form';
import { reducers as componentsReducer, appComponents } from './components';
// import component from reducers
import appReducer from './reducers';

// Export components
export const components = appComponents;
// Export reducers
export const reducers =
  Object.assign({}, appReducer, componentsReducer, { form: formReducer });
