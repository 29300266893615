import { Map as MapImmutable } from 'immutable';
import {
  UPDATE_USER_PROFILE_START,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
  UPDATE_USER_DETAILS,
} from './events';

const initialState = new MapImmutable({
  updatingProfile: false,
  profileUpdated: false,
  userData: {},
});

const actionHandlers = {};

// update user details
actionHandlers[UPDATE_USER_DETAILS] = (state, action) => state
  .set('userData', action.userData);

/**
 * UPDATE_USER_PROFILE_START
 * @param {Object} state Current state
 * @return {Object} Next state
 */
actionHandlers[UPDATE_USER_PROFILE_START] = state => state
  .set('updatingProfile', true)
  .set('profileUpdated', false)
  .delete('error');
/**
 * UPDATE_USER_PROFILE_SUCCESS
 * @param {Object} state Current state
 * @return {Object} Next state
 */
actionHandlers[UPDATE_USER_PROFILE_SUCCESS] = state => state
  .set('updatingProfile', false)
  .set('profileUpdated', true)
  .delete('error');
/**
 * UPDATE_USER_PROFILE_FAILURE
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[UPDATE_USER_PROFILE_FAILURE] = (state, action) => state
  .set('updatingProfile', false)
  .set('profileUpdated', false)
  .set('error', action.error);

/**
 * Action Handlers
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
export default (state = initialState, action) => {
  if (actionHandlers[action.type] !== undefined) {
    const nextState = actionHandlers[action.type](state, action);
    if (nextState === null || nextState === undefined) {
      return state;
    }
    return nextState;
  }
  return state;
};
