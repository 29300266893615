// SET_USER_DETAIL
export const SET_USER_DETAIL = 'SET_USER_DETAIL';
export const CLEAR_USER_DETAIL = 'CLEAR_USER_DETAIL';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_CURRENT_ACTIVE_PROGRAM = 'SET_CURRENT_ACTIVE_PROGRAM';
export const SET_USER_PERMISSION_WITHOUT_REFRESH = 'SET_USER_PERMISSION_WITHOUT_REFRESH';
export const SET_PROGRAM_LOGO_AND_ALTTEXT = 'SET_PROGRAM_LOGO_AND_ALTTEXT';

// calendar actions
export const RESET_SELECTED_MULTI_SITE = 'RESET_SELECTED_MULTI_SITE';
export const RESET_SELECTED_SITE = 'RESET_SELECTED_SITE';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const CHANGE_VIEW_MODE = 'CHANGE_VIEW_MODE';

// Search STATE clear
export const FORCE_CLEAR_ADVANCED_SEARCH_STATE = 'FORCE_CLEAR_ADVANCED_SEARCH_STATE';

// Org Nodes
export const GET_NODES_START = 'GET_NODES_START';
export const GET_NODES_SUCCESS = 'GET_NODES_SUCCESS';
export const GET_NODES_FAILURE = 'GET_NODES_FAILURE';

export const PROGRAM_SWITCH_START = 'PROGRAM_SWITCH_START';
export const PROGRAM_SWITCH_SUCCESS = 'PROGRAM_SWITCH_SUCCESS';
export const PROGRAM_SWITCH_FAILURE = 'PROGRAM_SWITCH_FAILURE';

export const UPDATE_USER_LANGUAGE_START = 'UPDATE_USER_LANGUAGE_START';
export const UPDATE_USER_LANGUAGE_SUCCESS = 'UPDATE_USER_LANGUAGE_SUCCESS';
export const UPDATE_USER_LANGUAGE_FAILURE = 'UPDATE_USER_LANGUAGE_FAILURE';

// GET_HELP_CENTER_DETAILS
export const GET_HELP_CENTER_DETAILS_START = 'GET_HELP_CENTER_DETAILS_START';
export const GET_HELP_CENTER_DETAILS_SUCCESS = 'GET_HELP_CENTER_DETAILS_SUCCESS';
export const GET_HELP_CENTER_DETAILS_FAILURE = 'GET_HELP_CENTER_DETAILS_FAILURE';
export const COLLAPSE_NAVBAR = 'COLLAPSE_NAVBAR';

export default {};
