import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@vibrent/electryon';
import moment from 'moment';
import * as actions from './actions';
import * as userActions from '../../actions/userActions';

const Login = (props) => {
  const { location: { search },
    actions: { getNodes, storeUserDetail,
      getHelpCentreDetails },
    history, setUserDetails, userDetail, keyCloakConfig, requestingNodes,
    isCheckingSession } = props;
  const { i18n } = useTranslation();
  const [error, setError] = useState(null);
  const loadProgramData = (user) => {
    storeUserDetail(user);
    if (!requestingNodes) {
      getNodes({ ...user, loginTime: Date.now() });
      if (user.jwtToken && setUserDetails) {
        getHelpCentreDetails(user.jwtToken, true);
      }
    }
  };

  // only runs on mount
  useEffect(() => {
    window.sessionStorage.clear();
    if (!userDetail || !keyCloakConfig) {
      props.actions.getKeyCloakURL();
    }
  }, []);

  useEffect(() => {
    if (keyCloakConfig && !setUserDetails) {
      const keycloak = window.Keycloak(keyCloakConfig);
      keycloak.init({ onLoad: 'login-required', promiseType: 'native', flow: 'standard' }).then(() => {
        if (keycloak && 'token' in keycloak && keycloak.authenticated) {
          const decodedURL = decodeURIComponent(search);
          const newURL = decodedURL.replaceAll('?', '&');
          const URLSearch = new URLSearchParams(newURL);
          props.actions.getUserInfoDetails(keycloak.token,
            keycloak.idToken, keycloak.refreshToken, URLSearch.get('program'));
        }
      });
    }
  }, [keyCloakConfig]);

  useEffect(() => {
    if (!isCheckingSession && !userDetail) {
      props.actions.checkLogin();
    }
  }, [setUserDetails]);

  // can login
  useEffect(() => {
    if (!_.isEmpty(userDetail)) {
      loadProgramData(userDetail);
      i18n.changeLanguage(userDetail.preferredLanguage.language);
      window.localStorage.setItem('userLocale', userDetail.preferredLanguage.language);
      moment.locale(userDetail.preferredLanguage.locale);
    }
  }, [userDetail]);

  useEffect(() => {
    if (userDetail) {
      const redirect = (new URLSearchParams(search)).get('redirect');
      history.replace(redirect || '/');
    }
  }, [userDetail]);

  useEffect(() => {
    // Update state error
    if (props.error && !_.isEqual(error, props.error.message)) {
      setError({ error: props.error.message });
    }
  }, [props.error]);

  return isCheckingSession ? <Spinner /> : null;
};

const mapStateToProps = state => ({
  isCheckingSession: state.LoginReducer.get('isCheckingSession'),
  error: state.LoginReducer.get('error'),
  keyCloakConfig: state.LoginReducer.get('keyCloakConfig'),
  userDetail: state.UserDetail.get('userDetail') || state.LoginReducer.get('userDetail'),
  setUserDetails: state.LoginReducer.get('setUserDetails'),
  requestingNodes: state.UserDetail.get('requestingNodes'),
});

const mapDispatchToProps = dispatch => ({
  actions:
    bindActionCreators(
      Object.assign({}, actions, userActions),
      dispatch,
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);

export const LoginComponent = Login;

Login.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    checkLogin: PropTypes.func.isRequired,
    storeUserDetail: PropTypes.func.isRequired,
    getHelpCentreDetails: PropTypes.func.isRequired,
    getKeyCloakURL: PropTypes.func.isRequired,
    userDetail: PropTypes.func.isRequired,
    getUserInfoDetails: PropTypes.func.isRequired,
    getNodes: PropTypes.func.isRequired,
  }).isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  keyCloakConfig: PropTypes.shape({
    resource: PropTypes.string,
    realm: PropTypes.string,
    url: PropTypes.string,
    credentials: PropTypes.shape({
      secret: PropTypes.string,
    }),
  }),
  setUserDetails: PropTypes.bool.isRequired,
  isCheckingSession: PropTypes.bool.isRequired,
  requestingNodes: PropTypes.bool.isRequired,
  userDetail: PropTypes.shape({
    preferredLanguage: PropTypes.string.isRequired,
  }),
};

Login.defaultProps = {
  error: null,
  keyCloakConfig: null,
  userDetail: null,
  setUserDetails: false,
  getProgramDetails: false,
};
