import fetch from 'isomorphic-fetch';
import _ from 'lodash';
import {
  SET_LAST_TIME_PING_SERVER,
} from '../components/sessionRefresher/events';

export const BEARER = 'Bearer';
export const storeLastTimePingServer = () => ({
  type: SET_LAST_TIME_PING_SERVER,
  time: (new Date()).getTime(),
});

/**
 * Export default
 * @param {string} url
 * @param {Object} params
 * @return {Object}
 */
export default store => (url, params, skipProgramId) => {
  const locale = _.get(store.getState().UserDetail.get('userDetail'), 'preferredLanguage', 'en-us');
  const headers = params.headers || {};
  headers['Accept-Language'] = locale.locale;
  const token = _.get(store.getState().UserDetail.get('userDetail'), 'jwtToken', '');
  const programId = _.get(store.getState().UserDetail.get('currentActiveProgram'), 'programId', '');
  if (programId && !skipProgramId) {
    headers.programId = programId;
  }
  if (!headers.Authorization) {
    headers['Cache-Control'] = 'no-cache, no-store, must-revalidate';
    headers.Pragma = 'no-cache';
  }
  if (token && !headers.Authorization) {
    headers.Authorization = `${BEARER} ${token}`;
  }
  return fetch(url, { ...params, headers }).then((res) => {
    // Check if status is 403
    // Then replace redirect
    if (res.status === 403) {
      window.location.replace(`/sessionExpired?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`);
      return res;
    }
    if (res.status === 501) {
      window.location.replace('/accessDenied');
      return res;
    }
    store.dispatch(storeLastTimePingServer());
    return res;
  });
};
