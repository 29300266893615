import { RequestUtil } from 'pmt-common';
import i18n from '../../../../i18n';
import {
  GET_NOTIFICATIONS_START,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  GET_TOTAL_NOTIFICATIONS_START,
  GET_TOTAL_NOTIFICATIONS_SUCCESS,
  SAVE_NOTIFICATION_STATUS_START,
  SAVE_NOTIFICATION_STATUS_SUCCESS,
  SAVE_NOTIFICATION_STATUS_FAILURE,
  SET_VIEW_APPOINTMENT_PARAMS,
  CHANGE_SELECTED_SITE,
} from './events';

import {
  API_GET_NOTIFICATIONS,
  API_GET_TOTAL_NOTIFICATIONS,
  API_SAVE_NOTIFICATION_STATUS,
  NOTIFICATION_TYPES,
} from '../../constants';
/**
 * GET_NOTIFICATIONS_START
 * @return Object
 */
export const getNotificationStart = (timeType, status) => ({
  type: GET_NOTIFICATIONS_START,
  timeType,
  status,
});
/**
 * GET_NOTIFICATIONS_SUCCESS
 * @param {Object} response
 * @return Object
 */
export const getNotificationSuccess = response => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  response,
});
/**
 * GET_NOTIFICATIONS_FAILURE
 * @param {Object} error
 * @return Object
 */
export const getNotificationFailure = error => ({
  type: GET_NOTIFICATIONS_FAILURE,
  error,
});

/**
 * API_GET_NOTIFICATIONS
 * @param {Number} offset
 * @param {Number} limit
 * @return {Promise}
 */
export const getNotification =
(status, timeType, startTime = 0, endTime, offset = 0, limit = 10) =>
  async (dispatch) => {
    const endTimeOfDay = new Date();
    endTimeOfDay.setHours(23, 59, 59, 999);

    try {
      dispatch(getNotificationStart(timeType, status));
      const params = {
        status,
        from: startTime,
        to: endTime,
        endTimeOfDay: endTimeOfDay.valueOf(),
        offset,
        limit,
      };
      const url = RequestUtil.getParam(API_GET_NOTIFICATIONS, params);
      const response = await window.fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }).then((res) => {
        if (res.status >= 400) {
          // Check if server return bad response
          // Then throw error
          throw new Error(i18n.t(NOTIFICATION_TYPES.ERROR_MESSAGE_FROM_SERVER));
        }
        return res.json();
      });
      // Success
      dispatch(getNotificationSuccess({ ...response, timeType, status }));
    } catch (e) {
      // Dispatch to failure
      dispatch(getNotificationFailure(e));
    }
  };
/**
 * GET_TOTAL_NOTIFICATIONS_SUCCESS
 * @return Object
 */
export const getTotalNotificationsStart = () => ({
  type: GET_TOTAL_NOTIFICATIONS_START,
});

/**
 * GET_TOTAL_NOTIFICATIONS_SUCCESS
 * @return Object
 */
export const getTotalNotificationsSuccess = total => ({
  type: GET_TOTAL_NOTIFICATIONS_SUCCESS,
  total,
});

export const getTotalNotifications = () =>
  async (dispatch) => {
    dispatch(getTotalNotificationsStart());
    const time = new Date();
    try {
      const params = {
        endTimeOfDay: time.setHours(23, 59, 59, 999).valueOf(),
      };
      const url = RequestUtil.getParam(API_GET_TOTAL_NOTIFICATIONS, params);
      const response = await window.fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }).then((res) => {
        if (res.status >= 400) {
          // Check if server return bad response
          // Then throw error
          throw new Error(i18n.t(NOTIFICATION_TYPES.ERROR_MESSAGE_FROM_SERVER));
        }
        return res.text();
      });
      // Success
      dispatch(getTotalNotificationsSuccess(response));
    } catch (e) {
      // Dispatch to failure
      dispatch(getTotalNotificationsSuccess(0));
    }
  };


/**
 * SAVE_NOTIFICATION_STATUS_START
 * @return Object
 */
export const saveNotificationStatusStart = () => ({
  type: SAVE_NOTIFICATION_STATUS_START,
});
/**
 * SAVE_NOTIFICATION_STATUS_SUCCESS
 * @param {Object} response
 * @return Object
 */
export const saveNotificationStatusSuccess = response => ({
  type: SAVE_NOTIFICATION_STATUS_SUCCESS,
  response,
});
/**
 * SAVE_NOTIFICATION_STATUS_FAILURE
 * @param {Object} error
 * @return Object
 */
export const saveNotificationStatusFailure = error => ({
  type: SAVE_NOTIFICATION_STATUS_FAILURE,
  error,
});

/**
 * API_SAVE_NOTIFICATION_STATUS
 * @param {string} id
 * @param {string} notificationType
 */
export const saveNotificationStatus = async ({ id, notificationType,
  from, to, status, oldStatus, dateTime, typeSection, endTimeOfDate }) => async (dispatch) => {
  try {
    // saveNotificationStatusStart
    dispatch(saveNotificationStatusStart());
    const url = API_SAVE_NOTIFICATION_STATUS;
    const response = await window.fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        id,
        notificationType,
        from,
        to,
        status,
        oldStatus,
        dateTime,
        endTimeOfDate,
      }),
    }).then((res) => {
      // Check if server return bad response
      // Then throw error
      if (res.status >= 400) {
        throw new Error(i18n.t(NOTIFICATION_TYPES.ERROR_MESSAGE_FROM_SERVER));
      }
      return res.json();
    });
    // Success
    dispatch(getTotalNotifications());
    dispatch(saveNotificationStatusSuccess({ ...response, typeSection }));
  } catch (e) {
    // Dispatch to failure
    dispatch(saveNotificationStatusFailure(e));
  }
};

/**
 * Set appointment Id to show Appointment
 * @param {string} appointmentId the appointment id
 */
export const setViewAppointmentParams = appointmentId => ({
  type: SET_VIEW_APPOINTMENT_PARAMS,
  appointmentId,
});

/**
 * Set the site id beforce navigate page
 * @param {string} siteId the Site ID
 */
export const setSiteId = siteId => ({
  type: CHANGE_SELECTED_SITE,
  selectedId: siteId,
});
