// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-coreModules-authentication-components-common-coloredCheckbox-styles__colored-checkbox--3mGAtTjo .colored-checkbox-content{padding-top:0px;margin-bottom:8px;display:block;position:relative;padding-left:24px;cursor:pointer;font-size:inherit;user-select:none;text-align:left}.src-coreModules-authentication-components-common-coloredCheckbox-styles__colored-checkbox--3mGAtTjo .colored-checkbox-content input{position:absolute;width:0;height:0;opacity:0;cursor:pointer}.src-coreModules-authentication-components-common-coloredCheckbox-styles__colored-checkbox--3mGAtTjo .colored-checkbox-content input:checked~.colored-checkbox-checkmark:after{display:block}.src-coreModules-authentication-components-common-coloredCheckbox-styles__colored-checkbox--3mGAtTjo .colored-checkbox-content input:disabled~.colored-checkbox-checkmark{background-color:#f3f3f3;cursor:not-allowed}.src-coreModules-authentication-components-common-coloredCheckbox-styles__colored-checkbox--3mGAtTjo .colored-checkbox-content .colored-checkbox-checkmark{position:absolute;top:2px;left:0;height:16px;width:16px;background-color:#fff;border:1px solid #979797;border-radius:2px}.src-coreModules-authentication-components-common-coloredCheckbox-styles__colored-checkbox--3mGAtTjo .colored-checkbox-content .colored-checkbox-checkmark:after{content:\"\";position:absolute;display:none;left:4px;top:1px;width:5px;height:9px;border:solid #4a4a4a;border-width:0 2px 2px 0;transform:rotate(45deg)}", ""]);
// Exports
exports.locals = {
	"colored-checkbox": "src-coreModules-authentication-components-common-coloredCheckbox-styles__colored-checkbox--3mGAtTjo"
};
module.exports = exports;
