import { Component } from 'react';
import PropTypes from 'prop-types';
import { addMiddleware } from 'redux-dynamic-middlewares';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';

class ModuleLoader extends Component {
  constructor(props) {
    super(props);

    this.loadScript = (src) => {
      const timestamp = (new Date()).getTime();
      const tag = document.createElement('script');
      tag.async = false;
      tag.src = `/modules/${src}/static/js/main.js?timestamp=${timestamp}`;
      tag.onload = () => {
        this.props.actions.loadModuleSuccess(src);
      };
      tag.onerror = () => {
        this.loadScript(src);
        this.props.actions.loadModuleFailure(src);
      };
      document.getElementsByTagName('body')[0].appendChild(tag);
    };

    this.generateLoaderFunction = functionName => (module) => {
      this.props.store.replaceReducer(this.props.store.createReducer(module.reducers));
      // Add middlewares of module
      if (module.middlewares) {
        const moduleMiddlewares = _.values(module.middlewares);
        addMiddleware(...moduleMiddlewares);
      }
      // Add module
      this.props.actions.addModule(functionName, module);
      delete window[functionName];
    };
  }

  componentDidUpdate() {
    // Check if isLoading is true
    if (this.props.isLoading) {
      return;
    }
    // Check if have userDetail and moduleList
    // and error is false
    // and requesting is false
    if (this.props.userDetail && !this.props.moduleList
      && !this.props.error && !this.props.requesting) {
      // Then getModulesInformation
      this.props.actions.getModulesInformation();
    }
    // Check if have moduleList
    if (this.props.moduleList) {
      _.forEach(this.props.moduleList, (module) => {
        const functionName = `_${module.moduleName.replace(/\./g, '_')}`;
        window[functionName] = this.generateLoaderFunction(functionName);
        this.loadScript(module.moduleName);
      });
      // Call action loadModuleStart
      this.props.actions.loadModuleStart();
    }
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = dispatch => ({
  actions:
    bindActionCreators(
      actions,
      dispatch,
    ),
});

const mapStateToProps = state => ({
  userDetail: state.UserDetail.get('userDetail'),
  currentActiveProgram: state.UserDetail.get('currentActiveProgram'),
  moduleList: state.ModuleLoader.get('moduleList'),
  error: state.ModuleLoader.get('error'),
  requesting: state.ModuleLoader.get('requesting'),
  isLoading: state.ModuleLoader.get('isLoading'),
});

ModuleLoader.propTypes = {
  userDetail: PropTypes.shape({}),
  store: PropTypes.shape({
    replaceReducer: PropTypes.func.isRequired,
    createReducer: PropTypes.func.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    getModulesInformation: PropTypes.func.isRequired,
    addModule: PropTypes.func.isRequired,
    loadModuleStart: PropTypes.func.isRequired,
    loadModuleSuccess: PropTypes.func.isRequired,
    loadModuleFailure: PropTypes.func.isRequired,
  }).isRequired,
  moduleList: PropTypes.arrayOf(PropTypes.shape()),
  error: PropTypes.shape(),
  requesting: PropTypes.bool,
  isLoading: PropTypes.bool,
};

ModuleLoader.defaultProps = {
  userDetail: null,
  moduleList: undefined,
  error: undefined,
  requesting: undefined,
  isLoading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModuleLoader);

export const ModuleComponents = ModuleLoader;
