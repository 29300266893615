// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-coreModules-authentication-components-notificationsModal-components-notificationMenu-style__notification-menu--31Xz-Dyd .fa-ellipsis-h{font-size:20px}", ""]);
// Exports
exports.locals = {
	"notification-menu": "src-coreModules-authentication-components-notificationsModal-components-notificationMenu-style__notification-menu--31Xz-Dyd"
};
module.exports = exports;
