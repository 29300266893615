import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Spinner } from '@vibrent/electryon';
import * as actions from './actions';
import * as userActions from '../../actions/userActions';
import * as programActions from '../login/actions';
import style from './style.scss';

class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    this.props.actions.logout();
  }

  componentDidUpdate() {
    if (this.props.isLogout) {
      window.sessionStorage.clear();
      const redirect = (new URLSearchParams(this.props.location.search)).get('redirect');
      const loginURL = redirect ? `/login?redirect=${encodeURIComponent(redirect)}` : '/login';
      window.location.href = loginURL;
    }
  }

  render() {
    return (
      <Fragment>
        <Spinner className={style['log-out-spinner-container']} />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  requesting: state.LogoutReducer.get('requesting'),
  error: state.LogoutReducer.get('error'),
  isLogout: state.LogoutReducer.get('isLogout'),
});

const mapDispatchToProps = dispatch => ({
  actions:
    bindActionCreators(
      Object.assign({}, actions, userActions, programActions),
      dispatch,
    ),
});

Logout.propTypes = {
  isLogout: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    logout: PropTypes.func.isRequired,
    clearUserDetail: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Logout);
export const LogoutClass = Logout;
