import _MainView from './mainView';
import _WrapperView from './wrapperView';
import _FullView from './fullView';

// Export default MainView and WrapperView
export default {
  MainView: _MainView,
  WrapperView: _WrapperView,
  FullView: _FullView,
};
// Export  for MainView
export const MainView = _MainView;
// Export  for WrapperView
export const WrapperView = _WrapperView;
// Export  for FullView
export const FullView = _FullView;
