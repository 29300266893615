import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Constants } from '@vibrent/electryon';
import style from './styles.scss';


const LogoHolder = (props) => {
  const { currentActiveProgram, isPMI } = props;
  const { alternateText, programLogo } = currentActiveProgram || {};
  if (!_.isEmpty(currentActiveProgram)) {
    return (
      <Fragment>
        {alternateText && programLogo && isPMI ?
          <Link
            to="/"
            className={
              style.linkStyles}
          >
            <img
              alt={alternateText}
              height={32}
              src={programLogo}
              className={style.logoStyles}
            />
            <div className={style.pmtStyle}>
              {Constants.getAppName().toUpperCase()}
            </div>
          </Link> :
          <Link
            to="/"
            className={
              style.linkStylesRC}
          >
            <img
              alt={`Vibrent: ${Constants.getAppName()}`}
              height={32}
              src="/tenants/default/img/rc-logo.png"
              className={style.logoStylesRC}
            />
          </Link>}
      </Fragment>
    );
  }
  return null;
};
const mapStateToProps = state => ({
  currentActiveProgram: state.UserDetail.get('currentActiveProgram'),
});
// Export default connect
export default connect(
  mapStateToProps,
  null,
)(LogoHolder);

export const LogoHolderConnect = LogoHolder;
/**
* normalLogo - to show PMT branded logo or just the program logo (boolean)
* linkedLogo - Should be a clickable logo.
* programLogo - Logo of the program.
*/
LogoHolder.propTypes = {
  currentActiveProgram: PropTypes.shape({
    alternateText: PropTypes.string,
    programLogo: PropTypes.string,
  }),
  isPMI: PropTypes.bool.isRequired,
};

LogoHolder.defaultProps = {
  currentActiveProgram: null,
};
