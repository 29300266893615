import { Map as MapImmutable } from 'immutable';
import {
  SET_LAST_TIME_PING_SERVER,
  SET_LAST_TIME_INTERACT_UI,
  PING_SERVER_START,
  PING_SERVER_SUCCESS,
  PING_SERVER_FAILURE,
  IS_CATI_SESSION_ACTIVE_FAILURE,
  IS_CATI_SESSION_ACTIVE_SUCCESS,
  IS_CATI_SESSION_ACTIVE_START,
  IS_PARTICIPANT_PROFILE,
} from './events';

const initialState = new MapImmutable({});
const actionHandlers = {};
/**
 * SET_LAST_TIME_PING_SERVER
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[SET_LAST_TIME_PING_SERVER] = (state, action) =>
  state.set('lastTimePingServer', action.time);

/**
 * SET_LAST_TIME_PING_SERVER
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[SET_LAST_TIME_INTERACT_UI] = (state, action) =>
  state.set('lastTimeUpdatedUI', action.time);
/**
 * SET_LAST_TIME_PING_SERVER
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[PING_SERVER_START] = state =>
  state.set('requesting', true);
/**
 * SET_LAST_TIME_PING_SERVER
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[PING_SERVER_SUCCESS] = state =>
  state.set('requesting', false);
/**
 * SET_LAST_TIME_PING_SERVER
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[PING_SERVER_FAILURE] = state =>
  state.set('requesting', false);

actionHandlers[IS_CATI_SESSION_ACTIVE_START] = state => state
  .set('isCATIActive', true)
  .set('requesting', true);
/**
 * SET_LAST_TIME_PING_SERVER
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[IS_CATI_SESSION_ACTIVE_SUCCESS] = (state, action) => state
  .set('isCATIActive', action.response.Status)
  .set('requesting', false);
/**
 * SET_LAST_TIME_PING_SERVER
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[IS_CATI_SESSION_ACTIVE_FAILURE] = state => state
  .set('isCATIActive', true)
  .set('requesting', false);

actionHandlers[IS_PARTICIPANT_PROFILE] = (state, action) => state
  .set('isParticipantProfile', action.response);

/**
 * Action Handlers
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
export default (state = initialState, action) => {
  if (actionHandlers[action.type] !== undefined) {
    const nextState = actionHandlers[action.type](state, action);
    if (nextState === null || nextState === undefined) {
      return state;
    }
    return nextState;
  }
  return state;
};
