import { Map as MapImmutable } from 'immutable';
import {
  PROTOCOL_BUILDER_REQUEST_START,
  PROTOCOL_BUILDER_REQUEST_SUCCESS,
  PROTOCOL_BUILDER_REQUEST_FAILURE,
} from './events';

const initialState = new MapImmutable({
  sendingRequest: false,
  requestSent: false,
});

const actionHandlers = {};

/**
 * PROTOCOL_BUILDER_REQUEST_START
 * @param {Object} state Current state
 * @return {Object} Next state
 */
actionHandlers[PROTOCOL_BUILDER_REQUEST_START] = state => state
  .set('sendingRequest', true)
  .set('requestSent', false)
  .delete('error');
/**
 * PROTOCOL_BUILDER_REQUEST_SUCCESS
 * @param {Object} state Current state
 * @return {Object} Next state
 */
actionHandlers[PROTOCOL_BUILDER_REQUEST_SUCCESS] = state => state
  .set('sendingRequest', false)
  .set('requestSent', true)
  .delete('error');
/**
 * PROTOCOL_BUILDER_REQUEST_FAILURE
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[PROTOCOL_BUILDER_REQUEST_FAILURE] = (state, action) => state
  .set('sendingRequest', false)
  .set('requestSent', false)
  .set('error', action.error);

/**
 * Action Handlers
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
export default (state = initialState, action) => {
  if (actionHandlers[action.type] !== undefined) {
    const nextState = actionHandlers[action.type](state, action);
    if (nextState === null || nextState === undefined) {
      return state;
    }
    return nextState;
  }
  return state;
};
