
import i18n from '../../i18n';
import {
  GET_MODULE_INFORMATION_START,
  GET_MODULE_INFORMATION_SUCCESS,
  GET_MODULE_INFORMATION_FAILURE,
  LOAD_MODULE_START,
  LOAD_MODULE_SUCCESS,
  LOAD_MODULE_FAILURE,
  ADD_MODULE,
} from './events';
import { NOTIFICATION_TYPES } from '../authentication/constants';
import {
  MODULES_INFORMATION_APIS,
} from './constants';
/**
 * GET_MODULE_INFORMATION_START
 * @return Object
 */
export const startGetModule = () => ({
  type: GET_MODULE_INFORMATION_START,
});
/**
 * GET_MODULE_INFORMATION_SUCCESS
 * @param {Object} response
 * @return Object
 */
export const getModuleSuccess = response => ({
  type: GET_MODULE_INFORMATION_SUCCESS,
  response,
});
/**
 * GET_MODULE_INFORMATION_FAILURE
 * @param {Object} error
 * @return Object
 */
export const getModuleFailure = error => ({
  type: GET_MODULE_INFORMATION_FAILURE,
  error,
});
/**
 * MODULES_INFORMATION_APIS
 * @return {Promise}
 */
export const getModulesInformation = async () =>
  async (dispatch) => {
    try {
      // startGetModule
      dispatch(startGetModule());
      const url = MODULES_INFORMATION_APIS;
      const response = await window.fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }).then((res) => {
        if (res.status >= 400) {
          // Check if server return bad response
          // Then throw error
          throw new Error(i18n.t(NOTIFICATION_TYPES.ERROR_MESSAGE_FROM_SERVER));
        }
        return res.json();
      });
      // Success
      dispatch(getModuleSuccess(response));
    } catch (e) {
      // Dispatch to failure
      dispatch(getModuleFailure(e));
    }
  };

  /**
   * LOAD_MODULE_START
   * @param {Object} module
   * @return Object
   */
export const loadModuleStart = module => ({
  type: LOAD_MODULE_START,
  module,
});
/**
 * LOAD_MODULE_SUCCESS
 * @param {Object} moduleName
 * @return Object
 */
export const loadModuleSuccess = moduleName => ({
  type: LOAD_MODULE_SUCCESS,
  moduleName,
});
/**
 * LOAD_MODULE_FAILURE
 * @param {Object} moduleName
 * @return Object
 */
export const loadModuleFailure = moduleName => ({
  type: LOAD_MODULE_FAILURE,
  moduleName,
});
/**
 * addModule
 * @param {Object} module
 * @param {Object} moduleName
 * @return Object
 */
export const addModule = (moduleName, module) => ({
  type: ADD_MODULE,
  moduleName,
  module,
});

export default { getModulesInformation };
