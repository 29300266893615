export const LOGIN_RESPONSE_CODE = {
  AUTH_ERROR_MFA_SETUP_REQUIRED: 15,
  AUTH_ERROR_MFA_REQUIRED: 16,
};
// API
export const API_LOGIN = '/api/login';
export const API_CHANGE_PASSWORD = '/api/changePassword';
export const API_LOGOUT = '/api/logout';
export const API_FORGOT_PASSWORD = '/api/forgotPassword';
export const API_RESET_PASSWORD = '/api/resetPassword';
export const API_GET_NOTIFICATIONS = '/api/notifications';
export const API_GET_TOTAL_NOTIFICATIONS = '/api/totalNotifications';
export const API_SAVE_NOTIFICATION_STATUS = '/api/saveNotificationStatus';
export const API_PING_SERVER = '/api/ping';
export const API_CATI_SESSION = '/api/cati/catiSessionStatus';
export const API_KEYCLOAK_URL = '/api/getKeycloakURL';
export const GET_USER_DETAIL_API = '/api/setUserInfo';
export const GET_PROGRAM_CONFIG_API = '/api/v1/programdetails?programId=';
export const API_GET_ROLE_PERMISSIONS = '/api/rolePermissions';
export const API_GET_NODES = '/api/nodes/getUserNodesByRequest';
export const API_PROGRAM_SWITCH = '/api/user/programswitch/{programId}';
export const API_UPDATE_USER_LANGUAGE = '/api/updateUserLocale';
export const API_GET_HELP_CENTER_DETAILS = '/api/helpcenter/getUrlAndStatus';

// NOTIFICATION_TYPES
export const NOTIFICATION_TYPES = {
  // APPOINTMENT
  NEW_APPOINTMENT: 'New {appointmentType} appointment scheduled at {locationName} on {appointmentCreatedAt}',
  RESCHEDULED_APPOINTMENT: 'Appointment rescheduled at {locationName} on {appointmentCreatedAt}',
  CANCEL_APPOINTMENT: 'Appointment canceled at {locationName}',

  NEW_APPOINTMENT_KEY: 'NEW_APPOINTMENT',
  RESCHEDULED_APPOINTMENT_KEY: 'RESCHEDULED_APPOINTMENT',
  CANCEL_APPOINTMENT_KEY: 'CANCEL_APPOINTMENT',
  APPOINTMENT_ASSIGN_KEY: 'APPOINTMENT_ASSIGN',
  APPOINTMENT_UNASSIGN_KEY: 'APPOINTMENT_UNASSIGN',
  APPOINTMENT_ASSIGN: 'You have been assigned to an appointment at {locationName}',
  APPOINTMENT_UNASSIGN: 'You have been unassigned from an appointment at {locationName}',

  LINKED_PARTICIPANT_APPOINTMENT_KEY: 'LINKED_PARTICIPANT_APPOINTMENT',
  LINKED_PARTICIPANT_APPOINTMENT: '{profileName} has an upcoming appointment on a duplicate account. View details to cancel the appointment.',

  // QUOTA
  QUOTA_TARGET: "{locationName}'s {frequency} target has been changed to {newValue}",
  QUOTA_GOAL: "{locationName}'s {frequency} goal has been changed to {newValue}",
  QUOTA_LOCK: "{locationName}'s {frequency} target has been {locked}",

  QUOTA_TARGET_KEY: 'QUOTA_TARGET',
  QUOTA_GOAL_KEY: 'QUOTA_GOAL',
  QUOTA_LOCK_KEY: 'QUOTA_LOCK',
  ERROR_MESSAGE_FROM_SERVER: 'There was a problem processing your request. Please try again.',
  DO_NOT_MATCH: 'The values entered do not match.',

  // REQUEST_TO_PUBLISH
  REQUESTING_PUBLISH_NODE: '{userName} has requested to Publish {recordName}',
  REQUEST_TO_PUBLISH: 'REQUESTING_PUBLISH_NODE',

  // CAMPAIGN
  CAMPAIGN_COMPLETED_KEY: 'CAMPAIGN_COMPLETED',
  CAMPAIGN_FAILED_TO_SEND_KEY: 'CAMPAIGN_FAILED_TO_SEND',
  CAMPAIGN_COMPLETED: '{campaignName} campaign has been completed',
  CAMPAIGN_FAILED_TO_SEND: '{campaignName} campaign has failed to send',
  PUBLISHED_NODE: '{recordUpdateOrNewRecord} {levelName} - {recordName} has been published.',
  PUBLISHED_NODE_TYPE: 'PUBLISHED_NODE',

  // CASE_MANAGEMENT
  CASE_MANAGEMENT_ASSIGN_KEY: 'CASE_MANAGEMENT_ASSIGN',
  CASE_MANAGEMENT_ASSIGN: '{name} 1-on-1 Engagement List has been assigned to you.',
  CASE_MANAGEMENT_REMOVE_ASSIGN_KEY: 'CASE_MANAGEMENT_REMOVE_ASSIGN',
  CASE_MANAGEMENT_REMOVE_ASSIGN: '{name} 1-on-1 Engagement List is no longer assigned to you',
  CASE_MANAGEMENT_RECONTACT_KEY: 'CASE_MANAGEMENT_RECONTACT',
  CASE_MANAGEMENT_RECONTACT: 'Follow up reminder to recontact {firstName} {lastName} {verb} {date} {time}',
  // ONE_OFF_MESSAGE
  ONE_OFF_MESSAGE_COMPLETED_KEY: 'ONE_OFF_MESSAGE_COMPLETED',
  ONE_OFF_MESSAGE_COMPLETED: 'The one-off message was successfully sent to {audienceFullName}.',
  ONE_OFF_MESSAGE_FAILED_TO_SEND_KEY: 'ONE_OFF_MESSAGE_FAILED_TO_SEND',
  ONE_OFF_MESSAGE_FAILED_TO_SEND: 'The one-off message failed to send to {audienceFullName}. Click this link to try again or contact Support Center for additional support',
  // EXPORT REPORT
  USER_EXPORT_REPORT_GENERATED_KEY: 'USER_EXPORT_REPORT_GENERATED',
  USER_EXPORT_REPORT_GENERATED: '{fileName} export is now complete. It is available to download and/or resubmit.',
};

export const NOTIFICATION_STATUS = {
  READ: 'READ',
  UNREAD: 'UNREAD',
  DELETED: 'DELETED',
};

// ROLE_NAME
export const ROLE_NAME = {
  ROLE_MC_SITE_MANAGER: 'ROLE_MC_SITE_MANAGER',
  SUPPORT_STAFF: 'ROLE_MC_SUPPORT_STAFF',
};

// Local Storage
export const USER_ID = 'user_id';
export const VIBRENT_USER = 'vibrent_user';

export const APPOINTMENT_URL = '/scheduling/calendar/scheduler/view';
export const QUOTA_URL = '/capacity/quota';
export const QUOTA_URL_RC = '/scheduling/quota';
export const SETTING_URL = '/scheduling/settings/scheduling';
export const HIERARCHY_INSTANCE_URL = '/hierarchy/instance';
export const HIERARCHY_INSTANCE_EDIT_URL = `${HIERARCHY_INSTANCE_URL}/edit`;
export const CAMPAIGN_URL = '/communications/campaigns';
export const CASE_MANAGEMENT_URL = '/communications/caseManagement';
export const SEARCH_URL = '/search';
export const PROSPECT_MANAGEMENT_VIEW_URL = '/prospectManagement/view';
export const SEARCH_NEW_ONE_OFF_TEMPLATE_URL = '/search/oneOffTemplate';
export const PROSPECT_NEW_ONE_OFF_TEMPLATE_URL = '/prospectManagement/oneOffTemplate';
export const REPORTS_LIST_URL = '/dataExplorer/reportsList';

export const HTTP_NOT_FOUND_RESPONSE = {
  CODE: 404,
  MESSAGE: 'Cannot reach the server, please try again in a few minutes.',
};

// Permissions
export const PERMISSIONS = {
  Scheduling: {
    // CALENDAR
    CALENDAR: '/scheduling/schedule/calendar/*',
    // CONFIG
    CONFIG: '/scheduling/availability/*',
    // DASHBOARD
    DASHBOARD: '/scheduling/schedule/dashboard/*',
    // SEARCH
    SEARCH: '/scheduling/search/*',
    // REPORT_ENROLL
  },
  Settings: {
    SITE: '/settings/site/*',
    ORGANIZATION: '/settings/group/*',
  },
  Communication: {
    // CASE_MANAGEMENT
    CASE_MANAGEMENT: '/communications/caseManagement/view',
    CASE_MANAGEMENT_ALL: '/communications/caseManagement/',
  },
  prospectManagement: '/prospectManagement/*',
  adhocCampaign: '/communications/adhocCampaign/sendMessage',
};

export const DATETIME_FORMAT = {
  // Short date
  shortDate: 'MM/DD/YYYY',
  // shortTime
  shortTime: 'HH:mm',
  // shortTimeAMPM
  shortTimeAMPM: 'hh:mm A',
  // shortDayOfWeek
  shortDayOfWeek: 'ddd',
  // dayOfWeek
  dayOfWeek: 'dddd',
  // shortMonth
  shortMonth: 'MMMM YYYY',
  // date time format with AM PM
  dateTimeAMPM: 'MM/DD/YYYY hh:mm A',
  // full date time
  fullDateTime: 'ddd, MMM DD, YYYY [at] hh:mm A',
};

export const VIEW_MODE = {
  DAY: 'day',
};
