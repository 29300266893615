import moment from 'moment-timezone';
import { Constants } from './constants';

// Guess timezone of browser
const guessTimezone = () => (moment.tz.guess(Constants.IgnoreCachedTimezone));

const DateUtil = {
  /**
  * Format the date
  * @param  {Date|timestamp|moment} date
  * @param  {String} format The expected format
  * @return {String} The date with expected format
  */
  format(date, format) {
    return moment(date).format(format);
  },

  /**
   * Format the date in UTC format to expected format that in timezone
   * @param  {Date|timestamp|moment} date
   * @param  {String} format The expected format
   * @param  {String} timezone Timezone id
   * @return {String} The date in timezone with expected format
   */
  formatUTC(date, format, timezone) {
    return moment.utc(date).tz(timezone).format(format);
  },

  /**
   * Get date in timezone
   * @param  {Date} date
   * @param  {String} timezone
   * @return {Date} Date in timezone
   */
  getDateInTimezone(date, timezone = guessTimezone()) {
    return moment(date).tz(timezone);
  },

  /**
   * Get date in UTC.
   * @param {Date} date
   * @return {Date} Date in UTC mode
   */
  utc(date) {
    return moment.utc(date);
  },
};

export default DateUtil;
