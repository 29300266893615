import _ from 'lodash';
import moment from 'moment';

/**
 * date function
 * @param {object} props
 * @param {string} propName
 * @param {string} componentName
 * @return error or null
 */
const date = (props, propName, componentName) => {
  const value = props[propName];
  // Checks if value is null or undefined.
  // if true return null
  if (_.isNil(value)) {
    return null;
  }
  // check value is date or moment or types equal string
  if (value instanceof Date || value instanceof moment || typeof value === 'string') {
    return null;
  }

  return new Error(`${componentName}.${propName} must be one of types(Date|moment|string)`);
};
// export date
export default {
  date,
};
