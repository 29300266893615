import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EventListener from 'react-event-listener';
import { bindActionCreators } from 'redux';
import * as actions from './actions';

/**
 * Refresher
 * @extends Component
 */
class SessionRefresher extends Component {
  /**
   * Constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.refreshSession = this.refreshSession.bind(this);
    setInterval(this.refreshSession, 1000);
    this.userInteractUIHandler = this.userInteractUIHandler.bind(this);
    this.isCustomInsightsBeingUsed = this.isCustomInsightsBeingUsed.bind(this);
    setInterval(this.isCustomInsightsBeingUsed, 300000);
  }

  /**
   * User interact UI handler
   */
  userInteractUIHandler() {
    const currentTime = (new Date()).getTime();
    if ((currentTime - this.props.lastTimeUpdatedUI) > 1000) {
      this.props.actions.setLastTimeInteractUI((new Date()).getTime());
    }
  }

  /**
   * Call Ping api to update the session if user is active inside Custom Insights (DX)
   */
  isCustomInsightsBeingUsed() {
    const userActive = window.localStorage.getItem('isUserActiveInDx');
    if (userActive && JSON.parse(userActive)) {
      this.props.actions.pingServer();
      window.localStorage.setItem('isUserActiveInDx', false);
    }
  }

  /**
   * Refresh session
   */
  refreshSession() {
    if (this.props.requesting) {
      return;
    }
    const currentTime = (new Date()).getTime();
    const { lastTimePingServer, lastTimeUpdatedUI } = this.props;
    const activeCatiSession = window.localStorage.getItem('activeCatiSession');
    if (JSON.parse(activeCatiSession)) {
      if ((this.props.userDetail && lastTimePingServer
        && ((currentTime - lastTimePingServer) > 60000))) {
        this.props.actions.getCatiSession();
      }
    } else if (this.props.userDetail && lastTimePingServer
        && ((currentTime - lastTimePingServer) > 60000)
      && (lastTimePingServer < lastTimeUpdatedUI)) {
      this.props.actions.pingServer();
    }
  }

  /**
   * Render
   */
  render() {
    return (<EventListener
      target="window"
      onMouseMoveCapture={this.userInteractUIHandler}
      onMouseUp={this.userInteractUIHandler}
      onMouseDown={this.userInteractUIHandler}
      onKeyUp={this.userInteractUIHandler}
      onKeyDown={this.userInteractUIHandler}
      onWheel={this.userInteractUIHandler}
    />);
  }
}
// mapStateToProps
const mapStateToProps = state => ({
  lastTimePingServer: state.SessionRefresherReducer.get('lastTimePingServer'),
  lastTimeUpdatedUI: state.SessionRefresherReducer.get('lastTimeUpdatedUI'),
  requesting: state.SessionRefresherReducer.get('requesting'),
  userDetail: state.UserDetail.get('userDetail'),
});
const mapDispatchToProps = dispatch => ({
  actions:
    bindActionCreators(
      Object.assign({}, actions),
      dispatch,
    ),
});
// Props types of Refresher
SessionRefresher.propTypes = {
  lastTimePingServer: PropTypes.number,
  lastTimeUpdatedUI: PropTypes.number,
  requesting: PropTypes.bool,
  actions: PropTypes.shape({
    setLastTimeInteractUI: PropTypes.func,
    pingServer: PropTypes.func,
    getCatiSession: PropTypes.func,
  }).isRequired,
  userDetail: PropTypes.shape({}),
};
// Default props of Refresher
SessionRefresher.defaultProps = {
  lastTimePingServer: null,
  lastTimeUpdatedUI: null,
  requesting: false,
  userDetail: null,
};
// Export default connect
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SessionRefresher);
// Export RefresherComponent
export const SessionRefresherComponent = SessionRefresher;
