import React from 'react';
import { PhoneNumberView } from '@vibrent/electryon';
import {
  MenuItem,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import {
  NOTIFICATION_TYPES,
  DATETIME_FORMAT,
} from '../../../../../../constants';
import {
  STATUS,
} from '../../../../constants';
import { DateUtil, ToolTipTextOverflow } from '../../../../../common';
import NotificationMenu, { NOTIFICATION_MENU_MODE } from '../../../notificationMenu';

import styles from './styles.scss';

const CLASS_NAME = styles.message;

const PARTICIPANT_WITH_TEXT = 'Participant with ';

const TYPE_ICON = {
  NEW_APPOINTMENT: 'fa-calendar',
  RESCHEDULED_APPOINTMENT: 'fa-calendar',
  CANCEL_APPOINTMENT: 'fa-calendar',
  APPOINTMENT_ASSIGN: 'fa-calendar',
  APPOINTMENT_UNASSIGN: 'fa-calendar',
  LINKED_PARTICIPANT_APPOINTMENT: 'fa-solid fa-clone',

  QUOTA_TARGET: 'fa-chart-pie',
  QUOTA_GOAL: 'fa-chart-pie',
  QUOTA_LOCK: 'fa-chart-pie',
  QUOTA_UNLOCK: 'fa-chart-pie',

  CAMPAIGN_COMPLETED: 'fa-bullseye',
  CAMPAIGN_FAILED_TO_SEND: 'fa-bullseye',

  PUBLISHED_NODE: 'fa fa-sitemap',
  REQUESTING_PUBLISH_NODE: 'fa fa-sitemap',

  CASE_MANAGEMENT_ASSIGN: 'fa-bullseye',
  CASE_MANAGEMENT_REMOVE_ASSIGN: 'fa-bullseye',
  CASE_MANAGEMENT_RECONTACT: 'fa-bullseye',
  ONE_OFF_MESSAGE_COMPLETED: 'fa-solid fa-paper-plane',
  ONE_OFF_MESSAGE_FAILED_TO_SEND: 'fa-solid fa-paper-plane',

  USER_EXPORT_REPORT_GENERATED: 'fa-file-export',
};

const TYPE_NOTIFICATION_NAME = {
  NEW_APPOINTMENT: 'Scheduling',
  RESCHEDULED_APPOINTMENT: 'Scheduling',
  CANCEL_APPOINTMENT: 'Scheduling',
  APPOINTMENT_ASSIGN: 'Scheduling',
  APPOINTMENT_UNASSIGN: 'Scheduling',
  LINKED_PARTICIPANT_APPOINTMENT: 'Scheduling',

  QUOTA_TARGET: 'Capacity Management',
  QUOTA_GOAL: 'Capacity Management',
  QUOTA_LOCK: 'Capacity Management',
  QUOTA_UNLOCK: 'Capacity Management',

  CAMPAIGN_COMPLETED: 'Communications',
  CAMPAIGN_FAILED_TO_SEND: 'Communications',

  PUBLISHED_NODE: 'Hierarchy Management',
  REQUESTING_PUBLISH_NODE: 'Hierarchy Management',

  CASE_MANAGEMENT_ASSIGN: 'Engagement',
  CASE_MANAGEMENT_REMOVE_ASSIGN: 'Engagement',
  CASE_MANAGEMENT_RECONTACT: 'Engagement',
  ONE_OFF_MESSAGE_COMPLETED: 'One off message',
  ONE_OFF_MESSAGE_FAILED_TO_SEND: 'One off message',

  USER_EXPORT_REPORT_GENERATED: 'Data Explorer',
};
/**
 * generate the second message for the notification
 * if the hours > 21 in recent section
 * should show 'x hour ago' intead  of 'a day ago'
 * @param  {moment} createDate
 * @return {Element}
 */
const getSubMessage = (createDate, t) => {
  const hourAgo = moment().diff(moment(createDate), 'hours');
  if (hourAgo > 20 && hourAgo < 24) {
    return (
      <i>{t('hours ago', ([hourAgo]))}</i>
    );
  }
  return (
    <i>{moment(createDate).fromNow()}</i>
  );
};
/**
 * Get content of the message
 * @return {String} Content of the message
 */

const getMessage = (notification, internationalPhoneSupportEnabled, t) => {
  let message = t(NOTIFICATION_TYPES[notification.notificationType]);
  // get options in options of notification
  const options = JSON.parse(notification.options);
  // Check if type of notification is QUOTA TARGET or QUOTA GOAL or QUOTA LOCK
  // Then replace group name and frequency
  if (
    notification.notificationType === NOTIFICATION_TYPES.QUOTA_TARGET_KEY ||
    notification.notificationType === NOTIFICATION_TYPES.QUOTA_GOAL_KEY ||
    notification.notificationType === NOTIFICATION_TYPES.QUOTA_LOCK_KEY
  ) {
    // custom message by the way replace {nodeName} to nodeName in notification
    // and {frequency} to frequency in options
    message = message
      .replace('{locationName}', _.get(options, 'locationName', ''))
      .replace('{frequency}', t(_.get(options, 'frequency', '')));
    // Check if type of notification is QUOTA TARGET or QUOTA GOAL
    // Then replace new value
    if (
      notification.notificationType === NOTIFICATION_TYPES.QUOTA_TARGET_KEY ||
      notification.notificationType === NOTIFICATION_TYPES.QUOTA_GOAL_KEY
    ) {
      // replace {newValue} to newValue in options
      message = message.replace('{newValue}', options.newValue);
    } else {
      // Check if type of notification is QUOTA LOCK
      // Then replace locked
      message = message.replace(
        '{locked}',
        options.newValue === 'true' ? t('locked') : t('unlocked')
      );
    }
  }
  if (notification.notificationType === NOTIFICATION_TYPES.REQUEST_TO_PUBLISH) {
    message = message
      .replace('{userName}', options.requestedUsername)
      .replace('{recordName}', options.nodeName);
  }
  if (notification.notificationType === NOTIFICATION_TYPES.PUBLISHED_NODE_TYPE) {
    message = message
      .replace('{recordUpdateOrNewRecord}', options.recordUpdateOrNewRecord)
      .replace('{levelName}', options.levelName)
      .replace('{recordName}', options.nodeName);
  }
  if (notification.notificationType === NOTIFICATION_TYPES.APPOINTMENT_ASSIGN_KEY ||
      notification.notificationType === NOTIFICATION_TYPES.APPOINTMENT_UNASSIGN_KEY) {
    message = message
      .replace('{locationName}', _.get(options, 'locationName', ''));
  }
  if (notification.notificationType === NOTIFICATION_TYPES.NEW_APPOINTMENT_KEY) {
    message = message
      .replace('{appointmentType}', _.get(options, 'appointmentType', ''))
      .replace('{locationName}', _.get(options, 'locationName', ''))
      .replace('{appointmentCreatedAt}', options.appointmentCreatedAt && options.siteTimeZone ?
        DateUtil.formatUTC(
          _.get(options, 'appointmentCreatedAt', 0),
          DATETIME_FORMAT.fullDateTime,
          options.siteTimeZone).replace('at', t('at'))
        : ''
      );
  }
  if (notification.notificationType === NOTIFICATION_TYPES.CANCEL_APPOINTMENT_KEY) {
    message = message
      .replace('{locationName}', _.get(options, 'locationName', ''));
  }
  if (notification.notificationType === NOTIFICATION_TYPES.RESCHEDULED_APPOINTMENT_KEY) {
    message = message
      .replace('{locationName}', _.get(options, 'locationName', ''))
      .replace('{appointmentCreatedAt}', options.appointmentCreatedAt && options.siteTimeZone ?
        DateUtil.formatUTC(
          _.get(options, 'appointmentCreatedAt', 0),
          DATETIME_FORMAT.fullDateTime,
          options.siteTimeZone).replace('at', t('at'))
        : ''
      );
  }
  if (notification.notificationType === NOTIFICATION_TYPES.NEW_APPOINTMENT_KEY) {
    message = message
      .replace('{appointmentType}', _.get(options, 'appointmentType', ''))
      .replace('{locationName}', _.get(options, 'location', ''))
      .replace('{appointmentCreatedAt}', options.appointmentCreatedAt && options.siteTimeZone ?
        DateUtil.formatUTC(
          _.get(options, 'appointmentCreatedAt', 0),
          DATETIME_FORMAT.fullDateTime,
          options.siteTimeZone).replace('at', t('at'))
        : ''
      );
  }
  if (notification.notificationType === NOTIFICATION_TYPES.LINKED_PARTICIPANT_APPOINTMENT_KEY) {
    /* Check conditions to render proper message */
    if (_.get(options, 'profileName')) {
      message = message
        .replace('{profileName}', _.get(options, 'profileName', ''));
    } else if (_.get(options, 'emailAddress')) {
      message = _.concat(
        t(PARTICIPANT_WITH_TEXT),
        message.replace('{profileName}', _.get(options, 'emailAddress'))
      );
    } else if (_.get(options, 'phoneNumber')) {
      message = (<div>
        {t(PARTICIPANT_WITH_TEXT)}
        <PhoneNumberView
          phoneNumber={_.get(options, 'phoneNumber')}
          internationalPhoneSupportEnabled={internationalPhoneSupportEnabled}
        />
        {message.replace('{profileName}', '')}
      </div>);
    }
  }
  if (notification.notificationType === NOTIFICATION_TYPES.CAMPAIGN_COMPLETED_KEY ||
    notification.notificationType === NOTIFICATION_TYPES.CAMPAIGN_FAILED_TO_SEND_KEY) {
    message = message
      .replace('{campaignName}', _.get(options, 'campaignName', ''));
  }
  if (notification.notificationType === NOTIFICATION_TYPES.CASE_MANAGEMENT_ASSIGN_KEY ||
    notification.notificationType === NOTIFICATION_TYPES.CASE_MANAGEMENT_REMOVE_ASSIGN_KEY) {
    message = message
      .replace('{name}', _.get(options, 'name', ''));
  }
  if (notification.notificationType === NOTIFICATION_TYPES.CASE_MANAGEMENT_RECONTACT_KEY) {
    message = message
      .replace('{firstName}', options.firstNameRecontactProfile ? options.firstNameRecontactProfile : '')
      .replace('{lastName}', options.lastNameRecontactProfile ? options.lastNameRecontactProfile : '')
      .replace('{verb}', options.recontactTime && options.recontactTime.split('-').length === 2 ? t('on') : t('at'))
      .replace('{date}', options.recontactDate ? DateUtil.format(options.recontactDate, DATETIME_FORMAT.shortDate).replace('at', t('at')) : '')
      .replace('{time}', options.recontactTime ? options.recontactTime : '');
  }

  if (notification.notificationType === NOTIFICATION_TYPES.ONE_OFF_MESSAGE_COMPLETED_KEY ||
  notification.notificationType === NOTIFICATION_TYPES.ONE_OFF_MESSAGE_FAILED_TO_SEND_KEY) {
    message = message
      .replace('{audienceFullName}', options.audienceFullName ? options.audienceFullName : 'N/A');
  }
  if (notification.notificationType === NOTIFICATION_TYPES.USER_EXPORT_REPORT_GENERATED_KEY) {
    message = message
      .replace('{fileName}', options.fileName ? options.fileName : '');
  }
  return message;
};
/**
 * Nontification Message
 * @param {Object} props The component properties
 */
const Message = (props) => {
  const [showMenu, setShowMenu] = React.useState(false);
  const { notification, onSelectNotification, typeSection,
    internationalPhoneSupportEnabled, t } = props;
  const type = props.typeNotification === STATUS.READ ?
    NOTIFICATION_MENU_MODE.READ_ONE : NOTIFICATION_MENU_MODE.UNREAD_ONE;
  return (
    <MenuItem
      key={notification.id}
      className={CLASS_NAME}
    >
      <div
        id="showMenu"
        onMouseOver={() => (setShowMenu(true))}
        onMouseLeave={() => (setShowMenu(false))}
      >
        <div className="content">
          <div className="icon">
            <i className={`fa ${TYPE_ICON[props.notification.notificationType]}`} aria-hidden="true" />
            <div className="icon-tooltip" >
              {t(TYPE_NOTIFICATION_NAME[props.notification.notificationType])}
              <i className="tooltip-arrow" />
            </div>
          </div>
          <div className="message-text text-ellipsis" onClick={() => onSelectNotification(notification)} role="presentation" >
            <ToolTipTextOverflow
              key={`tooltip-${notification.id}`}
              customClass={styles['tooltip-message-notification']}
              placement="bottom"
            >
              {getMessage(notification, internationalPhoneSupportEnabled, t)}
            </ToolTipTextOverflow>
          </div>
          <div className="action-btn text-right" >
            {showMenu &&
              <NotificationMenu
                id={notification.id}
                mode={type}
                typeSection={typeSection}
                t={t}
              />}
          </div>
        </div>
        <div className="create-date">
          {getSubMessage(notification.createdDate, t)}
        </div>
      </div>
    </MenuItem>
  );
};

Message.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number.isRequired,
    createdDate: PropTypes.number.isRequired,
    notificationType: PropTypes.string.isRequired,
  }).isRequired,
  onSelectNotification: PropTypes.func,
  typeNotification: PropTypes.string.isRequired,
  typeSection: PropTypes.string.isRequired,
  internationalPhoneSupportEnabled: PropTypes.bool.isRequired,
  t: PropTypes.func,
};

Message.defaultProps = {
  onSelectNotification: _.noop,
  t: x => x,
};

export default Message;
