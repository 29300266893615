// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-coreModules-authentication-components-notificationsModal-components-tabs-styles__tabs--NoSrgmQM{border-bottom:none}.src-coreModules-authentication-components-notificationsModal-components-tabs-styles__tabs--NoSrgmQM .react-tabs__tab-list{border-bottom:none}.src-coreModules-authentication-components-notificationsModal-components-tabs-styles__tabs--NoSrgmQM .react-tabs__tab:focus,.src-coreModules-authentication-components-notificationsModal-components-tabs-styles__tabs--NoSrgmQM .react-tabs__tab:hover,.src-coreModules-authentication-components-notificationsModal-components-tabs-styles__tabs--NoSrgmQM .react-tabs__tab:hover:focus{box-shadow:none}.src-coreModules-authentication-components-notificationsModal-components-tabs-styles__tabs--NoSrgmQM .react-tabs__tab--selected{font-weight:bold;color:#0099db;border:0;border-bottom:4px solid #0099db}", ""]);
// Exports
exports.locals = {
	"tabs": "src-coreModules-authentication-components-notificationsModal-components-tabs-styles__tabs--NoSrgmQM"
};
module.exports = exports;
