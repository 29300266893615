import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';

const userIsAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/login',
  // Determine if the user is authenticated or not
  authenticatedSelector: state => state.UserDetail.get('userDetail') !== undefined,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsAuthenticated',
});

export default userIsAuthenticated;
