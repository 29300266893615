import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Waypoint } from 'react-waypoint';
import Message from './message';

class Section extends Component {
  /**
   * Render way point
   * @return {Element} React element
   */
  renderWaypoint() {
    const { totalRecord, isRequesting, notifications, name, typeNotification, t } = this.props;
    if (!isRequesting && notifications.length < totalRecord) {
      return (
        <div className="infinite-scroll">
          <Waypoint
            key={`${typeNotification}-${name}`}
            onEnter={() => this.props.loadMoreData(name)}
          />
        </div>
      );
    }
    if (isRequesting && notifications.length < totalRecord) {
      return (
        <div className="infinite-scroll">
          <p className="loading-data"> {t('Loading more items…')} </p>
        </div>
      );
    }
    return (
      <div className="infinite-scroll-none" />
    );
  }
  /**
   * Render content
   * @return {Element} React element
   */
  renderContent() {
    const {
      notifications,
      onSelectNotification,
      name,
      internationalPhoneSupportEnabled,
      t,
    } = this.props;
    return _.map(notifications, item => (
      <Message
        notification={item}
        key={item}
        onSelectNotification={onSelectNotification}
        typeNotification={this.props.typeNotification}
        typeSection={name}
        internationalPhoneSupportEnabled={internationalPhoneSupportEnabled}
        t={t}
      />
    ));
  }
  render() {
    return (
      <Fragment>
        <div>
          {this.renderContent()}
        </div>
        {this.renderWaypoint()}
      </Fragment>
    );
  }
}
Section.propTypes = {
  name: PropTypes.string.isRequired,
  typeNotification: PropTypes.string.isRequired,
  totalRecord: PropTypes.number,
  isRequesting: PropTypes.bool,
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
  onSelectNotification: PropTypes.func,
  loadMoreData: PropTypes.func.isRequired,
  internationalPhoneSupportEnabled: PropTypes.bool.isRequired,
  t: PropTypes.func,
};
Section.defaultProps = {
  totalRecord: 0,
  isRequesting: false,
  notifications: [],
  onSelectNotification: _.noop,
  t: x => x,
};

export default Section;
