import { Map as MapImmutable } from 'immutable';
import { ADD_MODULE } from '../coreModules/moduleLoader/events';

const initialState = new MapImmutable({});
const actionHandlers = {};
/**
 * ADD_MODULE
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[ADD_MODULE] = (state, action) => state.set(action.moduleName, {
  reducers: action.module.reducers,
  components: action.module.components,
  routes: action.module.routes,
  middlewares: action.module.middlewares,
});
/**
 * Action Handlers
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
export default(state = initialState, action) => {
  if (actionHandlers[action.type] !== undefined) {
    const nextState = actionHandlers[action.type](state, action);
    if (nextState === null || nextState === undefined) {
      return state;
    }
    return nextState;
  }
  return state;
};
