import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Spinner } from '@vibrent/electryon';
import * as protocolBuilderActions from './actions';
import ProtocolBuilderForm from './userForm';
import { AlertComponent } from '../globalMessage';
import styles from './styles.scss';

const CLASS_NAME = styles['protocol-builder'];

const ProtocolBuilder = (props) => {
  const [loading, setLoading] = React.useState(true);
  return (
    <div className={CLASS_NAME}>
      <div className="titles">Protocol Builder</div>
      <div className="main-wrapper">
        <AlertComponent
          id={protocolBuilderActions.PROTOCOL_BUILDER_REQUEST}
        />
        <h1 className="sub-title"><span>Protocol Builder</span> is Coming Soon!</h1>
        <div className="description">Design, launch, and start recruiting for your research study from anywhere.</div>
        <div className="desc-text">Connect with us on our new low cost, self service tool for a demo.</div>
        <div
          className="flex-container"
          style={{ justifyContent: loading ? 'center' : 'unset' }}
        >
          {!loading ? <ProtocolBuilderForm
            internationalPhoneSupportEnabled
            sendingRequest={props.sendingRequest}
            protocolBuilderRequest={props.actions.protocolBuilderRequest}
          /> : <Spinner />}
          <img
            style={{ display: loading ? 'none' : 'unset' }}
            alt="marketing-img"
            src="/assets/img/protocol_builder.png"
            onLoad={() => setLoading(false)}
            onError={() => setLoading(false)}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  sendingRequest: state.ProtocolBuilder.get('updatingProfile'),
});

ProtocolBuilder.propTypes = {
  actions: PropTypes.shape({
    protocolBuilderRequest: PropTypes.func,
  }).isRequired,
  sendingRequest: PropTypes.bool,
};

ProtocolBuilder.defaultProps = {
  sendingRequest: false,
};

const mapDispatchToProps = dispatch => ({
  actions:
    bindActionCreators(
      Object.assign({}, protocolBuilderActions),
      dispatch,
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProtocolBuilder);

export const ProtocolBuilderComponent = ProtocolBuilder;
