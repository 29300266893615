import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FieldUtils } from '@vibrent/electryon';
import { reduxForm, Field as ReduxField } from 'redux-form';
import {
  Col,
  Row,
  ControlLabel,
  Form,
  Button,
} from 'react-bootstrap';
import {
  maxLength50,
  required,
  firstName,
  lastName,
  institution,
  normalizePhoneValidation,
  requiredEmail,
  email,
} from '../../components/common/fieldForm/validator';
import Field from '../../components/common/fieldForm';

const {
  renderPhoneNumberInput,
} = FieldUtils;

const ProtocolBuilderForm = (props) => {
  const { t } = useTranslation();
  const phoneValidator =
    useMemo(() => normalizePhoneValidation(
      props.internationalPhoneSupportEnabled), [props.internationalPhoneSupportEnabled]);

  const onSubmit = (user) => {
    props.protocolBuilderRequest(user).then((res) => {
      if (res.code === 200) {
        props.reset();
      }
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  };

  return (<Form
    onSubmit={props.handleSubmit(values => onSubmit(values))}
  >
    <Row>
      <Col className="form-col">
        <Row>
          <Col sm={12}>
            <Field
              name="firstName"
              type="text"
              label={t('firstName')}
              validate={[required, firstName, maxLength50]}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Field
              name="lastName"
              type="text"
              label={t('Last Name')}
              validate={[required, lastName, maxLength50]}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Field
              name="institution"
              type="text"
              label={t('Institution/Company')}
              validate={[required, institution, maxLength50]}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Field
              name="email"
              type="text"
              className="disabled-field"
              label={t('Email')}
              validate={[requiredEmail, email]}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <ControlLabel className="label-padding">{t('Telephone')}</ControlLabel>
            <ReduxField
              name="phoneNumber"
              type="text"
              component={renderPhoneNumberInput}
              className="form-control"
              validate={[phoneValidator]}
              internationalPhoneSupportEnabled
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Button
              type="submit"
              disabled={props.invalid || props.pristine || props.sendingRequest}
              className="btn btn-primary"
            >
              {t('Submit')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  </Form>
  );
};

ProtocolBuilderForm.propTypes = {
  internationalPhoneSupportEnabled: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  protocolBuilderRequest: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  sendingRequest: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
};
ProtocolBuilderForm.defaultProps = {
  internationalPhoneSupportEnabled: false,
  sendingRequest: false,
};

const formName = 'ProtocolBuilderForm';

const ProtocolBuilderFormConnect = reduxForm({
  form: formName,
  validate: ProtocolBuilderForm.validator,
  enableReinitialize: true,
})(ProtocolBuilderForm);

export default ProtocolBuilderFormConnect;
