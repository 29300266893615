import { Map as MapImmutable } from 'immutable';
import _ from 'lodash';
import {
  SET_USER_DETAIL,
  CLEAR_USER_DETAIL,
  SET_USER_ROLE,
  SET_CURRENT_ACTIVE_PROGRAM,
  GET_NODES_START,
  GET_NODES_FAILURE,
  GET_NODES_SUCCESS,
  SET_USER_PERMISSION_WITHOUT_REFRESH,
  SET_PROGRAM_LOGO_AND_ALTTEXT,
  PROGRAM_SWITCH_START,
  PROGRAM_SWITCH_SUCCESS,
  PROGRAM_SWITCH_FAILURE,
  UPDATE_USER_LANGUAGE_FAILURE,
  UPDATE_USER_LANGUAGE_SUCCESS,
  UPDATE_USER_LANGUAGE_START,
  GET_HELP_CENTER_DETAILS_FAILURE,
  GET_HELP_CENTER_DETAILS_SUCCESS,
  GET_HELP_CENTER_DETAILS_START,
  COLLAPSE_NAVBAR,
} from '../events';
import { landingPageRolesHierarchy } from '../components/common/constants';

const initialState = new MapImmutable({});
const actionHandlers = {};

/**
 * SET_USER_DETAIL
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[SET_USER_DETAIL] = (state, action) => {
  const currentActiveProgram = {
    ...action.userDetail.currentActiveProgram,
    ...action.userDetail.currentActiveProgram.programDetails,
  };
  let selectedRole = '';
  if (!action.userDetail.pmiplatform) {
    selectedRole = currentActiveProgram &&
  currentActiveProgram.roles &&
  currentActiveProgram.permissions
      ? currentActiveProgram.roles.sort((a, b) =>
        landingPageRolesHierarchy.indexOf(a.name) -
      landingPageRolesHierarchy.indexOf(b.name))
        .find(role => currentActiveProgram.permissions.find(
          p => p.action === 'view'
      && p.roleName === role.name
        ))
      : currentActiveProgram.roles[0];
  } else {
    selectedRole = currentActiveProgram &&
  currentActiveProgram.roles &&
  currentActiveProgram.permissions
      ? currentActiveProgram.roles
        .find(role => currentActiveProgram.permissions.find(
          p => p.action === 'view'
      && p.roleName === role.name
        ))
      : currentActiveProgram.roles[0];
  }

  return state.set('userDetail', action.userDetail).set('roleSelected', selectedRole)
    .set('isPMI', action.userDetail.pmiplatform)
    .set('currentActiveProgram', currentActiveProgram);
};

/**
 * CLEAR_USER_DETAIL
 * @param {Object} state Current state
 * @return {Object} Next state
 */
actionHandlers[CLEAR_USER_DETAIL] = state =>
  state.delete('userDetail').delete('roleSelected')
    .delete('currentActiveProgram');

/**
 * SET_USER_ROLE
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[SET_USER_ROLE] = (state, action) =>
  state.set('roleSelected', action.roleSelected);

/**
 * SET_CURRENT_ACTIVE_PROGRAM
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[SET_CURRENT_ACTIVE_PROGRAM] = (state, action) => {
  const currentActiveProgram = _.cloneDeep(state.get('currentActiveProgram'));
  currentActiveProgram.permissions = action.currentActiveProgram.permissions;
  currentActiveProgram.enabledFeatures = action.currentActiveProgram.enabledFeatures;
  return state.set('currentActiveProgram', currentActiveProgram);
};

/**
 * SET_USER_PERMISSION_WITHOUT_REFRESH
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
actionHandlers[SET_USER_PERMISSION_WITHOUT_REFRESH] = (state, action) => {
  const currentActiveProgram = state.get('currentActiveProgram');
  // we change permisison without refresh page. So need to modify state instead of create new state.
  currentActiveProgram.permissions = action.permissions;
};

actionHandlers[SET_PROGRAM_LOGO_AND_ALTTEXT] = (state, action) => {
  let currentActiveProgram = state.get('currentActiveProgram');
  currentActiveProgram = {
    ...currentActiveProgram,
    programLogo: action.programDetails.programLogo,
    alternateText: action.programDetails.altText,
  };
  return state.set('currentActiveProgram', currentActiveProgram);
};

actionHandlers[GET_NODES_START] = state => state
  .set('requestingNodes', true)
  .delete('nodeData')
  .delete('nodeError');

actionHandlers[GET_NODES_FAILURE] = (state, action) => state
  .set('requestingNodes', false)
  .delete('nodeData')
  .set('nodeError', action.err);

actionHandlers[GET_NODES_SUCCESS] = (state, action) => state
  .set('requestingNodes', false)
  .set('nodeData', action.res)
  .delete('nodeError');

actionHandlers[PROGRAM_SWITCH_START] = state => state
  .set('requestingProgramData', true)
  .delete('programData')
  .delete('programSwitchError');

actionHandlers[PROGRAM_SWITCH_SUCCESS] = (state, action) => state
  .set('requestingProgramData', false)
  .set('programData', action.res)
  .delete('programSwitchError');

actionHandlers[PROGRAM_SWITCH_FAILURE] = (state, action) => state
  .set('requestingProgramData', false)
  .delete('programData')
  .set('programSwitchError', action.err);

actionHandlers[UPDATE_USER_LANGUAGE_START] = state => state
  .set('updatingUserLanguage', true)
  .delete('updatingUserLanguageError');

actionHandlers[UPDATE_USER_LANGUAGE_SUCCESS] = (state, action) => {
  let userDetail = state.get('userDetail');
  userDetail = {
    ...userDetail, preferredLanguage: action.res,
  };
  return state
    .set('userDetail', userDetail)
    .delete('updatingUserLanguageError');
};

actionHandlers[UPDATE_USER_LANGUAGE_FAILURE] = (state, action) => state
  .set('requestingProgramData', false)
  .set('updatingUserLanguageError', action.err);

actionHandlers[GET_HELP_CENTER_DETAILS_START] = state => state
  .set('isCheckingSession', true)
  .set('getProgramDetails', true)
  .set('showHelpCentre', false)
  .delete('helpCentreUrl')
  .set('lmsStatus', false)
  .delete('lmsPortalUrl')
  .set('partnerCmsStatus', false)
  .delete('partnerCmsUrl')
  .set('studyDesignStatus', false)
  .delete('studyDesignUrl');

actionHandlers[GET_HELP_CENTER_DETAILS_SUCCESS] = (state, action) => state
  .set('isCheckingSession', false)
  .set('getProgramDetails', false)
  .set('showHelpCentre', action.response.Status)
  .set('helpCentreUrl', action.response.helpCenterUrl)
  .set('lmsStatus', action.response.lmsStatus)
  .set('lmsPortalUrl', action.response.lmsPortalUrl)
  .set('partnerCmsStatus', action.response.partnerCmsStatus)
  .set('partnerCmsUrl', action.response.partnerCmsUrl)
  .set('studyDesignStatus', action.response.studyDesignStatus)
  .set('studyDesignUrl', action.response.studyDesignUrl);

actionHandlers[GET_HELP_CENTER_DETAILS_FAILURE] = state => state
  .set('isCheckingSession', false)
  .set('getProgramDetails', false)
  .set('showHelpCentre', false)
  .delete('helpCentreUrl')
  .set('lmsStatus', false)
  .delete('lmsPortalUrl')
  .set('partnerCmsStatus', false)
  .delete('partnerCmsUrl')
  .set('studyDesignStatus', false)
  .delete('studyDesignUrl');
actionHandlers[COLLAPSE_NAVBAR] = (state, action) => state
  .set('collapseNavbar', action.open);

/**
 * Action Handlers
 * @param {Object} state Current state
 * @param {Object} action
 * @return {Object} Next state
 */
export default (state = initialState, action) => {
  if (actionHandlers[action.type] !== undefined) {
    const nextState = actionHandlers[action.type](state, action);
    if (nextState === null || nextState === undefined) {
      return state;
    }
    return nextState;
  }
  return state;
};
