// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-coreModules-authentication-components-common-logoHolder-styles__pmtStyle--2Fnv1CTL{width:60px;height:35px;font-size:10px;font-weight:900;line-height:1.2;color:#434343;margin-top:6.5px;margin-left:17px;padding-left:16px;border-left:solid 1px #434343;font-family:Avenir}.src-coreModules-authentication-components-common-logoHolder-styles__linkStyles--dwjrENSG{display:flex;flex-direction:\"row\"}.src-coreModules-authentication-components-common-logoHolder-styles__linkStylesRC--ZDSONat3{display:flex;flex-direction:\"row\";padding-left:10px}.src-coreModules-authentication-components-common-logoHolder-styles__logoStyles--1mDC-wUR{margin-left:16px;margin-top:8px;margin-bottom:8px}.src-coreModules-authentication-components-common-logoHolder-styles__logoStylesRC--MRFEftfL{margin-top:8px;margin-bottom:8px}", ""]);
// Exports
exports.locals = {
	"pmtStyle": "src-coreModules-authentication-components-common-logoHolder-styles__pmtStyle--2Fnv1CTL",
	"linkStyles": "src-coreModules-authentication-components-common-logoHolder-styles__linkStyles--dwjrENSG",
	"linkStylesRC": "src-coreModules-authentication-components-common-logoHolder-styles__linkStylesRC--ZDSONat3",
	"logoStyles": "src-coreModules-authentication-components-common-logoHolder-styles__logoStyles--1mDC-wUR",
	"logoStylesRC": "src-coreModules-authentication-components-common-logoHolder-styles__logoStylesRC--MRFEftfL"
};
module.exports = exports;
