import { Map as MapImmutable } from 'immutable';
import * as CONSTANT from './constant';

const initialState = new MapImmutable({});
const actionHandlers = {};

/**
 * Reducer handler
 * @param  {Object} state
 * @param  {Object} action
 * @return {Object} next state
 */
export default (state = initialState, action) => {
  const fn = actionHandlers[action.type];
  return fn ? fn(state, action) : state;
};


actionHandlers[CONSTANT.GET_ADHOC_CAMPAIGN_NOTIFICATION_START] = state => state
  .set('requestingAdhocNotification', true);

actionHandlers[CONSTANT.GET_ADHOC_CAMPAIGN_NOTIFICATION_SUCCESS] = (state, action) => state
  .set('requestingAdhocNotification', false)
  .set('listCampaignSuccess', action.data);


actionHandlers[CONSTANT.GET_ADHOC_CAMPAIGN_NOTIFICATION_FAILURE] = (state, action) => state
  .set('requestingAdhocNotification', false)
  .set('errorAdhocNotification', action.error);
