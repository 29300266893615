// import StoreEngine
import StoreEngine from 'store/src/store-engine';
// import LocalStorage
import LocalStorage from 'store/storages/localStorage';
// import CookieStorage
import CookieStorage from 'store/storages/cookieStorage';
// import Expire
import Expire from 'store/plugins/expire';

// Example custom build usage:
const storages = [
  LocalStorage,
  CookieStorage,
];
const plugins = [
  Expire,
];
// create store
const store = StoreEngine.createStore(storages, plugins);
// export store and get it is default
export default store;
