export const GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const GET_TOTAL_NOTIFICATIONS_START = 'GET_TOTAL_NOTIFICATIONS_START';
export const GET_TOTAL_NOTIFICATIONS_SUCCESS = 'GET_TOTAL_NOTIFICATIONS_SUCCESS';

export const SAVE_NOTIFICATION_STATUS_START = 'SAVE_NOTIFICATION_STATUS_START';
export const SAVE_NOTIFICATION_STATUS_SUCCESS = 'SAVE_NOTIFICATION_STATUS_SUCCESS';
export const SAVE_NOTIFICATION_STATUS_FAILURE = 'SAVE_NOTIFICATION_STATUS_FAILURE';


export const SET_VIEW_APPOINTMENT_PARAMS = 'SET_VIEW_APPOINTMENT_PARAMS';

export const CHANGE_SELECTED_SITE = 'CHANGE_SELECTED_SITE';
