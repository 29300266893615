import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise';
import { createLogger } from 'redux-logger';
import dynamicMiddlewares from 'redux-dynamic-middlewares';
import _ from 'lodash';
import createReducer from './reducers/index';
import requestMiddleware from './coreModules/authentication/components/middlewares/request';

const reducers = createReducer();
const middlewares = [thunk, promiseMiddleware, dynamicMiddlewares, requestMiddleware];
if (process.env.NODE_ENV !== 'production') {
  middlewares.push(createLogger({
    stateTransformer: (state) => {
      const newState = {};

      _.forEach(state, (value, name) => {
        try {
          newState[name] = value.toJS();
        } catch (e) {
          newState[name] = value;
        }
      });

      return newState;
    },
  }));
}
// Generate createStoreWithMiddleware
const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);
// Create Store With Middleware
const store = createStoreWithMiddleware(reducers);
store.createReducer = createReducer;
// Export store;
export default store;
