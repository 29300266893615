// export Constants
export const Constants = {
  /**
   * SuccessMessageForSaving getter function
   * when changes have been saved
   */
  SuccessMessageForSaving: 'Your changes have been saved.',
  ErrorMessageFromServer: 'Bad response from server.',
  Form: {
    PasswordForm: 'VibrentCorePasswordForm',
    AddNewProgramMilestoneForm: 'VibrentAddNewProgramMilestoneForm',
  },
  isMinLength: {
    text: 'Minimum 8 characters',
    validation: value => value.length >= 8,
  },
  hasUpperCase: {
    text: 'Contains at least one uppercase letter',
    validation: value => /[A-Z]/.test(value),
  },
  hasLowerCase: {
    text: 'Contains at least one lowercase letter',
    validation: value => /[a-z]/.test(value),
  },
  hasNumber: {
    text: 'Contains at least one number',
    validation: value => /\d/.test(value),
  },
  hasSpecialCharacter: {
    text: 'Contains at least one special character',
    validation: value => /[!"#$%&'()*+,-./:;<=>?@[\]^`{|}~]/.test(value),
  },
  passwordPolicy: 'To conform with our Strong Password policy, you are required to use a sufficiently strong password.',
  /**
   * DateTimeFormat getter function
   * define date time format
   * for shortDate, shortTime, shortTimeAMPM, shortDayOfWeek
   * shortMonth and requestedShortDate
   */
  DateTimeFormat:
  {
    shortDate: 'MM/DD/YYYY',
    shortTime: 'HH:mm',
    shortTimeAMPM: 'hh:mm A',
    shortDayOfWeek: 'ddd',
    dayOfWeek: 'dddd',
    shortMonth: 'MMMM YYYY',
    requestedShortDate: 'YYYY-MM-DD',
  },

  /**
   * DefaultWorkingDaysOfWeek getter function
   * default all day of week
   */
  DefaultWorkingDaysOfWeek:
  [
    'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday',
  ],

  /**
   * TimeConstraint getter function
   * define time Constraint format
   * for time minutes, startTime, endTime
   */
  TimeConstraint:
  {
    minutes: {
      step: 30,
      min: 0,
    },
    startTime: {
      hours: 9,
      minutes: 0,
      seconds: 0,
    },
    endTime: {
      hours: 17,
      minutes: 0,
      seconds: 0,
    },
  },

  /**
   * Roles getter function
   * define all roles
   */
  Roles:
  {
    NIH: 'NIH',
    AWARDEE_LEADER: 'Awardee Leader',
    AWARDEE_STAFF: 'Awardee Staff',
    ORG_LEADER: 'Org Leader',
    ORG_STAFF: 'Org Staff',
    SITE_LEADER: 'Site Leader',
    SITE_STAFF: 'Site Staff',
  },

  /**
   * RoleTypes getter function
   * define all RoleTypes
   */
  RoleTypes:
  {
    NIH: 1,
    AWARDEE_LEADER: 2,
    AWARDEE_STAFF: 3,
    ORG_LEADER: 4,
    ORG_STAFF: 5,
    SITE_LEADER: 6,
    SITE_STAFF: 7,
  },

  /**
   * Scopes getter function
   * define Scopes
   */
  Scopes:
  {
    ALL: '*',
  },

  /**
   * Actions getter function
   * define action
   */
  Actions:
  {
    ALL: '*',
    VIEW: 'VIEW',
  },

  /**
   * numberOfDays getter function
   * define day of month
   */
  numberOfDays: 31,

  MESSAGE_BOX_ID: {
    SAVED_TOGGLE_FEATURE_MESSAGE_ID: 'programMaintenance',
    SAVE_PROGRAM_MILESTONE: 'saveProgramMilestone',
    SAVE_ENGAGEMENT_OUTCOME: 'saveEngagementOutcomes',
    MIGRATE_TEMPLATES: 'migrateTemplates',
    CHECK_OVERRIDE_TEMPLATES: 'checkOverrideTemplates',
    UPDATE_TEMPLATE_INFORMATION: 'updateTemplateInformation',
  },
  PARAM_TYPE: {
    QUERY_STRING: 'Query String',
    PATH_VARIABLE: 'Path Variabel',
    BOTH: 'Both',
  },
  HELP_CENTER_URL: 'https://pmthelp.vibrenthealth.com',
  FORMS: {
    TEMPLATE_MIGRATION_FORM: 'templateMigrationForm',
  },
};
export const landingPageRolesHierarchy = [
  'ROLE_MC_PROGRAM_MANAGER',
  'ROLE_MC_COMMUNICATIONS_ENGAGEMENT_MANAGER',
  'ROLE_MC_SITE_MANAGER',
  'ROLE_MC_SUPPORT_ADMIN',
  'ROLE_MC_SYSTEM_ADMINISTRATOR',
  'ROLE_MC_ADMINISTRATOR',
  'ROLE_MC_PROGRAM_COORDINATOR',
  'ROLE_MC_RESEARCH_ASSISTANT',
  'ROLE_MC_REPORTING_STAFF',
  'ROLE_MC_RESEARCHER',
  'ROLE_MC_DATA_SCIENTIST',
  'ROLE_MC_HIERARCHY_MANAGER',
  'ROLE_MC_CATI_INTERVIEWER',
  'ROLE_MC_SUPPORT_STAFF',
];
export const featureWiseRolesHierarchy = {
  Dashboard: [
    'ROLE_MC_PROGRAM_MANAGER',
    'ROLE_MC_COMMUNICATIONS_ENGAGEMENT_MANAGER',
    'ROLE_MC_PROGRAM_COORDINATOR',
  ],
  Tasks: [
    'ROLE_MC_PROGRAM_MANAGER',
    'ROLE_MC_COMMUNICATIONS_ENGAGEMENT_MANAGER',
    'ROLE_MC_PROGRAM_COORDINATOR',
    'ROLE_MC_SITE_MANAGER',
    'ROLE_MC_RESEARCH_ASSISTANT',
    'ROLE_MC_CATI_INTERVIEWER',
  ],
  Appointments: [
    'ROLE_MC_PROGRAM_MANAGER',
    'ROLE_MC_PROGRAM_COORDINATOR',
    'ROLE_MC_SITE_MANAGER',
    'ROLE_MC_RESEARCH_ASSISTANT',
    'ROLE_MC_CATI_INTERVIEWER',
  ],
  'Record Management': [
    'ROLE_MC_PROGRAM_MANAGER',
    'ROLE_MC_COMMUNICATIONS_ENGAGEMENT_MANAGER',
    'ROLE_MC_PROGRAM_COORDINATOR',
    'ROLE_MC_SITE_MANAGER',
    'ROLE_MC_RESEARCH_ASSISTANT',
    'ROLE_MC_CATI_INTERVIEWER',
    'ROLE_MC_SUPPORT_ADMIN',
    'ROLE_MC_SUPPORT_STAFF',
  ],
  'File Library': [
    'ROLE_MC_PROGRAM_MANAGER',
    'ROLE_MC_COMMUNICATIONS_ENGAGEMENT_MANAGER',
    'ROLE_MC_PROGRAM_COORDINATOR',
    'ROLE_MC_SITE_MANAGER',
    'ROLE_MC_RESEARCH_ASSISTANT',
    'ROLE_MC_CATI_INTERVIEWER',
  ],
  'User Administration': [
    'ROLE_MC_SYSTEM_ADMINISTRATOR',
    'ROLE_MC_ADMINISTRATOR',
    'ROLE_MC_PROGRAM_MANAGER',
    'ROLE_MC_SITE_MANAGER',
    'ROLE_MC_SUPPORT_ADMIN',
  ],
  Recruitment: [
    'ROLE_MC_PROGRAM_MANAGER',
    'ROLE_MC_COMMUNICATIONS_ENGAGEMENT_MANAGER',
    'ROLE_MC_PROGRAM_COORDINATOR',
    'ROLE_MC_SITE_MANAGER',
    'ROLE_MC_RESEARCH_ASSISTANT',
    'ROLE_MC_CATI_INTERVIEWER',
  ],
  Engagement: [
    'ROLE_MC_PROGRAM_MANAGER',
    'ROLE_MC_COMMUNICATIONS_ENGAGEMENT_MANAGER',
    'ROLE_MC_PROGRAM_COORDINATOR',
    'ROLE_MC_SITE_MANAGER',
    'ROLE_MC_RESEARCH_ASSISTANT',
    'ROLE_MC_CATI_INTERVIEWER',
  ],
  'Research Workbench': [
    'ROLE_MC_PROGRAM_MANAGER',
    'ROLE_MC_COMMUNICATIONS_ENGAGEMENT_MANAGER',
    'ROLE_MC_PROGRAM_COORDINATOR',
    'ROLE_MC_SITE_MANAGER',
    'ROLE_MC_REPORTING_STAFF',
    'ROLE_MC_DATA_SCIENTIST',
    'ROLE_MC_RESEARCHER',
    'ROLE_MC_SYSTEM_ADMINISTRATOR',
  ],
  'Admin Configurations': [
    'ROLE_MC_SYSTEM_ADMINISTRATOR',
  ],
  'Researcher Portal': [
    'ROLE_MC_RESEARCHER',
  ],
  'Site EDC': [
    'ROLE_MC_PROGRAM_MANAGER',
  ],
  'Site Management': [
    'ROLE_MC_SYSTEM_ADMINISTRATOR',
    'ROLE_MC_HIERARCHY_MANAGER',
  ],
};
export const RESOLUTION_TOAST_MESSAGE = 'For optimal user experience, we recommend a screen resolution above 1440; Adjust your display settings for the best experience.';
export const RESOLUTION_TOAST_DURATION = 10000;
export const MINIMUM_RESOLUTION = 1440;
// export Constants component
export default Constants;
