import _ from 'lodash';
import Reducer from './reducer';
import * as EVENTS from './events';
import AbortableRequest, { REQUEST_METHOD } from './abortableRequest';

const HTTP_REQUEST = 'VibrentCoreUIRequestMiddleware';
const REDUCER_NAME = 'VibrentCoreUIRequestMiddlewareReducer';
const RESPONSE_STATUS = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  ABORTED: 'aborted',
};

/**
 * Response
 * @param  {Function|String} handler The handler
 * @param  {Object} response The response from server
 * @return {Promise}
 */
const doResponse = (handler, response) => {
  let action = {};
  const params = _.values(response);
  // Check type of handler
  switch (typeof handler) {
  case 'string':
    // The handler is action type
    action = {
      type: handler,
      ...response,
    };
    break;
  case 'function':
    // Call the handler to get action object
    action = handler(...params);
    break;
  default:
    // Unable to create response action
    throw new Error(`Unable to create response action from "${JSON.stringify(handler)}"`);
  }
  // Return action object
  return action;
};

/**
 * Get start request action
 * @param  {String} id The id of request
 * @param  {Number} timestamp The moment when the request is started
 * @param  {Object} request The request controller
 * @return {Object} The action object
 */
const startRequest = (id, timestamp, request) => ({
  type: EVENTS.START_REQUEST,
  payload: {
    id,
    timestamp,
    request,
  },
});

/**
 * Get end request action
 * @param  {String} id The id of request
 * @param  {Number} timestamp The moment when the request is started
 * @return {Object} The action object
 */
const endRequest = (id, timestamp) => ({
  type: EVENTS.END_REQUEST,
  payload: {
    id,
    timestamp,
  },
});

/**
 * The request middleware class
 * @param {Object} store The store
 * @return {Function}
 */
const RequestMiddleware = store => next => (action) => {
  const httpRequestPayload = action[HTTP_REQUEST];

  // Check if we need handle the request
  if (_.isNil(httpRequestPayload)) {
    return next(action);
  }

  // Create timestamp of request
  const timestamp = (new Date()).getTime();
  const {
    handlers,
    // In case the id is empty, create id by using timestamp
    id = timestamp,
  } = httpRequestPayload;

  // Cancel old request
  const listRequests = store.getState()[REDUCER_NAME].get('requests');
  if (listRequests.has(id)) {
    store.dispatch({
      type: EVENTS.ABORT_REQUEST,
      payload: {
        id,
        timestamp,
      },
    });
  }

  // Create abortable request.
  const requestController = new AbortableRequest();

  // Trigger start request
  store.dispatch(startRequest(id, timestamp, requestController));

  // Do Request
  requestController
    .request(httpRequestPayload)
    .then((payload) => {
      // Trigger response
      store.dispatch(doResponse(handlers[RESPONSE_STATUS.SUCCESS], { payload }));
    })
    .catch((error) => {
      // Check if request is aborted of not
      const type = _.get(error, 'isAborted', false) ?
        RESPONSE_STATUS.ABORTED :
        RESPONSE_STATUS.FAILURE;
      // Trigger response
      store.dispatch(doResponse(handlers[type], { error }));
    })
    .finally(() => {
      // Trigger end request
      store.dispatch(endRequest(id, timestamp));
    });

  return next(action);
};

// Export constants
export {
  REQUEST_METHOD,
  RESPONSE_STATUS,
  HTTP_REQUEST,
  REDUCER_NAME,
};
export const RequestReducer = {
  [REDUCER_NAME]: Reducer,
};

// Export
export default RequestMiddleware;
