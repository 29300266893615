import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ANIMATION_DURATION } from './constants';

/**
 * Message component
 * @extends PureComponent
 */
class Message extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.hideMessage = this.hideMessage.bind(this);
    this.clearMessage = this.clearMessage.bind(this);
    this.showMessage = this.showMessage.bind(this);
  }

  /**
   * Use set timeout to show animation for message
   */
  componentDidMount() {
    this.timeOut = setTimeout(this.showMessage, ANIMATION_DURATION);
  }

  /**
   * Clear timeout (if exists) on unmount
   */
  componentWillUnmount() {
    clearTimeout(this.timeOut);
  }

  /**
   * Hide message in reducer
   */
  clearMessage() {
    this.props.onClearMessage(this.props.data);
  }

  /**
   * Show 'fade in' animation for message then hide message
   */
  showMessage() {
    this.setState({ show: true });
    this.timeOut = setTimeout(this.hideMessage, this.props.data.duration);
  }

  /**
   * Show 'fade' animation for message then hide message in reducer
   */
  hideMessage() {
    this.setState({ show: false });
    this.timeOut = setTimeout(this.clearMessage, ANIMATION_DURATION);
  }

  render() {
    const messageTypeClass = this.props.data.type;
    const showClass = this.state.show ? 'in' : '';
    return (
      <div className={`message animated fade ${messageTypeClass} ${showClass}`}>
        {this.props.data.text}
        <button
          type="button"
          onClick={this.hideMessage}
          className="close"
        >
          <i className="fas fa-times" />
        </button>
      </div>
    );
  }
}

Message.propTypes = {
  onClearMessage: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string,
    type: PropTypes.oneOf(['success', 'error']),
    duration: PropTypes.number,
  }).isRequired,
};

export default Message;
